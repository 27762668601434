import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import inventoryReducer from './slices/inventorySlice';
import contactLogInventoryReducer from './slices/contactLogInventorySlice';
import medicalsSlice from './slices/medicalsSlice';
import medicalsTreatmentsReducer from './slices/medicalsTreatmentsSlice';
import navbarSlice from './slices/navbarSlice';
import authorSlice from './slices/authorSlice';
import demandDomSlice from './slices/demandDomSlice';
import documentSlice from './slices/documentSlice';
import userHistoryPersistedSlice from './persistedSlices/userHistoryPersistedSlice';
import globalDialogueSlice from './slices/globalDialogueSlice';
import globalToastSlice from './slices/globalToastSlice';
import customerSlice from './slices/customerSlice';
import documentInsightsSlice from './slices/documentInsightsSlice';
import templateSlice from './slices/templateSlice';
import mriFindingsSlice from './slices/mriFindingsSlice';
import sideBySideWithVerticalNavSlice from './slices/sideBySideWithVerticalNavSlice';
import icdCodesSlice from './slices/icdCodesSlice';
import aiNarrativeGenerationSlice from "./slices/aiNarrativeGenerationSlice";
import adminEditTemplateSlice from "./slices/adminEditTemplateSlice";
import templatePreviewSlice from './slices/templatePreviewSlice';
import codeEditorSlice from './slices/codeEditorSlice';

const getStore = () => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['UserHistoryPersisted'],
  };

  const rootReducer = combineReducers({
    Inventory: inventoryReducer,
    ContactLogInventory: contactLogInventoryReducer,
    MedicalsTreatments: medicalsTreatmentsReducer,
    Medicals: medicalsSlice,
    Navbar: navbarSlice,
    Author: authorSlice,
    DemandDom: demandDomSlice,
    Document: documentSlice,
    GlobalDialogues: globalDialogueSlice,
    GlobalToasts: globalToastSlice,
    UserHistoryPersisted: userHistoryPersistedSlice,
    Customer: customerSlice,
    DocumentInsights: documentInsightsSlice,
    Template: templateSlice,
    MriFindings: mriFindingsSlice,
    IcdCodes: icdCodesSlice,
    AiNarrativeGeneration: aiNarrativeGenerationSlice,
    SideBySideWithVerticalNav: sideBySideWithVerticalNavSlice,
    AdminEditTemplate: adminEditTemplateSlice,
    TemplatePreview: templatePreviewSlice,
    CodeEditor: codeEditorSlice,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
  });
};

const store = getStore();

export default store;
