import React, { useEffect } from 'react';
import { Box, Stack, IconButton, Skeleton } from '@mui/material';
import Title from './Title';
import { MenuOpenRounded } from '@mui/icons-material';
import PDFViewerComponent from './PDFViewerComponent';
import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';
import { setPdfPanelOpen, setResizableWidth, setPdfInstance } from '../redux/slices/demandDomSlice';
import moment from 'moment';
import { isDocumentUrlExpired } from '../common-document';
import { setDocumentDataLoading, setDocumentRegnerationLoading } from '../redux/slices/documentSlice';
import { fetchDocumentData } from '../redux/thunks/documentData';

const ResizableWrapWithPdfViewer = ({
  children,
  documentS3Url,
  currentPage = 0,
  currentBoundingBoxes = [],
  currentPageTrigger = 0,
  pdfHeight = '75vh',
  defaultWidth = '66%',
  isPDFSticky = false,
  documentId,
  user
}) => {
  const currentTime = moment().unix();
  const documentUrlExpired = isDocumentUrlExpired(documentS3Url);
  const dispatch = useDispatch();
  const {
    resizableWidth,
    editAllDocumentsOpen,
    demandDetailsEditOpen,
    demandEditOpen,
    deleteMedicalOpen,
    bulkDeleteOpen,
    bulkEditOpen,
    pdfPanelOpen,
  } = useSelector((state) => state.DemandDom);
  const documentDataState = useSelector((state) => state.Document);
  const { documentRegnerationLoading } = documentDataState;
  const anyModalsOpen =
    editAllDocumentsOpen || demandDetailsEditOpen || demandEditOpen || deleteMedicalOpen || bulkDeleteOpen || bulkEditOpen;

  useEffect(() => {
    if (documentS3Url && documentUrlExpired && pdfPanelOpen && !anyModalsOpen) {
      dispatch(setDocumentDataLoading(documentId));
      dispatch(setDocumentRegnerationLoading(documentId));
      dispatch(fetchDocumentData({ documentId, user })).then(() => {
        dispatch(setDocumentRegnerationLoading(''));
      });
    }
  }, [currentTime]);

  useEffect(() => {
    dispatch(setResizableWidth(defaultWidth));
    dispatch(setPdfPanelOpen(true));
    return () => {
      dispatch(setResizableWidth(defaultWidth));
      dispatch(setPdfPanelOpen(true));
    };
  }, [defaultWidth]);

  const handleDocumentPanelClose = (event) => {
    dispatch(setPdfPanelOpen(false));
    setPdfInstance(null);
    dispatch(setResizableWidth('99.9%'));
  };

  const handleDocumentPanelOpen = (event) => {
    dispatch(setPdfPanelOpen(true));
    dispatch(setResizableWidth(defaultWidth));
  };

  const handleResizeStop = (e, direction, ref, d) => {
    const newWidth = resizableWidth + d.width;
    dispatch(setResizableWidth(newWidth));
  };

  const viewerPositioningProps = isPDFSticky
    ? {
      position: 'sticky',
      top: '64px', // Stick to the bottom of the header
      paddingTop: 1, // Align title with form tabs
    }
    : { width: '100%' };

  return (
    <>
      <Resizable
        enable={{ left: pdfPanelOpen, right: pdfPanelOpen }}
        size={{ width: !documentS3Url ? '100%' : resizableWidth, height: '100%' }}
        minWidth={'25%'}
        maxWidth={!documentS3Url && !documentRegnerationLoading ? '100%' : pdfPanelOpen ? '80%' : '97%'}
        onResizeStop={handleResizeStop}
      >
        {children}
      </Resizable>
      <Box sx={{ width: '100%', paddingLeft: '8px', borderLeft: '1px solid', borderColor: 'divider' }}>
        <Box {...viewerPositioningProps}>
          {pdfPanelOpen && (
            <>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 1 }}>
                <Title>Demand</Title>
                <IconButton onClick={handleDocumentPanelClose}>
                  <MenuOpenRounded sx={{
                    transform: 'scaleX(-1)'
                  }} />
                </IconButton>
              </Stack>
              <div>
                {documentRegnerationLoading && (
                  <Stack spacing={1} sx={{ py: 2, borderRadius: '4px' }}>
                    <Skeleton variant="rounded" width={'100%'} height={40} />
                    <Skeleton variant="rounded" width={'100%'} height={690} />
                  </Stack>
                )}
                {!documentRegnerationLoading && !documentUrlExpired && documentS3Url && (
                  <Box sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    overflow: 'hidden'
                  }}>
                    <PDFViewerComponent
                      currentPage={currentPage}
                      currentBoundingBoxes={currentBoundingBoxes}
                      currentPageTrigger={currentPageTrigger}
                      height={pdfHeight}
                      documentS3Url={documentS3Url}
                    ></PDFViewerComponent>
                  </Box>
                )}
              </div>
            </>
          )}

          {!pdfPanelOpen && (
            <IconButton onClick={handleDocumentPanelOpen}>
              <MenuOpenRounded />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResizableWrapWithPdfViewer;
