import { createSlice } from '@reduxjs/toolkit';

const originalGlobalToastState = {
  toast: {
    isOpen: false,
    severity: 'success',
    message: '',
    duration: 7000,
  },
};

export const globalToastSlice = createSlice({
  name: 'GlobalToasts',
  initialState: originalGlobalToastState,
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload;
    },
    resetGlobalToasts: () => originalGlobalToastState,
  },
});

export const { setToast, resetGlobalToasts } = globalToastSlice.actions;

export default globalToastSlice.reducer;
