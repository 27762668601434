import { createSlice } from '@reduxjs/toolkit'
import { saveDocumentFilesBydId } from '../thunks/demandData';

const originalDomState = {
    demandDetailsEditOpen: false,
    medicalsScrollFrameRef: null,
    demandEditOpen: false,
    medicalExtractionDialogOpen: false,
    pdfPanelOpen: true,
    resizableWidth: '66.6%',
    deleteMedicalOpen: false,
    bulkDeleteOpen: false,
    bulkEditOpen: false,
    medicalsTableIndex: 0,
    createNewDemandOpen: false,
    documentUploaderList: [],
    documentFiles: [],
    documentFilesLoading: false,
    editAllDocumentsOpen: false,
    editOneDocumentFileName: '',
    documentEditS3Url: '',
    pdfInstance: null,
    redactDocumentOpen: false,
    editDocumentPdfOpen: false,
    fileDisabled: '',
    isRedactorDirty: false,
    pdfRedactionIndex: 0,
    fileSaveStatusMap: {},
    aiPromptBuilderOpen: false,
}

const demandDomSlice = createSlice({
    name: 'DemandDom',
    initialState: originalDomState,
    reducers: {
        setEditAllDocumentsOpen: (state, action) => { state.editAllDocumentsOpen = action.payload },
        setMedicalsScrollFrameRef: (state, action) => { state.medicalsScrollFrameRef = action.payload },
        setDemandEditOpen: (state, action) => { state.demandEditOpen = action.payload },
        setDemandDetailsEditOpen: (state, action) => { state.demandDetailsEditOpen = action.payload },
        setMedicalExtractionDialogOpen: (state, action) => { state.medicalExtractionDialogOpen = action.payload },
        setPdfPanelOpen: (state, action) => { state.pdfPanelOpen = action.payload },
        setResizableWidth: (state, action) => { state.resizableWidth = action.payload },
        setDeleteMedicalOpen: (state, action) => { state.deleteMedicalOpen = action.payload },
        setBulkDeleteOpen: (state, action) => { state.bulkDeleteOpen = action.payload },
        setBulkEditOpen: (state, action) => { state.bulkEditOpen = action.payload },
        setMedicalsTableIndex: (state, action) => { state.medicalsTableIndex = action.payload },
        setCreateNewDemandOpen: (state, action) => { state.createNewDemandOpen = action.payload },
        setDocumentUploaderList: (state, action) => { state.documentUploaderList = action.payload },
        setDocumentFiles: (state, action) => { state.documentFiles = action.payload },
        setDocumentFilesLoading: (state, action) => { state.documentFilesLoading = action.payload },
        setEditOneDocumentFileName: (state, action) => { state.editOneDocumentFileName = action.payload },
        setDocumentEdit3Url: (state, action) => { state.documentEditS3Url = action.payload },
        setPdfInstance: (state, action) => { state.pdfInstance = action.payload },
        setRedactDocumentOpen: (state, action) => { state.redactDocumentOpen = action.payload },
        setEditDocumentPdfOpen: (state, action) => { state.editDocumentPdfOpen = action.payload },
        setFileDisabled: (state, action) => { state.fileDisabled = action.payload },
        setIsRedactorPdfDirty: (state, action) => { state.isRedactorDirty = action.payload },
        setPdfRedactionIndex: (state, action) => { state.pdfRedactionIndex = action.payload },
        setFileSaveStatusMap: (state, action) => { state.fileSaveStatusMap = action.payload },
        setAiPromptBuilderOpen: (state, action) => { state.aiPromptBuilderOpen = action.payload }
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveDocumentFilesBydId.fulfilled, (state, action) => {
             
            })

            .addCase(saveDocumentFilesBydId.rejected, (state, action) => {
                
            })

            .addCase(saveDocumentFilesBydId.pending, (state, action) => {
                state.documentFilesLoading = true;
            })

    }
})

export const { setEditAllDocumentsOpen, setMedicalsScrollFrameRef, setDemandEditOpen, setDemandDetailsEditOpen, setMedicalExtractionDialogOpen,
    setPdfPanelOpen, setResizableWidth, setDeleteMedicalOpen, setBulkDeleteOpen, setBulkEditOpen, setMedicalsTableIndex, setCreateNewDemandOpen,
    setDocumentUploaderList, setDocumentFiles, setEditOneDocumentFileName, setDocumentEdit3Url, setPdfInstance, setRedactDocumentOpen, setEditDocumentPdfOpen,
    setFileDisabled, setIsRedactorPdfDirty, setPdfRedactionIndex, setFileSaveStatusMap, setAiPromptBuilderOpen, setDocumentFilesLoading } = demandDomSlice.actions

export default demandDomSlice.reducer;