import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DataGridPro, gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { Stack, Button, Box } from '@mui/material';
import awsExports from '../aws-exports';
import { convertUTCDateTimeToLocalDate } from '../common';
import AdminTabs from '../AdminTabs';
import Title from '../CommonComponents/Title';

const CustomersView = () => {
  const { user } = useAuthenticator((context) => [context.route, context.signOut, context.user]);

  const rootApiUrl = awsExports.ROOT_API_URL;
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getCustomersApi = async () => {
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
    };

    setLoading(true);

    fetch(rootApiUrl + '/customers', settings)
      .then((response) => response.json())
      .then((data) => setCustomersData(data))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateCustomerClick = () => navigate('/customer-management/new');

  const getOwner = (customer) =>
    customer['ownerUser'] ? `${customer['ownerUser']['firstName']} ${customer['ownerUser']['lastName']}` : '';

  // Grid Column_col
  const name_col = {
    field: 'name',
    headerName: 'Name',
    minWidth: 300,
    flex: 0.25,
    getSortComparator: (sortDirection) => {
      // Custom sort function. Precedent first and then all the others alphabetically
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 === 'Precedent') {
          return -1;
        }
        if (value2 === 'Precedent') {
          return 1;
        }
        return modifier * gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2);
      };
    },
    valueGetter: (params) => params.row.name,
    renderCell: (params) => <Link to={'/customer-management/' + params.row.customerId}>{params.row.name}</Link>,
  };

  const owner_col = {
    field: 'owner',
    headerName: 'Account Owner',
    minWidth: 200,
    flex: 0.25,
    valueGetter: (params) => {
      return getOwner(params.row);
    },
  };

  const created_date_col = {
    field: 'create_dt',
    headerName: 'Created',
    minWidth: 300,
    valueGetter: (params) => {
      return convertUTCDateTimeToLocalDate(new Date(params.row.createdTs * 1000));
    },
  };

  useEffect(() => {
    getCustomersApi();
  }, []);

  const columns = React.useMemo(() => [name_col, owner_col, created_date_col]);

  return (
    <Box padding={4} pt={8}>
      <Stack spacing={2}>
        <AdminTabs selectedTab="customer-management"></AdminTabs>

        <Stack direction={'row'}>
          <Stack direction={'row'} width={'80%'} display="flex" alignItems="left">
            <Title>Exchange customers</Title>
          </Stack>
          <Stack direction={'row'} width={'20%'} justifyContent={'flex-end'}>
            <Button variant="outlined" color="secondary" onClick={handleCreateCustomerClick}>
              Create customer
            </Button>
          </Stack>
        </Stack>

        <Box height="75vh">
          <DataGridPro
            getRowId={(row) => row.customerId}
            columns={columns}
            rows={customersData}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
              pagination: { paginationModel: { pageSize: 50 } },
            }}
            disableRowSelectionOnClick={true}
            disableColumnSelector={true}
            loading={loading}
            pageSizeOptions={[25, 50, 100]}
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              mt: 1.5,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '14px',
                fontWeight: '500',
              },
              '& .MuiDataGrid-cellContent': {
                fontSize: '14px',
              },
            }}
          ></DataGridPro>
        </Box>
      </Stack>
    </Box>
  );
};

export default CustomersView;
