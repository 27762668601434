import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogTitle,
  DialogContent,
  Button,
  Stack,
  CircularProgress,
  Toolbar,
  IconButton,
  Typography,
  Skeleton,
  FormControl,
  FormLabel,
} from '@mui/material';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import { Close, InfoOutlined as InfoOutlinedIcon, PreviewOutlined as PreviewOutlinedIcon } from '@mui/icons-material';
import Title from '../../CommonComponents/Title';
import ResizableSideBySide from '../../CommonComponents/ResizableSideBySide';
import GeneralOptionsPanel from '../../CommonComponents/GeneralOptionsPanel';
import { theme } from '../../Theme';
import CodeEditor from '../../CodeEditor/CodeEditor';
import { saveTemplate } from '../../redux/thunks/adminEditTemplateData';
import { resetTemplatePreviewState } from '../../redux/slices/templatePreviewSlice';
import { resetSideBySideWithVerticalNavState } from '../../redux/slices/sideBySideWithVerticalNavSlice';
import { resetCodeEditorStates } from '../../redux/slices/codeEditorSlice';
import TemplateDetails from './TemplateDetails';
import TemplatePreview from './TemplatePreview';

const EditTemplate = ({ onClose, user, customerName, row }) => {
  const dispatch = useDispatch();
  const AdminEditTemplateState = useSelector((state) => state.AdminEditTemplate);
  const { initialCodeEditorContent, currentCodeEditorContent } = useSelector((state) => state.CodeEditor.AdminEditTemplate);
  const { documentUsage, documentUsageLoading } = useSelector((state) => state.Template);
  const { formFieldData, formMetadata, loading, isSaving } = AdminEditTemplateState;
  const { isFormDirty, hasAnyErrors } = formMetadata;
  const { status } = row;

  const [dialogState, setDialogState] = useState({
    publishWithUpdates: false,
    publishWithoutUpdates: false,
    requireUpdate: undefined,
  });

  useEffect(() => {
    return () => {
      dispatch(resetCodeEditorStates(['AdminEditTemplate', 'PreviewTemplate']));
      dispatch(resetTemplatePreviewState());
    };
  }, []);

  const {
    templateId,
    templateName,
    customerId,
  } = AdminEditTemplateState.formMetadata.editTemplateData

  const tabsAndComponentMap = [
    {
      icon: <InfoOutlinedIcon />,
      title: 'Details',
      component: <TemplateDetails user={user} />,
    },
    {
      icon: <PreviewOutlinedIcon />,
      title: 'Preview',
      component: <TemplatePreview user={user} />,
    }
  ];
  
  const notLive = status !== 'live';

  const isContentDirty = initialCodeEditorContent !== currentCodeEditorContent;
  const isDirty = isContentDirty || isFormDirty;
  const canPublish = (!hasAnyErrors && !isSaving && notLive) || (!notLive && isDirty && !isSaving && !hasAnyErrors);
  const canSave = isDirty && !hasAnyErrors && !isSaving

  const basicPayload = {
    templateId,
    templateName: formFieldData.templateName.value,
    templateContent: currentCodeEditorContent,
    setAsDefaultTemplate: formFieldData.isCustomerDefault.value,
  };

  const handleSaveClick = () => {
    dispatch(saveTemplate({
      templateData: {
        ...basicPayload,
        live: false
      },
      user,
      customerId
    }));
  };


  const handlePublishClick = () => {
    if ((documentUsage?.documentCount || 0) > 0) {
      setDialogState({ ...dialogState, publishWithUpdates: true });
    } else {
      setDialogState({ ...dialogState, publishWithoutUpdates: true });
    }
  };

  const handlePublish = (forceBumpTemplatePin) => {
    dispatch(saveTemplate({
      templateData: {
        ...basicPayload,
        live: true,
        forceBumpTemplatePin
      },
      user,
      customerId
    }));
  };

  return (
    <>
      <DialogTitle
        className="dialog-title-blue"
        sx={{ height: '60px', width: '100%', zIndex: 1000 }}
        display="flex"
        alignItems="center"
        position={'fixed'}
      >
        <Stack direction={'row'} style={{ width: '100%' }} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <IconButton
              onClick={() => {
                dispatch(resetSideBySideWithVerticalNavState());
                onClose();
              }}
              aria-label="close"
              sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.15)' }, mt: -4 }}
            >
              <Close color={'white'} />
            </IconButton>
            <Stack>
              <Title color={theme.palette.white.main}>Edit {templateName}</Title>
              <Typography variant="body2" color={theme.palette.white.main}>
                {customerName}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={2}>

            <Button
              disabled={!canSave}
              variant="outlined"
              color="white"
              sx={{
                '&:disabled': {
                  color: 'rgba(255, 255, 255, 0.5)', // Slightly darkened text color
                  borderColor: 'rgba(255, 255, 255, 0.5)', // Slightly darkened outline color
                }
              }}
              onClick={handleSaveClick}
              startIcon={isSaving && <CircularProgress size={20} color="inherit" />} // Show spinner when loading
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>

            <Button
              disabled={!canPublish}
              variant="contained"
              color="white"
              sx={{
                '&:disabled': {
                  backgroundColor: 'rgba(255,255,255,0.2)',
                },
              }}
              onClick={handlePublishClick}
            >
              {'Publish'}
            </Button>
          </Stack>

        </Stack>
      </DialogTitle>
      <Toolbar sx={{ height: '60px' }} />

      <DialogContent
        sx={{
          marginTop: .5,
          paddingTop: 0,
          overflow: 'hidden',
          pr: 0,
          pl: 1
        }}>
        {
          loading
            ? (
              <Skeleton variant="rounded" width="100%" height='calc(100vh - 80px)' />
            ) :
            (
              <Stack direction="row" >
                <ResizableSideBySide>
                  <CodeEditor parentComponentName={'AdminEditTemplate'} height="calc(100vh - 80px)" />
                  <GeneralOptionsPanel tabsAndComponentMap={tabsAndComponentMap} />
                </ResizableSideBySide>

              </Stack>
            )
        }
      </DialogContent>

      <ConfirmationDialog
        dialogTitle={`Publish ${templateName}`}
        handleCancel={() => setDialogState({ ...dialogState, publishWithUpdates: false, requireUpdate: undefined })}
        handleOk={() => handlePublish(dialogState.requireUpdate)}
        enableOkButton={!!(dialogState.requireUpdate !== undefined)}
        additonalContent=
        {
          <Stack spacing={2}>
            <Typography variant="body1">
              {`Template used in ${documentUsage?.documentCount} demands`}
            </Typography>
            <FormControl>
              <FormLabel
                id="control-publish-behavior"
              >
                How would you like to update those demands?
              </FormLabel>
              <RadioGroup
                aria-labelledby="control-publish-behavior"
                name='template-publish-behavior'
              >
                <FormControlLabel value="update" control={<Radio />} label="Require update" onChange={() => setDialogState({ ...dialogState, requireUpdate: true })} />
                <FormControlLabel value="dontUpdate" control={<Radio />} label="Optional update" onChange={() => setDialogState({ ...dialogState, requireUpdate: false })} />
              </RadioGroup>
            </FormControl>


          </Stack>
        }

        dialogOpen={dialogState.publishWithUpdates}
        cancelButtonText={'Cancel'}
        okButtonText={'Publish'}
      />

      <ConfirmationDialog
        dialogOpen={dialogState.publishWithoutUpdates}
        additonalContent={
          <Stack spacing={2}>
            <Typography variant="body1">
              {`Are you sure you want to publish ${templateName}?`}
            </Typography>
            <Typography variant="body1">
              Updates will apply immediately.
            </Typography>
          </Stack>
        }
        cancelButtonText={'Cancel'}
        okButtonText={'Yes, Publish'}
        handleCancel={() => setDialogState({ ...dialogState, publishWithoutUpdates: false })}
        handleOk={() => handlePublish(false)}
      />
    </>
  );
};

export default EditTemplate;
