import React, { useState, useEffect } from 'react';
import { Dialog, AppBar, IconButton, Button, Stack, DialogTitle, Typography } from '@mui/material';
import Title from '../../CommonComponents/Title';
import { ArrowBack } from '@mui/icons-material';
import { theme } from '../../Theme';
import { Transition } from '../../Transition';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import PdfRedactor from './PdfRedactor';
import { useDispatch, useSelector } from 'react-redux';
import { setRedactDocumentOpen, setEditOneDocumentFileName, setDocumentEdit3Url, setDocumentFiles, setFileDisabled, setFileSaveStatusMap, setIsRedactorPdfDirty } from '../../redux/slices/demandDomSlice';
import { getUploadUrl, pushDataToS3, handleUpdateFilePdf, getDocumentFilesApi, regenerateDocumentStatusApi } from '../../api'
import { setDocumentDataLoading, setDocumentRegnerationLoading } from '../../redux/slices/documentSlice';
import { fetchDocumentData } from '../../redux/thunks/documentData';
import { setDocumentEditingConfirmation } from '../../redux/slices/globalDialogueSlice';
import { DiscardWork } from '../../dialogs/DialogText';

const PdfRedactorDialog = ({ documentS3Url, documentId, user, setToastMessage, setToastSeverity, setToastOpen, setLocalDocumentFiles, file, setIsSaving }) => {

    const dispatch = useDispatch();
    const { redactDocumentOpen, editOneDocumentFileName, fileDisabled, isRedactorDirty, pdfRedactionIndex, fileSaveStatusMap } = useSelector(state => state.DemandDom);
    const [pdfRedactionInstance, setPdfRedactionInstance] = useState(null);
    const [saveConfirmationDialogOpen, setSaveConfirmationDialogOpen] = useState(false);

    const fileId = file.fileEntityId

    useEffect(() => {
        isRedactorDirty && dispatch(setIsRedactorPdfDirty(false));
    }, [redactDocumentOpen])

    const handleClose = () => {
        if (!isRedactorDirty) {
            dispatch(setEditOneDocumentFileName(''))
            dispatch(setDocumentEdit3Url(''))
            dispatch(setRedactDocumentOpen(false))
        } else {
            dispatch(setDocumentEditingConfirmation({
                open: true,
                data: {
                    source: 'pdfRedactorCloseDialog',
                    text: DiscardWork.text,
                    confirmText: DiscardWork.confirmText,
                    cancelText: DiscardWork.cancelText,
                    buttonColor: 'error'
                }
            }));
        }
    };

    const handleSaveClick = () => {
        setSaveConfirmationDialogOpen(true);
    }

    const handleSubmit = async () => {
        dispatch(setFileDisabled(fileId));
        getUploadFieldsAndRegenerate(pdfRedactionInstance);
        dispatch(setRedactDocumentOpen(false));
    }

    const handleDiscardChanges = () => {
        dispatch(setDocumentEditingConfirmation({
            open: true,
            data: {
                source: 'pdfRedactorDiscardChanges',
                text: 'Unsaved redactions will be lost. Are you sure you want to discard?',
                confirmText: 'Discard',
                cancelText: 'Cancel',
                buttonColor: 'error'
            }
        }))
    }

    const checkStatus = async (documentId) => {
        const response = await regenerateDocumentStatusApi(documentId, user);
        const status = response["status"];

        if (status === "Complete") {
            dispatch(setDocumentDataLoading(documentId));
            dispatch(fetchDocumentData({ documentId, user }))
                .then(() => {
                    dispatch(setDocumentRegnerationLoading(''));
                    dispatch(setFileDisabled(''));
                    setToastMessage(`Redactions succesful for ${editOneDocumentFileName}`);
                    setToastSeverity('success');
                    setToastOpen(true);
                    setIsSaving(false);
                    dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saved' }));
                    dispatch(setRedactDocumentOpen(false));
                })
            return;
        }
        else if (status === "Failed") {
            setToastMessage(`Error updating ${editOneDocumentFileName}`);
            setToastSeverity('error');
            setToastOpen(true);
            dispatch(setDocumentRegnerationLoading(''));
            return;
        }
        setTimeout(() => {
            checkStatus(documentId);
        }, 2000);
    }

    const getUploadFieldsAndRegenerate = async (instance) => {
        setIsSaving(true);
        dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saving' }));
        const uploadFields = await getUploadUrl(editOneDocumentFileName, documentId, user)

        const pdf = await instance.exportPDF()
            .then((buffer) => new Blob([buffer], { type: 'application/pdf' }));

        const success = await pushDataToS3(uploadFields, pdf)

        if (success) {
            dispatch(setDocumentRegnerationLoading(documentId));
            await handleUpdateFilePdf(fileId, editOneDocumentFileName, user)
                .then((response) => {
                    getDocumentFilesApi(documentId, user)
                        .then(response => response.json())
                        .then(data => {
                            dispatch(setDocumentFiles(data));
                            const updatedFile = data.find(file => file.fileEntityId === fileId);
                            setLocalDocumentFiles((prevState) => prevState.map(file => file.fileEntityId === fileId ? updatedFile : file));
                        })
                    checkStatus(documentId);
                })
                .catch((error) => {
                    dispatch(setDocumentRegnerationLoading(''));
                    setToastMessage(`Error updating ${editOneDocumentFileName}`);
                    setToastSeverity('error');
                    setToastOpen(true);
                    dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Error' }));
                });
        }
    }

    return (
        <>
            <Dialog
                fullScreen
                open={redactDocumentOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >

                <AppBar sx={{ position: 'relative' }}>
                    <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                        <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                <IconButton onClick={handleClose} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                    <ArrowBack color={'white'} />
                                </IconButton>

                                <Title color={theme.palette.white.main}>Redact content: {editOneDocumentFileName}</Title>

                            </Stack>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Button
                                        variant="outlined"
                                        color="white"
                                        sx={{ '&:disabled': { border: '1px solid rgba(255, 255, 255, 0.2)', color: 'rgba(255, 255, 255, 0.2)' } }}
                                        onClick={handleDiscardChanges}
                                        disabled={!isRedactorDirty}
                                    >
                                        Discard changes
                                    </Button>

                                    <Button
                                        disabled={!isRedactorDirty}
                                        variant="contained"
                                        color="white"
                                        sx={{ '&:disabled': { backgroundColor: ("rgba(255,255,255,0.2)") } }}
                                        onClick={handleSaveClick}
                                    >
                                        Save
                                    </Button>

                                </Stack>

                            </Stack>
                        </Stack>
                    </DialogTitle>
                </AppBar>

                <div style={{ height: "60px" }}></div>

                <PdfRedactor
                    documentS3Url={documentS3Url}
                    setPdfRedactionInstance={setPdfRedactionInstance}
                    pdfRedactionIndex={pdfRedactionIndex}
                />

            </Dialog>

            <ConfirmationDialog
                dialogOpen={saveConfirmationDialogOpen}
                handleCancel={() => setSaveConfirmationDialogOpen(false)}
                handleOk={handleSubmit}
                dialogText={
                    <Stack spacing={2}>
                        <Typography variant='body1' color={"primary"}>
                            Redactions apply immediately and cannot be undone.
                        </Typography>
                        <Typography variant='body1' color={"primary"}>
                            Are you sure you want to continue?
                        </Typography>
                    </Stack>
                }
                okButtonText={'Apply redactions'}
                cancelButtonText={'Cancel'}
            />
        </>
    );
}

export default PdfRedactorDialog;