import React from 'react';
import { Alert, Button, CircularProgress } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { bumpTemplatePin } from '../redux/thunks/templateData';
import { useDispatch, useSelector } from 'react-redux';
import { isPrecedentUser } from '../common-roles';
import { LAW_FIRM_VIEW_TYPE, PRECEDENT_VIEW_TYPE } from '../common-view-types';

export const shouldDisplayAlert = (templateBody, documentMetaData) => {
    if (!templateBody?.live) return false;
    const templateVersion = templateBody ? parseInt(templateBody.version, 10) : NaN;
    const demandTemplatePinnedVersion = documentMetaData ? parseInt(documentMetaData.demandTemplatePinnedVersion, 10) : NaN;
    return !Number.isNaN(templateVersion) && !Number.isNaN(demandTemplatePinnedVersion) && templateVersion !== demandTemplatePinnedVersion;
};

const TemplateUpdateAlert = ({ user, templateBody, documentMetaData, viewType }) => {
    const dispatch = useDispatch();
    const { bumpTemplatePinLoading, templateFetchLoading } = useSelector((state) => state.Template);
    const { documentDataLoading } = useSelector((state) => state.Document);

    const handleUpdateTemplateClick = async () => {
        dispatch(bumpTemplatePin({ documentId: documentMetaData.documentId, user, templateId: templateBody.templateId }));
    };

    const templateVersion = templateBody ? parseInt(templateBody.version, 10) : NaN;
    const demandTemplatePinnedVersion = documentMetaData ? parseInt(documentMetaData.demandTemplatePinnedVersion, 10) : NaN;
    const isAnythingLoading = bumpTemplatePinLoading || templateFetchLoading || documentDataLoading;

    return (
        <Alert
            icon={<WarningAmberOutlinedIcon fontSize="inherit" />}
            severity="warning"
            action={

                <Button
                    color="primary"
                    size="small"
                    onClick={handleUpdateTemplateClick}
                    disabled={isAnythingLoading}
                >
                    {isAnythingLoading ? <CircularProgress size={20} /> : 'Update template'}
                </Button>
            }
        >
            {`New version of ${templateBody.templateName} available ${viewType === PRECEDENT_VIEW_TYPE ? `(current: v${demandTemplatePinnedVersion}, latest: v${templateVersion})` : ''}`}
        </Alert>
    );
};

export default TemplateUpdateAlert;