import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLiveTemplateById, getDocumentUsageForTemplate, bumpTemplatePinforDocument} from '../../api';
import { checkRegeneratePdfStatus } from './documentData';
import { setDocumentDataLoading } from '../slices/documentSlice';
import { setToast } from '../slices/globalToastSlice';

export const fetchTemplateData = createAsyncThunk('Templates/fetchTemplateData', async ({ templateId, user }, {dispatch, rejectWithValue}) => {
    try {
        if (!user || !templateId) {
            return;
          }
        const response = await getLiveTemplateById(templateId, user);
        return response['template'];
    } catch (err) {
        return rejectWithValue(new Error('Failed to fetch template data'));
    }
});

export const fetchTemplateDocumentUsage = createAsyncThunk('Templates/fetchDocumentUsage', async ({ templateId, user }, {dispatch, rejectWithValue}) => {
    try {
        if (!user || !templateId) {
            return;
          }
        const response = await getDocumentUsageForTemplate(templateId, user);
        return response;
    } catch (err) {
        return rejectWithValue(new Error('Failed to fetch document usage'));
    }
});

export const bumpTemplatePin = createAsyncThunk('Templates/bumpTemplatePin', async ({ documentId, user, templateId }, {dispatch, rejectWithValue}) => {
    try {
        if (!user || !documentId) {
            return;
          }
        return bumpTemplatePinforDocument(documentId, user)
        .then(() => {
            dispatch(fetchTemplateData({ templateId, user }));
        })
        .then(() => {
            dispatch(setDocumentDataLoading(documentId));
            setTimeout(() => {
                dispatch(checkRegeneratePdfStatus(documentId, user));
                dispatch(setToast({ message: 'Template updated', type: 'success' }));
            }, 3000);
        });


    } catch (err) {
        dispatch(setToast({ message: 'Failed to bump template pin', type: 'error' }));
        return rejectWithValue(new Error('Failed to bump template pin'));
    }
});