import { createAsyncThunk } from '@reduxjs/toolkit';
import {getDocumentApi, regenerateDocumentStatusApi } from '../../api';
import { setDocumentDataLoading, setDocumentRegnerationLoading } from '../slices/documentSlice';
import { setToast } from '../slices/globalToastSlice';

export const fetchDocumentData = createAsyncThunk('Document/fetchDocumentData', async ({ documentId, user }, {}) => {
  const response = await getDocumentApi(documentId, user).then((serverResponse) => {
    if (serverResponse.status === 200) {
      return serverResponse.json();
    } else if (serverResponse.status === 403) {
      return false;
    }
  });

  return response;
});

export const checkRegeneratePdfStatus = (documentId, user) => (dispatch) => {
  const recursivelyCheckGeneratedPdfStatus = (documentId, user) => {
    regenerateDocumentStatusApi(documentId, user)
      .then((response) => {
        if (response?.status === 'DocumentRegeneration' || response?.status === 'InProcess') {
          setTimeout(() => {
            recursivelyCheckGeneratedPdfStatus(documentId, user);
          }, 3000);
        } else {
          dispatch(setDocumentDataLoading(documentId));
          dispatch(fetchDocumentData({ documentId, user }));
          dispatch(setDocumentRegnerationLoading(''));
          // Done processing
          return response
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setDocumentRegnerationLoading(''));
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating demand' }));
      });
  }

  // Start flow
  dispatch(setDocumentRegnerationLoading(documentId));  // Raise PDF Skeleton
  recursivelyCheckGeneratedPdfStatus(documentId, user);
};

