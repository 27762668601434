import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { setEditAllDocumentsOpen, setEditOneDocumentFileName, setDocumentEdit3Url, setFileDisabled, setRedactDocumentOpen, setIsRedactorPdfDirty, setPdfRedactionIndex } from '../redux/slices/demandDomSlice';

const DocumentEditingConfirmation = () => {
    const dispatch = useDispatch();

    const { documentEditingConfirmation } = useSelector((state) => state.GlobalDialogues);
    const { pdfRedactionIndex } = useSelector((state) => state.DemandDom);
    const { open, data } = documentEditingConfirmation;
    const { title, text, confirmText, cancelText, source, buttonColor } = data;

    const customHandlerDictionary = {
        editDocuments: {
            handleOk: () => {
                dispatch(setEditAllDocumentsOpen(false));
                dispatch(resetGlobalDialogues());
            },
            handleCancel: () => {
                dispatch(resetGlobalDialogues());
            }
        },
        pdfRedactorCloseDialog: {
            handleOk: () => {
                dispatch(setEditOneDocumentFileName(''))
                dispatch(setDocumentEdit3Url(''))
                dispatch(setFileDisabled(''))
                dispatch(setRedactDocumentOpen(false))
                dispatch(resetGlobalDialogues());
                dispatch(setIsRedactorPdfDirty(false));
            },
            handleCancel: () => {
                dispatch(resetGlobalDialogues());
            },
        },
        pdfRedactorDiscardChanges: {
            handleOk: () => {
                dispatch(setIsRedactorPdfDirty(false))
                dispatch(setPdfRedactionIndex(pdfRedactionIndex + 1))
                dispatch(resetGlobalDialogues());
            },
            handleCancel: () => {
                dispatch(resetGlobalDialogues());
            }
        }
    };

    return (
        <ConfirmationDialog
            dialogOpen={open}
            handleCancel={customHandlerDictionary[source]?.handleCancel}
            handleOk={customHandlerDictionary[source]?.handleOk}
            dialogTitle={title}
            dialogText={text}
            okButtonText={confirmText}
            cancelButtonText={cancelText}
            buttonColor={'secondary'}
        />
    );
};

export default DocumentEditingConfirmation;