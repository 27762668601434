import moment from 'moment';

export const isDocumentUrlExpired = (documentS3Url) => {
  const documentS3urlExpiryTime = Number(documentS3Url ? documentS3Url.split('Expires=')[1] : 0);
  const currentTime = moment().unix();
  return documentS3urlExpiryTime < currentTime;
};

export const DOCUMENT_UPLOAD_FILE_TYPES = '.pdf,.jpg,.jpeg,.png,.tif,.tiff,.doc,.docx';

export const DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT = 'Demand is currently being processed by Precedent';
