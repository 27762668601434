import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    component_A_width: 0,
    component_B_width: 0,
    isClosed: false,
    component_B_current_minimum_width: 350,
    settings: {
        component_B_minimum_width: 350,
        component_A_minimum_width: 350,
        closedWidth: 58,
    }
};

export const SideBySideWithVerticalNavSlice = createSlice({
    name: 'SideBySideWithVerticalNav',
    initialState,
    reducers: {

        set_component_A_width: (state, action) => {
            state.component_A_width = action.payload
        },
        set_component_B_width: (state, action) => {
            state.component_B_width = action.payload
        },
        setScreenWidth: (state, action) => {
            state.screenWidth = action.payload
        },
        setIsClosed: (state, action) => {
            state.isClosed = action.payload
            state.component_B_current_minimum_width = action.payload ? state.settings.closedWidth : state.settings.component_B_minimum_width;
            state.component_A_width = action.payload ? window.innerWidth - state.settings.closedWidth : state.settings.component_A_minimum_width;







            // if(action.payload){
            //     state.component_B_current_minimum_width = state.settings.closedWidth;
            // } else {
            //     state.component_B_current_minimum_width = state.settings.component_B_minimum_width;
            // }
        },
        resetSideBySideWithVerticalNavState: (state) => initialState,
    },
})

export const { set_component_A_width, set_component_B_width, resetSideBySideWithVerticalNavState, setScreenWidth, setIsClosed } = SideBySideWithVerticalNavSlice.actions

export default SideBySideWithVerticalNavSlice.reducer;