import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDocumentInsightsByType } from '../../api';

export const fetchDocumentInsightsMriData = createAsyncThunk(
  'DocumentInsights/fetchDocumentInsightsMriData',
  async ({ documentId, user }) => {
    if (!user || !documentId) {
      return;
    }
    return getDocumentInsightsByType(documentId, 'mri', user)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => data);
  }
);
