
import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Alert, DialogContentText, Stack, CircularProgress, Typography } from '@mui/material';
import { Transition } from '../Transition'
import Title from '../CommonComponents/Title';
import { approveFirmDemandApi, getDocumentApi } from '../api';
import StatusDialog from "./StatusDialog";
import { getMedicalTreatmentAlerts } from "../MedicalsComponents/medicals";
import ConfirmationSwitch from '../CommonComponents/ConfirmationSwitch';
import { useSelector, useDispatch } from 'react-redux';
import { setDocumentData } from '../redux/slices/documentSlice';
import { setMedicalsTreatments } from '../redux/slices/medicalsSlice';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { fetchMedicalsData } from '../redux/thunks/medicalsData';


const FirmApproveDialog = ({ handleClose, dialogOpen, documentId, user, handler, viewType, source }) => {
    const dispatch = useDispatch();
    const { documentData, documentDataLoading } = useSelector((state) => state.Document);
    const { medicals, medicalsLoading } = useSelector((state) => state.Medicals);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [medicalTreatmentAlerts, setMedicalTreatmentAlerts] = useState([]);
    const [switchChecked, setSwitchChecked] = useState(false);
    const currentDocumentFetched = documentData?.documentId === documentId;
    const hasAlerts = medicalTreatmentAlerts.length > 0;
    const buttonIsDisabled = (hasAlerts && switchChecked) || (!hasAlerts)

    useEffect(() => {
        if (source === 'inventory') {
            dispatch(fetchDocumentData({ documentId, user }));
            dispatch(fetchMedicalsData({ documentId, user }));
        }
    }, [documentId]);

    useEffect(() => {
        if (!documentDataLoading && !medicalsLoading && currentDocumentFetched) {
            setMedicalTreatmentAlerts(getMedicalTreatmentAlerts(documentData, viewType, true, true, medicals));
        }
    }, [documentDataLoading, medicalsLoading]);

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const approveDemand = (userTriggered) => {

        if (userTriggered) {
            handleClose();
            setStatusCloseDisabled(true);
            showStatusDialog("Sending the demand to the carrier...");
        }

        approveFirmDemandApi(documentId, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("The demand has been sent to the carrier.");
                    setStatusCloseDisabled(false);
                    handler(true);
                }
                else if (response.status == 201) {
                    setStatusDialogText("The demand is being regenerated. This should only take a few moments.");
                    setTimeout(function () { approveDemand(false) }, 3000);
                }
                else {
                    showStatusDialog("There was an error sending the email.");
                    setStatusCloseDisabled(false);
                    handler(false);
                }
            });
    };

    return (
        <>
            <Dialog
                maxWidth="xs"
                open={dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={2}>
                        <Title>Approve & Send to Carrier</Title>
                        {(documentDataLoading || medicalsLoading) && (
                            <CircularProgress />
                        )}
                    </Stack>
                </DialogTitle>
                <DialogContent>

                    {hasAlerts && (
                        <Box>
                            <DialogContentText id="alert-dialog-slide-description">
                                The following warnings exist on this demand:
                            </DialogContentText>

                            <div>
                                {medicalTreatmentAlerts.map((alert, index) => {
                                    return (
                                        <Alert sx={{ mt: 1 }} key={index} severity={alert.alertSeverity}>{alert.alertMessage}</Alert>
                                    )
                                }
                                )}
                            </div>
                            <ConfirmationSwitch setSwitchChecked={setSwitchChecked} switchChecked={switchChecked} labelText='Send demand despite warnings'></ConfirmationSwitch>
                        </Box>
                    )}

                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack spacing={2}>
                            <Typography variant="body1" color={"primary"}>
                                This demand will be submitted to {documentData?.recipientCarrier?.carrierCommonName || ''} by Precedent.
                            </Typography>
                            <Typography variant="body1" color={"primary"}>
                                Are you sure you want to continue?
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => { handleClose() }}>Cancel</Button>
                    <Button disabled={!buttonIsDisabled} variant="contained" color="secondary" onClick={() => { approveDemand(true) }}>Approve & send</Button>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default FirmApproveDialog;
