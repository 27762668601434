
import awsExports from '../aws-exports';
const rootApiUrl = awsExports.ROOT_API_URL;

export const postTemplate = async (payload, user, targetCustomer = null) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(payload)
    }

    const url = targetCustomer ? `${rootApiUrl}/template?onBehalfOf=${targetCustomer}` : `${rootApiUrl}/template`;
    const response = await fetch(url, settings)
        .then((response) => response);

    return response;
}

export const generateTemplatePreviewApi = async (templateText, jsonBlobText, user) => {
    const lambdaRequestBody = {
        template_content: templateText,
        json_content: JSON.parse(jsonBlobText),
        testing: true,
        testingWithAuxiliaryData: true
    };

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(lambdaRequestBody)
    };


    return fetch(rootApiUrl + "/generateTestDemandPdf", settings)
        .then(response => response.json())
        .catch(err => err);
}

export const loadAiData = async (documentId, values, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(values)
    };
    return fetch(`${rootApiUrl}/document/${documentId}/getAiRequestData`, settings)
        .then((response) => response.text())
        .catch(err => err);
}
