import React from 'react'
import { Dialog } from '@mui/material'

export const DialogWrapper = (props) => {
    //any props that you want to pass to the Dialog component
    return (
  
      <Dialog
        {...props}
      >
        {props.children}
      </Dialog>
    )
  }

export default DialogWrapper