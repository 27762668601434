import { useNavigate, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";

import { DataGridPro, GridRowsProp, GridColDef } from '@mui/x-data-grid-pro';
import Container from '@mui/material/Container';
import { LinearProgress, Stack } from '@mui/material';
import awsExports from './aws-exports';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { formatDate, formatCurrency, mapStatusToChipClass, mapStatusToLabel } from './common';

export function AdjusterList(props) {

    const DEFAULT_PAGE_SIZE = 25
    const USD = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const DT = Intl.DateTimeFormat('en-US', { weekday: "short", year: "numeric", month: "short", day: "numeric" });
    const [demandData, setDemandData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const getDemandList = async () => {
        const rootApiUrl = awsExports.ROOT_API_URL;

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/submissions", settings
        ).then((response) => response.json())
            .finally(() => {
                setLoading(false);
            });

        setDemandData(response);
    };

    const dateComparator = (v1, v2) => {
        const d1 = new Date(v1);
        const d2 = new Date(v2);
        return d2 - d1;
    };

    const currencyComparator = (v1, v2) => {
        v1 = v1.replace("$", "").replaceAll(",", "");
        v2 = v2.replace("$", "").replaceAll(",", "");
        if (v1 == "")
            v1 = 0;
        if (v2 == "")
            v2 = 0;
        v1 = parseFloat(v1);
        v2 = parseFloat(v2);
        return v1 - v2;
    };

    const columns = [
        {
            field: 'document_id',
            headerName: 'Demand Id',
            minWidth: 275,
            headerAlign: 'center',
            valueGetter: (params) => {
                return params.row.document.documentId
            },

        },
        {
            field: 'claim_number',
            headerName: 'Claim Number',
            minWidth: 250,
            headerAlign: 'center',
            align: 'left',
            valueGetter: (params) => {
                return params.row.document.claimInfo.claimNumber
            },
            renderCell: (params) => (
                <Link to={"/adjuster/d/" + params.row.document.documentId}>{params.row.document.claimInfo.claimNumber ? params.row.document.claimInfo.claimNumber : params.row.document.documentId}</Link>
            )
        },
        {
            field: 'loss_state',
            headerName: 'Loss State',
            minWidth: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return params.row.document.claimInfo.lossState
            }
        },
        {
            field: 'demand_due_date',
            headerName: 'Due Date',
            minWidth: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return formatDate(params.row.document.demandDetails.demandResponseDueDate);
            }
        },
        {
            field: 'demand_amt',
            headerName: 'Demand Amount',
            minWidth: 125,
            type: 'number',
            headerAlign: 'center',
            align: 'center',
            sortComparator: currencyComparator,
            valueGetter: (params) => {
                if (params.row.document.demandDetails.demandAmount != '')
                    return formatCurrency(params.row.document.demandDetails.demandAmount + '');
                else
                    return '';
            }

        },
        {
            field: 'policy_limit',
            headerName: 'Policy Limit',
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return params.row.document.demandDetails.policyLimitDemandIndicator ? "Yes" : "No";
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 175,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return params.row.documentStatus;
            },
            renderCell: (params) => (
                <div className={mapStatusToChipClass(params.row.documentStatus)}>{mapStatusToLabel(params.row.documentStatus)}</div>
            )
        }
    ];

    useEffect(() => {
        getDemandList();
    }, []);

    return (
        <Container maxWidth='xl' sx={{
            flexGrow: 1,
            height: '90vh',
            overflow: 'auto'
        }} className='header-offset'>
            <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>

            </Stack>

            <DataGridPro autoHeight getRowId={(row) => row.document.documentId} columns={columns} rows={demandData}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'document_id', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns status and traderName, the other columns will remain visible
                            document_id: false,
                        },
                    }

                }}
                getRowHeight={() => 'auto'}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                disableRowSelectionOnClick={true}
                disableColumnSelector={false}
                loading={loading}
                pageSizeOptions={[25, 50, 100]}

                sx={{
                    backgroundColor: "#fff",
                    m: 1,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: '9pt',
                        fontWeight: '500'
                    },
                    '& .MuiDataGrid-cellContent': {
                        fontSize: '9pt',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }
                }}

                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'
                }
            >
            </DataGridPro>



        </Container>
    );

}