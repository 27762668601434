import { createSlice } from '@reduxjs/toolkit';
import { fetchCodeEditorContent } from '../thunks/codeEditorData';

//each parent component/instance with have its own state for the code editor
const originalCodeEditorSlice = {
    AdminEditTemplate: {
        initialCodeEditorContent: '',
        currentCodeEditorContent: '',
        codeEditorContentFetched: false,
        codeEditorLoading: false,
    },
    PreviewTemplate: {
        initialCodeEditorContent: '',
        currentCodeEditorContent: '',
        codeEditorContentFetched: true,
        codeEditorLoading: false,
    }

};

const codeEditorSlice = createSlice({
    name: 'CodeEditor',
    initialState: originalCodeEditorSlice,
    reducers: {
        setInitialCodeEditorContent: (state, action) => {
            state[action.payload.parentComponentName].initialCodeEditorContent = action.payload.content;
        },
        setCurrentCodeEditorContent: (state, action) => {
            state[action.payload.parentComponentName].currentCodeEditorContent = action.payload.content;
        },
        setCodeEditorContentFetched: (state, action) => {
            state[action.payload.parentComponentName].codeEditorContentFetched = action.payload.fetched;
        },
        resetCodeEditorStates: (state, action) => {
            return originalCodeEditorSlice;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchCodeEditorContent.pending, (state, action) => {
            state[action.meta.arg.parentComponentName].loading = true;
        });

        builder.addCase(fetchCodeEditorContent.fulfilled, (state, action) => {
            state[action.meta.arg.parentComponentName].initialCodeEditorContent = action.payload;
            state[action.meta.arg.parentComponentName].currentCodeEditorContent = action.payload;
            state[action.meta.arg.parentComponentName].codeEditorContentFetched = true;
            state[action.meta.arg.parentComponentName].loading = false;
        });

        builder.addCase(fetchCodeEditorContent.rejected, (state, action) => {
            state[action.meta.arg.parentComponentName].loading = false;
        });

    }
});

export const {
    setInitialCodeEditorContent,
    setCurrentCodeEditorContent,
    setCodeEditorContentFetched,
    resetCodeEditorStates
} = codeEditorSlice.actions;

export default codeEditorSlice.reducer;
