import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  TextField,
  CircularProgress,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { batchUpdatePageInsights, getDocumentFilesApi } from '../api';
import { setDocumentFiles } from '../redux/slices/demandDomSlice';
import { selectPageInsights, INCLUDED_STATUS } from '../MedicalsComponents/pageInsights';
import { checkRegeneratePdfStatus } from '../redux/thunks/documentData';
import { setDocumentRegnerationLoading, setDocumentFilesLoading } from '../redux/slices/documentSlice';
import { setToast } from '../redux/slices/globalToastSlice';
import ICDCodeAutocomplete from '../MedicalsComponents/ICDCodes/ICDCodeAutocomplete';
import { v4 as uuidv4 } from 'uuid';

const AddICDCode = () => {
  const dispatch = useDispatch();

  const { addICDCode } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = addICDCode;
  const { documentId, user } = data;

  const [icdCodeFormValue, setIcdCodeFormValue] = useState(['', '']);
  const [icdCode, description] = icdCodeFormValue;

  const [pages, setPages] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  const isIcdCodeDirty = icdCode !== '';
  const isPageDirty = pages.length > 0;

  const { documentFiles } = useSelector((state) => state.DemandDom);

  const getPageOptions = () => {
    const lastPage = documentFiles[documentFiles.length - 1]?.fileEntityData?.endPage;
    return Array.from({ length: lastPage }, (_, index) => index + 1);
  };

  const sortedPages = pages.sort((a, b) => a - b);

  const handleIcdCodeChange = (value) => {
    if (Array.isArray(value)) {
      setIcdCodeFormValue(value);
    } else if (value === null) {
      setIcdCodeFormValue(['', '']);
    }
  };

  const handleSaveClick = () => {
    setIsSaving(true);
    dispatch(setDocumentFilesLoading(true)); // Table skeleton
    dispatch(setDocumentRegnerationLoading(documentId)); // PDF Skeleton

    // Get files to update based on selected page numbers.
    const filesToUpdate = [];
    pages.forEach((page) => {
      documentFiles.find((file) => {
        if (
          file.fileEntityData.startPage <= page &&
          file.fileEntityData.endPage >= page &&
          !filesToUpdate.includes(file)
        ) {
          filesToUpdate.push(file);
        }
      });
    });
    // Build payload by adding new ICD code to the selected pages.
    const payload = filesToUpdate.map((file) => {
      const pageInsightsWithNewIcdCode = selectPageInsights(file).map((pageInsight) => {
        if (pages.includes(pageInsight.generated_page_number)) {
          return {
            ...pageInsight,
            entities: {
              ...pageInsight.entities,
              icd10_codes: [
                ...pageInsight.entities.icd10_codes,
                {
                  icd_code_id: uuidv4(),
                  value: icdCode,
                  description,
                  status: INCLUDED_STATUS,
                  needs_review: false,
                  needs_review_reason_internal: [],
                  bboxes: [],
                },
              ],
            },
          };
        }

        return {
          ...pageInsight,
        };
      });

      return {
        fileEntityId: file.fileEntityId,
        pageInsights: pageInsightsWithNewIcdCode,
      };
    });

    batchUpdatePageInsights(documentId, payload, user)
      .then(() => {
        getDocumentFilesApi(documentId, user)
          .then((response) => response.json())
          .then((data) => {
            dispatch(setDocumentFiles(data));
            dispatch(setDocumentFilesLoading(false));
            setIsSaving(false);
          });

        dispatch(checkRegeneratePdfStatus(documentId, user));
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(setDocumentFilesLoading(false));
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with adding ICD code' }));
      });

    dispatch(resetGlobalDialogues());
  };

  const handleClose = () => {
    dispatch(resetGlobalDialogues());
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Add ICD code</DialogTitle>

      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <ICDCodeAutocomplete value={icdCodeFormValue} onChange={handleIcdCodeChange} user={user} />
          <Autocomplete
            id="select-page-number"
            multiple
            limitTags={5}
            disableCloseOnSelect
            options={getPageOptions()}
            value={sortedPages}
            onChange={(e, value) => {
              setPages(value);
            }}
            getOptionLabel={(option) => option.toString()}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Page" />}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!isIcdCodeDirty || !isPageDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          {isSaving ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddICDCode;
