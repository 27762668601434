import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Container from '@mui/material/Container';
import {
  Stack,
  ThemeProvider,
  Button,
  Alert,
  Snackbar,
  Tooltip,
  Menu,
  Typography,
  IconButton,
  Grid,
  Box,
  ListItemText,
  ListItemIcon,
  Divider,
  MenuItem,
  Badge,
  Paper,
} from '@mui/material';
import { theme } from '../Theme';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { isRequestApprovalEnabled, formatDateTime, userHasPermission, userHasRole } from '../common';
import { buildProviderSummary } from '../common-medicals';
import {
  DOCUMENT_UPLOADED,
  AWAITING_MEDICAL_EXTRACTION,
  AWAITNG_PRECEDENT_SIGNOFF,
  WAITING_FIRM_APPROVAL,
  REJECTED_BY_LAW_FIRM,
} from '../common-demand';
import { isDocumentUrlExpired, DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT } from '../common-document';
import { LAW_FIRM_APPROVER_ROLE, LAW_FIRM_ADMIN_ROLE } from '../common-roles';
import MedicalsProviderSummary from '../MedicalsComponents/MedicalsProviderSummary';
import MedicalsOverview from '../MedicalsComponents/MedicalsOverview';
import MedicalsTreatmentsChart from '../eCharts/MedicalsTreatmentsChart';
import MedicalsOverviewPrecedent from '../MedicalsComponents/MedicalsOverviewPrecedent';
import MedicalsTreatments from '../MedicalsComponents/MedicalsTreatments';
import FirmRejectDialog from '../dialogs/FirmRejectDialog';
import FirmApproveDialog from '../dialogs/FirmApproveDialog';
import SendPreviewDialog from '../dialogs/SendPreviewDialog';
import AttorneySendDialog from '../dialogs/AttorneySendDialog';
import ArchiveDialog from '../dialogs/ArchiveDialog';
import StatusDialog from '../dialogs/StatusDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { getUserApi, rejectDemandApi, archiveApi, getConfigValue } from '../api';
import { fetchTemplateData } from '../redux/thunks/templateData';
import { Transition } from '../Transition';
import {
  ArrowBack,
  ErrorOutline,
  MoreHorizOutlined,
  PictureAsPdfOutlined,
  RemoveRedEyeOutlined,
  SendRounded,
  TextSnippetOutlined,
  ArchiveOutlined,
  EditNoteSharp,
} from '@mui/icons-material';
import CollapseCard from '../CommonComponents/CollapseCard';
import EditMenu from '../DemandComponents/EditMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAllDocumentsOpen, setDemandDetailsEditOpen } from '../redux/slices/demandDomSlice';
import { canComposeDemand, isCompositeDemand, hasNotAllowedStatus } from '../DemandComponents/helpers';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { fetchCustomerData } from '../redux/thunks/customerData';
import { fetchMedicalsData } from '../redux/thunks/medicalsData';
import { clearDocumentState } from '../redux/slices/documentSlice';
import { setEditDemandNotes, setEditDocumentNames } from '../redux/slices/globalDialogueSlice';
import StatusChip from '../CommonComponents/Chips/StatusChip';
import UploadDocumentsMenuItem from '../DemandComponents/UploadDocumentsMenuItem';
import DemandWorkflowTooltip from '../DemandComponents/DemandWorkflowTooltip';

export const MedicalsLawFirmViewer = () => {
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const [providerSummary, setProviderSummary] = useState([]);
  const [userData, setUserData] = useState();
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
  const [statusDialogText, setStatusDialogText] = useState('');
  const [statusDialogTitle, setStatusDialogTitle] = useState('');
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogTitle, setInfoDialogTitle] = useState('');
  const [infoDialogText, setInfoDialogText] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastSeverity, setToastSeverity] = useState('success');
  const [toastTimeout, setToastTimeout] = useState(2000);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [attorneyDialogOpen, setAttorneyDialogOpen] = useState(false);
  const [attorneyConfirmationDialogOpen, setAttorneyConfirmationDialogOpen] = useState(false);
  const [deliveryEmail, setDeliveryEmail] = useState();
  const [deliveryFax, setDeliveryFax] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [uploadErrorState, setUploadErrorState] = useState({});
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [useDemandNotesEnabled, setUseDemandNotesEnabled] = useState(false);

  const menuopen = Boolean(anchorEl);

  const { user } = useAuthenticator((context) => [context.user]);
  const getUser = async () => {
    setUserData(await getUserApi(user));
    setUseDemandNotesEnabled((await getConfigValue('useDemandNotesEnabled', null, user)) === 1);
  };
  const [carrierEmailAddress, setCarrierEmailAddress] = useState();
  const [carrierFaxNumber, setCarrierFaxNumber] = useState();
  const documentDataState = useSelector((state) => state.Document);
  const { documentS3Url, documentData, documentMetaData, documentStatus, deliveryAttorneyEmail, noPermissions } =
    documentDataState;
  const { customerId } = documentData || {};
  const { medicals } = useSelector((state) => state.Medicals);

  const navigate = useNavigate();
  const location = useLocation();

  const noDemandResponseDueDate =
    !documentData?.demandDetails?.demandResponseDueDate ||
    documentData?.demandDetails?.demandResponseDueDate === 'Invalid date';

  const handleRejectDialogClose = () => setRejectDialogOpen(false);
  const handleApproveDemand = () => setApproveDialogOpen(true);
  const handleSendForApproval = () => {
    if (userHasRole(LAW_FIRM_APPROVER_ROLE, userData)) {
      setAttorneyConfirmationDialogOpen(true);
    } else {
      setAttorneyDialogOpen(true);
    }
  };
  const handleApproveDialogClose = () => setApproveDialogOpen(false);
  const handleRejectDemand = () => setRejectDialogOpen(true);

  const showToastMessage = (message, severity = 'success', timeout = 2000) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastTimeout(timeout);
    setToastOpen(true);
  };

  const handleToastClose = () => {
    setToastMessage('');
    setToastOpen(false);
  };

  const handleInfoDialogClose = () => setInfoDialogOpen(false);
  const handlePreviewEmail = () => {
    setPreviewDialogOpen(true);
    handleMenuClose();
  };
  const handlePreviewDialogClose = () => setPreviewDialogOpen(false);
  const handleStatusDialogClose = () => setStatusDialogOpen(false);
  const handleAttorneyDialogClose = () => {
    setAttorneyDialogOpen(false);
    handleAttorneyConfirmationDialogClose();
  };
  const handleAttorneyConfirmationDialogClose = () => setAttorneyConfirmationDialogOpen(false);
  const handleAttorneyConfirmationDialogOk = () => {
    handleAttorneyConfirmationDialogClose();
    setAttorneyDialogOpen(true);
  };

  const getDocument = () => {
    dispatch(fetchDocumentData({ documentId, user }));
  };

  useEffect(() => {
    if (user && documentId) {
      dispatch(fetchDocumentData({ documentId, user }));
      dispatch(fetchMedicalsData({ documentId, user }));
    }
  }, [user, documentId]);

  useEffect(() => {
    if (user && documentMetaData) {
      dispatch(fetchTemplateData({ templateId: documentMetaData?.demandTemplateId, user }));
    }
  }, [user, documentMetaData]);

  useEffect(() => {
    if (customerId && user) {
      dispatch(fetchCustomerData({ customerId, user }));
    }
  }, [customerId, user]);

  const handleSendComplete = (success) => {
    success && getDocument();
  };

  const handleArchiveDemand = (event) => {
    handleMenuClose(event);
    setArchiveDialogOpen(true);
  };

  const handleArchiveDialogClose = () => {
    setArchiveDialogOpen(false);
  };

  const showStatusDialog = (text, isCloseDisabled = false) => {
    setStatusDialogText(text);
    setStatusDialogOpen(true);
    setStatusCloseDisabled(isCloseDisabled);
  };

  const findStatusEventDate = (status, statusHistory) => {
    const matchedStatus = statusHistory.find((element) => element.documentStatus == status);
    return matchedStatus;
  };

  const findDeliveryStatusEventDate = (status, statusHistory) =>
    statusHistory.find((element) => element.deliveryStatus == status);
  const getStatusTimestamp = (statusEventId) => {
    const statusEvent = findStatusEventDate(statusEventId, documentMetaData.documentStatusHistory);
    return statusEvent ? formatDateTime(new Date(statusEvent.timestamp * 1000)) : '';
  };

  const getDeliveryChannel = () => {
    if (findDeliveryStatusEventDate('FaxSent', documentMetaData.deliveryStatusHistory)) {
      return 'Fax';
    }
    if (findDeliveryStatusEventDate('EmailSent', documentMetaData.deliveryStatusHistory)) {
      return 'Email';
    }
    if (findDeliveryStatusEventDate('ManuallySent', documentMetaData.deliveryStatusHistory)) {
      return 'Manually Sent';
    }
    return '';
  };

  const handleBackNavClick = () => (location.state?.from ? navigate(location.state.from) : navigate('/inventory'));
  const handleMenuClose = () => setAnchorEl(null);

  const rejectDemand = (reason, comment) => {
    setRejectDialogOpen(false);
    showStatusDialog('Sending notification to attorney');

    rejectDemandApi(documentId, { rejectReason: reason, comment: comment }, user).then((response) => {
      if (response.status == 200) {
        showStatusDialog('The notification has been delivered');
        getDocument();
      } else {
        showStatusDialog('There was an error delivering the rejection. Please contact support.');
      }
    });
  };

  const archiveDemand = async (archiveReason, archiveComments) => {
    setArchiveDialogOpen(false);
    const archiveData = {
      archiveReason: archiveReason,
      archiveComments: archiveComments,
    };

    archiveApi(documentId, archiveData, user).then((response) => {
      if (response.status == 200) {
        showStatusDialog('Success! The demand has been archived.');
        getDocument();
      } else {
        showStatusDialog('There was an error archiving the demand.');
      }
    });
  };

  const sendFirmApprovalRequestComplete = (success) => success && getDocument();
  const sendPreviewComplete = (success) => null;

  useEffect(() => {
    medicals?.medicalTreatments && setProviderSummary(buildProviderSummary(medicals.medicalTreatments));
  }, [medicals]);

  useEffect(() => {
    getUser();
    getDocument();

    return () => {
      dispatch(clearDocumentState());
    };
  }, []);

  const isEditDocumentsDisabled =
    isDocumentUrlExpired(documentS3Url) ||
    (userHasPermission('DemandCompose', userData) &&
      [DOCUMENT_UPLOADED, AWAITING_MEDICAL_EXTRACTION, AWAITNG_PRECEDENT_SIGNOFF, REJECTED_BY_LAW_FIRM].includes(
        documentStatus
      ));

  const isApprovalButtonVisible =
    isCompositeDemand(documentMetaData.documentType) &&
    (userHasPermission('DemandApproval', userData) || userHasPermission('DemandRequestApproval', userData)) &&
    !(
      (userHasRole(LAW_FIRM_ADMIN_ROLE, userData) || userHasRole(LAW_FIRM_APPROVER_ROLE, userData)) &&
      documentStatus === WAITING_FIRM_APPROVAL
    );

  const isUploadDocumentsVisible =
    isCompositeDemand(documentMetaData.documentType) && userHasPermission('DemandCompose', userData);

  const isDocumentSubmittedOrReceived = documentStatus === 'DocumentSubmitted' || documentStatus === 'DocumentReceived';

  return (
    <Container maxWidth="100" sx={{ padding: '10px' }}>
      <ThemeProvider theme={theme}>
        {noPermissions ? (
          <div style={{ position: 'fixed', zIndex: 10, top: 65, left: 5, right: 15, backgroundColor: '#FFFFFF' }}>
            You are not currently assigned to this demand
          </div>
        ) : null}
        {documentData && userData ? (
          <>
            <div style={{ position: 'fixed', zIndex: 10, top: 60, left: 5, right: 15, backgroundColor: '#FFFFFF' }}>
              <div className="breadcrumbs-container">
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                    <Stack spacing={2} direction={'row'} alignItems="center">
                      <Stack direction={'row'} alignItems="center" spacing={1}>
                        <IconButton onClick={handleBackNavClick}>
                          <ArrowBack />
                        </IconButton>
                        <Typography variant="h5" color="primary">
                          {documentData.sendingFirm.firmCaseNumber}
                        </Typography>
                      </Stack>
                      {isCompositeDemand(documentMetaData.documentType) ||
                        documentStatus === 'DocumentSubmitted' ||
                        documentStatus === 'DocumentReceived' ? (
                        <Tooltip
                          title={
                            documentStatus !== 'DocumentArchived' && (
                              <DemandWorkflowTooltip documentStatus={documentStatus} />
                            )
                          }
                          placement="right-start"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [-6, -16],
                                  },
                                },
                              ],
                            },
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontWeight: 450,
                                bgcolor: 'transparent',
                              },
                            },
                          }}
                        >
                          <div>
                            <StatusChip
                              type='document'
                              documentStatus={documentStatus}
                              showInfoIcon={documentStatus !== 'DocumentArchived'}
                              showEditOffIcon={false}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="This demand cannot be edited" placement="bottom-start">
                          <span>
                            <StatusChip
                              type='document'
                              documentStatus={documentStatus}
                              showInfoIcon={!isDocumentSubmittedOrReceived}
                              showEditOffIcon={!isDocumentSubmittedOrReceived}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction={'row'} spacing={1}>
                      {/* begin overflow menu */}
                      {/* actions menu for md breakpoints and up */}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                        }}
                        endIcon={<MoreHorizOutlined />}
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                      >
                        Actions
                      </Button>

                      {/* actions menu for xs and sm breakpoints */}
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                        }}
                        endIcon={<MoreHorizOutlined />}
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                      >
                        Actions
                      </Button>

                      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <EditMenu
                          user={user}
                          userData={userData}
                          showTooltip={hasNotAllowedStatus(documentMetaData)}
                          documentId={documentId}
                          useDemandNotesEnabled={useDemandNotesEnabled}
                          currentEditNote={documentData?.customerSpecialNotes ?? ''}
                          isEditDocumentsDisabled={isEditDocumentsDisabled}
                          editDocumentsToolTipText={DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT}
                        />
                      </Box>

                      {isApprovalButtonVisible && (
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                          <Tooltip
                            title={
                              noDemandResponseDueDate &&
                              documentStatus === 'AwaitingFirmReview' &&
                              'Add a response due date in edit demand details'
                            }
                            placement="bottom-end"
                          >
                            <Badge
                              badgeContent={'!'}
                              color="warning"
                              invisible={!noDemandResponseDueDate || documentStatus !== 'AwaitingFirmReview'}
                            >
                              <span>
                                <Button
                                  disabled={
                                    !isRequestApprovalEnabled(documentStatus, documentMetaData.documentType, userData)
                                  }
                                  onClick={() => handleSendForApproval()}
                                  variant="contained"
                                  color="secondary"
                                  startIcon={<SendRounded />}
                                >
                                  Send for approval
                                </Button>
                              </span>
                            </Badge>
                          </Tooltip>
                        </Box>
                      )}

                      {/* actions menu for md breakpoints and up */}

                      {userHasPermission('DemandApproval', userData) && documentStatus === 'WaitingFirmApproval' && (
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                          <Tooltip
                            placement="top"
                            disableHoverListener={
                              !(
                                !documentData.recipientCarrier.contactInfo.emailAddress &&
                                !documentData.recipientCarrier.contactInfo.faxNumber
                              )
                            }
                            arrow
                          >
                            <span>
                              <Button
                                disabled={documentStatus != 'WaitingFirmApproval'}
                                onClick={(e) => {
                                  handleApproveDemand();
                                }}
                                variant="contained"
                                color="secondary"
                                startIcon={<SendRounded />}
                              >
                                Approve and send to carrier
                              </Button>
                            </span>
                          </Tooltip>
                        </Box>
                      )}

                      {/* begin overflow menu */}

                      <Menu
                        id="menu-options"
                        keepMounted
                        anchorEl={anchorEl}
                        open={menuopen}
                        disableScrollLock
                        onClose={(e) => {
                          handleMenuClose(e);
                        }}
                      >
                        {/* show primary cta in menu below md breakpoints */}
                        {isApprovalButtonVisible && // Use an array because MUI complains if a fragment is used as a child of Menu
                          [
                            <Box key="send-for-approval" sx={{ display: { xs: 'flex', md: 'none' } }}>
                              <Tooltip
                                title={
                                  noDemandResponseDueDate &&
                                  documentStatus === 'AwaitingFirmReview' &&
                                  'Add a response due date in edit demand details'
                                }
                                placement="bottom-end"
                              >
                                <span style={{ width: '100%' }}>
                                  <Stack direction="row" alignItems={'center'} sx={{ width: '100%' }}>
                                    <MenuItem
                                      disabled={
                                        !isRequestApprovalEnabled(documentStatus, documentMetaData.documentType, userData)
                                      }
                                      onClick={() => handleSendForApproval()}
                                      sx={{ width: noDemandResponseDueDate ? 'auto' : '100%' }}
                                    >
                                      <ListItemIcon>
                                        <SendRounded />
                                      </ListItemIcon>
                                      <ListItemText primary="Send for approval" />
                                    </MenuItem>
                                    <Badge
                                      badgeContent={'!'}
                                      color="warning"
                                      invisible={!noDemandResponseDueDate || documentStatus !== 'AwaitingFirmReview'}
                                    ></Badge>
                                  </Stack>
                                </span>
                              </Tooltip>
                            </Box>,
                            <Divider
                              key="send-for-approval-divider"
                              sx={{ my: 1, display: { xs: 'flex', md: 'none' } }}
                            />,
                          ]}
                        {userHasPermission('DemandApproval', userData) && documentStatus !== 'AwaitingFirmReview' && (
                          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <Tooltip
                              placement="top"
                              disableHoverListener={
                                !(
                                  !documentData.recipientCarrier.contactInfo.emailAddress &&
                                  !documentData.recipientCarrier.contactInfo.faxNumber
                                )
                              }
                              arrow
                            >
                              <span>
                                <MenuItem
                                  disabled={documentStatus != 'WaitingFirmApproval'}
                                  onClick={(e) => {
                                    handleApproveDemand();
                                  }}
                                >
                                  <ListItemIcon>
                                    <SendRounded />
                                  </ListItemIcon>
                                  <ListItemText primary="Approve and send to carrier" />
                                </MenuItem>
                                <Divider sx={{ display: { xs: 'flex', md: 'none' } }} />
                              </span>
                            </Tooltip>
                          </Box>
                        )}

                        {/* show edit actions in actions menu below md breakpoints */}

                        {(userHasPermission('DemandApproval', userData) ||
                          userHasPermission('DemandCompose', userData) ||
                          userHasPermission('DemandEdit', userData)) && (
                            <div>
                              {canComposeDemand(userData, documentMetaData) && (
                                <>
                                  <MenuItem
                                    sx={{ display: { xs: 'flex', md: 'none' } }}
                                    onClick={() => {
                                      setAnchorEl(null);
                                      dispatch(setDemandDetailsEditOpen(true));
                                    }}
                                  >
                                    <ListItemIcon>
                                      <TextSnippetOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit demand details" />
                                  </MenuItem>

                                  <Tooltip
                                    title={isEditDocumentsDisabled ? DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT : ''}
                                    arrow
                                    placement="left"
                                  >
                                    <span>
                                      <MenuItem
                                        sx={{ display: { xs: 'flex', md: 'none' } }}
                                        onClick={() => {
                                          setAnchorEl(null);
                                          dispatch(setEditAllDocumentsOpen(true));
                                        }}
                                        disabled={isEditDocumentsDisabled}
                                      >
                                        <ListItemIcon>
                                          <PictureAsPdfOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Edit documents" />
                                      </MenuItem>
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                              <MenuItem
                                sx={{ display: { xs: 'flex', md: 'none' } }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  dispatch(setEditDocumentNames({ open: true, data: { documentId, user } }));
                                }}
                              >
                                <ListItemIcon>
                                  <PictureAsPdfOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Edit document display names" />
                              </MenuItem>

                              {useDemandNotesEnabled && (
                                <MenuItem
                                  sx={{ display: { xs: 'flex', md: 'none' } }}
                                  onClick={() => {
                                    setAnchorEl(false);
                                    dispatch(
                                      setEditDemandNotes({
                                        open: true,
                                        isOpenFromInventory: false,
                                        data: { documentId, user },
                                      })
                                    );
                                  }}
                                  disabled={!userHasPermission('DemandCompose', userData)}
                                >
                                  <ListItemIcon>
                                    <EditNoteSharp />
                                  </ListItemIcon>
                                  <ListItemText primary="Edit notes" />
                                </MenuItem>
                              )}

                              {(canComposeDemand(userData, documentMetaData) || useDemandNotesEnabled) && (
                                <Divider sx={{ display: { xs: 'flex', md: 'none' } }} />
                              )}

                              {userHasPermission('DemandApproval', userData) && (
                                <>
                                  {documentMetaData.documentStatus === 'WaitingFirmApproval' ? (
                                    <MenuItem
                                      onClick={(e) => {
                                        handleRejectDemand();
                                      }}
                                    >
                                      <ListItemIcon>
                                        <ErrorOutline />
                                      </ListItemIcon>
                                      <ListItemText primary="Request additional review" />
                                    </MenuItem>
                                  ) : (
                                    <Tooltip title="Demand is currently under review.">
                                      <div>
                                        <MenuItem disabled={true}>
                                          <ListItemIcon>
                                            <ErrorOutline />
                                          </ListItemIcon>
                                          <ListItemText primary="Request additional review" />
                                        </MenuItem>
                                      </div>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        {isUploadDocumentsVisible && (
                          <UploadDocumentsMenuItem
                            documentId={documentId}
                            documentStatus={documentStatus}
                            user={user}
                            isDocumentUploading={isDocumentUploading}
                            setIsDocumentUploading={setIsDocumentUploading}
                            handleMenuClose={handleMenuClose}
                            showStatusDialog={showStatusDialog}
                            showToastMessage={showToastMessage}
                            setUploadErrorState={setUploadErrorState}
                            uploadErrorState={uploadErrorState}
                          />
                        )}
                        <MenuItem
                          onClick={(e) => {
                            handlePreviewEmail();
                          }}
                        >
                          <ListItemIcon>
                            <RemoveRedEyeOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Email preview to me" />
                        </MenuItem>

                        {userHasPermission('DemandArchive', userData) && documentStatus != 'DocumentArchived' ? (
                          <div>
                            <MenuItem
                              onClick={(e) => {
                                handleArchiveDemand(e);
                              }}
                            >
                              <ListItemIcon>
                                <ArchiveOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Archive demand" />
                            </MenuItem>
                          </div>
                        ) : null}
                      </Menu>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </div>

            <Stack direction="column" spacing={2} sx={{ marginTop: '130px' }}>
              <CollapseCard expanded={true} title="Demand overview">
                <MedicalsOverview documentData={documentData} adminView={false} />

                <div className="form-hr" />

                <MedicalsOverviewPrecedent documentData={documentData} showCarrierPanel={false} />
              </CollapseCard>

              <MedicalsProviderSummary providerSummary={providerSummary} />
              {medicals?.medicalTreatments?.length > 0 && <MedicalsTreatmentsChart documentData={documentData} />}
              <MedicalsTreatments
                viewType="lawfirm"
                documentId={documentId}
                userData={userData}
                adminView={false}
                medicals={medicals}
                user={user}
              />
            </Stack>
          </>
        ) : (
          <p></p>
        )}

        <Dialog
          open={infoDialogOpen}
          TransitionComponent={Transition}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{infoDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{infoDialogText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleInfoDialogClose();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <FirmRejectDialog
          handler={rejectDemand}
          handleClose={handleRejectDialogClose}
          dialogOpen={rejectDialogOpen}
        ></FirmRejectDialog>

        <SendPreviewDialog
          user={user}
          documentId={documentId}
          handler={sendPreviewComplete}
          handleClose={handlePreviewDialogClose}
          dialogOpen={previewDialogOpen}
        ></SendPreviewDialog>

        <StatusDialog
          closeDisabled={statusCloseDisabled}
          handleClose={handleStatusDialogClose}
          dialogOpen={statusDialogOpen}
          dialogText={statusDialogText}
        ></StatusDialog>

        {attorneyDialogOpen && (
          <AttorneySendDialog
            viewType="precedent"
            user={user}
            userData={userData}
            documentId={documentId}
            deliveryEmail={deliveryAttorneyEmail}
            handler={handleSendComplete}
            handleClose={handleAttorneyDialogClose}
            dialogOpen={attorneyDialogOpen}
          ></AttorneySendDialog>
        )}

        {attorneyConfirmationDialogOpen && (
          <ConfirmationDialog
            dialogOpen={attorneyConfirmationDialogOpen}
            dialogText={
              <Stack spacing={2}>
                <Typography>The demand is currently under review.</Typography>
                <Typography>Are you sure you want to send for approval?</Typography>
              </Stack>
            }
            cancelButtonText={'Cancel'}
            okButtonText={'Yes, send for approval'}
            handleCancel={handleAttorneyConfirmationDialogClose}
            handleOk={handleAttorneyConfirmationDialogOk}
          />
        )}

        {archiveDialogOpen && (
          <ArchiveDialog
            firmCaseNumber={documentData.sendingFirm.firmCaseNumber}
            handler={archiveDemand}
            handleClose={handleArchiveDialogClose}
            dialogOpen={archiveDialogOpen}
          ></ArchiveDialog>
        )}

        {documentData && (
          <FirmApproveDialog
            user={user}
            handler={sendFirmApprovalRequestComplete}
            documentId={documentId}
            deliveryEmail={deliveryEmail}
            deliveryFax={deliveryFax}
            carrierEmailAddress={carrierEmailAddress}
            carrierFaxNumber={carrierFaxNumber}
            viewType="lawfirm"
            handleClose={handleApproveDialogClose}
            dialogOpen={approveDialogOpen}
            docData={documentData}
          ></FirmApproveDialog>
        )}

        <Snackbar
          open={toastOpen}
          autoHideDuration={toastTimeout}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Paper elevation={8}>
            <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
              {toastMessage}
            </Alert>
          </Paper>
        </Snackbar>
      </ThemeProvider>
    </Container>
  );
};
