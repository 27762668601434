import { EditOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Card, Collapse, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import Title from './Title';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CollapseCard = ({ title, adminView, children, editHandler, expanded }) => {
    const [expandedState, setExpandedState] = useState(expanded);
    const handleExpandClick = () => {
        setExpandedState(!expandedState);
    }

    return (
        <Card>
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                <Title>{title}</Title>
                <Stack direction="row" spacing={1}>
                    {adminView ? <IconButton onClick={editHandler}>
                        <EditOutlined />
                    </IconButton> : ""}
                    <ExpandMore expand={expandedState}
                        onClick={handleExpandClick}>
                        <ExpandMoreOutlined />
                    </ExpandMore>
                </Stack>
            </Stack>
            <Collapse in={expandedState} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Card>
    )
}

export default CollapseCard