import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { UploadFileOutlined } from '@mui/icons-material';
import VisuallyHiddenInput from '../CommonComponents/VisuallyHiddenInput';
import { getDocumentFilesApi, regenerateDocumentStatusApi } from '../api';
import { uploadFilesAndPostToDemand } from '../DemandComponents/utilities';
import { useDispatch } from 'react-redux';
import {
  setDocumentRegnerationLoading,
  setDocumentDataLoading,
  setDocumentStatus,
} from '../redux/slices/documentSlice';
import { setDocumentFiles } from '../redux/slices/demandDomSlice';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { DOCUMENT_UPLOAD_FILE_TYPES } from '../common-document';
import {
  DOCUMENT_UPLOADED,
  AWAITING_MEDICAL_EXTRACTION,
  WAITING_FIRM_APPROVAL,
  AWAITING_FIRM_REVIEW,
  AWAITNG_PRECEDENT_SIGNOFF,
} from '../common-demand';

const UploadDocumentsMenuItem = ({
  documentId,
  documentStatus,
  user,
  isDocumentUploading,
  setIsDocumentUploading,
  setUploadErrorState,
  uploadErrorState,
  handleMenuClose,
  showStatusDialog,
  showToastMessage,
}) => {
  const dispatch = useDispatch();

  const checkStatus = async (documentId) => {
    const response = await regenerateDocumentStatusApi(documentId, user);
    const status = response['status'];
    if (status !== 'InProcess') {
      dispatch(setDocumentDataLoading(documentId));
      dispatch(fetchDocumentData({ documentId, user })).then((response) => {
        dispatch(setDocumentStatus(response?.payload?.metadata?.documentStatus));
        dispatch(setDocumentRegnerationLoading(''));
        getDocumentFilesApi(documentId, user)
          .then((response) => response.json())
          .then((data) => {
            dispatch(setDocumentFiles(data));
          })
          .catch((error) => console.log('Error with fetching document files: ', error));
      });

      return;
    }

    setTimeout(() => {
      checkStatus(documentId);
    }, 3000);
  };

  const handleFilesUpload = async (e) => {
    handleMenuClose(e);
    if (!e.target.files) {
      return;
    }
    const filesToUpload = [...e.target.files];
    for (const file of filesToUpload) {
      // 150MB
      if (file.size > 157286400) {
        showStatusDialog('Error: Files cannot be larger than 150MB', false);
        return;
      }
    }
    showToastMessage('Uploading file(s)...', 'info');
    setIsDocumentUploading(true);
    dispatch(setDocumentRegnerationLoading(documentId));

    uploadFilesAndPostToDemand(
      filesToUpload,
      documentId,
      user,
      setUploadErrorState,
      uploadErrorState,
      checkStatus
    ).then((uploadData) =>
      getDocumentFilesApi(documentId, user)
        .then((response) => response.json())
        .then((data) => {
          if (uploadData.failed.length === 0) {
            showToastMessage('File(s) uploaded successfully');
          } else if (uploadData.successful.length === 0) {
            showToastMessage('File upload failed!', 'error', null);
          } else {
            showToastMessage(
                'File upload partially successful! ' +
                'The following file(s) failed upload: '
                + uploadData.failed.map((file)=>file.name).join(),
                'warning', null);
          }
          dispatch(setDocumentFiles(data));
          setIsDocumentUploading(false);
        })
        .catch((error) => {
          console.log('Error with fetching document files: ', error);
        })
    );
  };

  const isUploadDocumentsDisabled =
    isDocumentUploading ||
    ![
      DOCUMENT_UPLOADED,
      AWAITING_MEDICAL_EXTRACTION,
      AWAITING_FIRM_REVIEW,
      WAITING_FIRM_APPROVAL,
      AWAITNG_PRECEDENT_SIGNOFF,
    ].includes(documentStatus);

  return (
    <MenuItem component="label" disabled={isUploadDocumentsDisabled}>
      <ListItemIcon>
        <UploadFileOutlined />
      </ListItemIcon>
      <ListItemText primary="Upload documents" />
      <VisuallyHiddenInput
        onChange={(e) => {
          handleFilesUpload(e);
        }}
        type="file"
        multiple
        accept={DOCUMENT_UPLOAD_FILE_TYPES}
      />
    </MenuItem>
  );
};

export default UploadDocumentsMenuItem;
