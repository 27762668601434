import { postDocumentFilesApi, pushDataToS3, getUploadUrl } from '../api';

export const uploadFilesAndPostToDemand = async (uploadFileList, documentId, user, setUploadErrorState, uploadErrorState, checkStatus) => {

    const uploadPromises = uploadFileList.map(async (file, idx) => {
        const uploadFields = await getUploadUrl(file.name, documentId, user)
            .then((response) => response)
            .catch((error) => setUploadErrorState({ ...uploadErrorState, [file.name]: `Error getting upload url: ${error}` }));

        const success = await pushDataToS3(uploadFields, file)
            .then((response) => response)
            .catch((error) => setUploadErrorState({ ...uploadErrorState, [file.name]: `Error uploading file: ${error}` }));
        return {
            success: success,
            file: file
        }
    })

    const successfulFiles = []
    const failedFiles = []

    await Promise.all(uploadPromises)
        .then(async (promiseResults) => {

            for (const promiseResult of promiseResults) {
                promiseResult.success ? successfulFiles.push(promiseResult.file) : failedFiles.push(promiseResult.file);
            }

            const uploaderListMappedtoFileBody = successfulFiles.map((file) => {
                return {
                    fileName: file.name,
                    detectedType: file.type,
                }
            })

            if (successfulFiles.length > 0) {
                return await postDocumentFilesApi(documentId, {files: uploaderListMappedtoFileBody}, user)
                    .then((response) => response)
                    .catch(error => console.log('error from postDocumentFilesApi', error))
            }
        })
        .then(() => {
            checkStatus(documentId)
        })
        .catch((error) => console.log('Error uploading files', error));
    return {
        successful: successfulFiles,
        failed: failedFiles
    }
}