import React from 'react';
import { useRichTextEditorContext } from 'mui-tiptap';
import { LoadingButton } from '@mui/lab';
import { AutoAwesome } from '@mui/icons-material';
import { deepPurple } from '@mui/material/colors';

const MenuButtonGenerate = ({ fieldName, documentId, user }) => {
  const editor = useRichTextEditorContext();

  return (
    <LoadingButton
      sx={{
        backgroundColor: deepPurple[50],
        color: deepPurple[900],
        '&:hover': {
          backgroundColor: deepPurple[100]
        }
      }}
      size="small"
      startIcon={<AutoAwesome fontSize="small" sx={{ color: deepPurple[900] }} />}
      loading={editor.storage.aiGenerate.isLoading}
      onClick={() => editor.commands.generateNarrative(fieldName, documentId, user)}
    >
      Compose
    </LoadingButton>
  );
};

export default MenuButtonGenerate;
