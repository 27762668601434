import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, CircularProgress, Typography, Box, TextField } from '@mui/material';
import { getIcdCodesAutocomplete } from '../../api';
import { debounce } from 'lodash';

const ICDCodeAutocomplete = ({ value, user, onChange }) => {
  const [icdCodeAutoCompleteResults, setIcdCodeAutoCompleteResults] = useState([]);
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);

  const icdCode = value[0];

  useEffect(() => {
    setAutoCompleteLoading(true);
    getIcdCodesAutocomplete(user, icdCode).then((response) => {
      if (response.length > 0) {
        setIcdCodeAutoCompleteResults(response);
        setAutoCompleteLoading(false);
      } else {
        getIcdCodesAutocomplete(user, '')
          .then((response) => {
            setIcdCodeAutoCompleteResults(response);
            setAutoCompleteLoading(false);
          })
          .catch((error) => console.log('error', error));
      }
    });
  }, [icdCode, user]);

  const handleAutoCompleteInputChange = useCallback(
    debounce((event, value) => {
      setAutoCompleteLoading(true);
      getIcdCodesAutocomplete(user, value ? value : '').then((response) => {
        if (response.length > 0 || value === '') {
          setIcdCodeAutoCompleteResults(response);
          setAutoCompleteLoading(false);
        } else {
          getIcdCodesAutocomplete(user, '')
            .then((response) => {
              setIcdCodeAutoCompleteResults(response);
              setAutoCompleteLoading(false);
            })
            .catch((error) => console.log('error', error));
        }
      });
    }, 300),
    []
  );

  return (
    <Autocomplete
      id="icd-code-autocomplete"
      loading={autoCompleteLoading}
      value={value}
      isOptionEqualToValue={(option, selectedValue) => option[0] === selectedValue[0]}
      onInputChange={(event, value) => {
        event?.type === 'change' && handleAutoCompleteInputChange(event, value);
      }}
      onChange={(e, value) => onChange(value)}
      options={icdCodeAutoCompleteResults}
      getOptionLabel={(option) => option[0]}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <ul style={{ listStyleType: 'none', marginLeft: '-20px' }}>
            <li>
              <Typography variant="menu-item">{option[0]}</Typography>
            </li>
            <li>
              <Typography variant="body2">{option[1]}</Typography>
            </li>
          </ul>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ICD Code"
          helperText={value[1]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {autoCompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ICDCodeAutocomplete;
