import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLatestTemplateVersionById, getAvailableTemplatesForCustomer, postTemplate } from "../../api";
import { setToast } from '../slices/globalToastSlice';
import { setCustomerData, resetFormFieldsAndMetadata } from '../slices/adminEditTemplateSlice';

export const saveTemplate = createAsyncThunk(
    'AdminEditTemplate/saveTemplate',
    async ({ templateData, user, customerId }, { dispatch, rejectWithValue, getState }) => {
        return postTemplate(templateData, user, customerId)
            .then(async (response) => {
                const jsonResponse = await response.json();
                if (response.status === 200) {
                    const { AdminEditTemplate } = getState();
                    const templateId = templateData.templateId;
                    dispatch(fetchAvailableTemplatesForCustomer({ user, customerId }));
                    if (templateData?.setAsDefaultTemplate) {
                        dispatch(setCustomerData({ ...AdminEditTemplate.customerData, defaultTemplateId: templateId }));
                    }
                    dispatch(setToast({ isOpen: true, severity: 'success', message: templateData.live ? 'Template published successfully' : 'Template saved successfully' }));
                    dispatch(resetFormFieldsAndMetadata());
                } else {
                    const errorMessage = 'Error saving template' + (jsonResponse?.message ? ": " + jsonResponse?.message : '');
                    dispatch(setToast({ isOpen: true, severity: 'error', message: errorMessage }));
                    return rejectWithValue(errorMessage);
                }
            })
            .catch((error) => {
                console.error('Error saving template:', error);
                return rejectWithValue('Error saving template');
            });
    });

export const fetchTemplateDataById = createAsyncThunk(
    'AdminEditTemplate/fetchTemplateDataById',
    async ({ templateId, user }, { dispatch, rejectWithValue }) =>
        getLatestTemplateVersionById(templateId, user)
            .then((response) => response?.template?.templateId ? response.template : rejectWithValue('Error fetching template data'))
            .catch((error) => console.error('Error fetching template data:', error))
);

export const fetchAvailableTemplatesForCustomer = createAsyncThunk(
    'AdminEditTemplate/getAvailableTemplatesForCustomer',
    async ({ user, customerId }, { dispatch, rejectWithValue }) => {
        return getAvailableTemplatesForCustomer(user, customerId)
            .then((response) => {
                if (response?.templates) {
                    return response.templates;
                } else {
                    const errorMessage = 'Error fetching available templates';
                    dispatch(setToast({ isOpen: true, severity: 'error', message: errorMessage }));
                    return rejectWithValue(errorMessage);
                }
            })
    })






