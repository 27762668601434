import React from 'react';
import { Dialog, DialogContent, CircularProgress, Typography, Grid } from '@mui/material';

const LoadingDialogue = ({ isLoading, text }) => {
    return (
        <>
            <Dialog open={isLoading} fullWidth>
                <DialogContent>
                    <Grid container alignItems="center" justifyContent="center" spacing={2} direction="column">
                        <Grid item>
                            <CircularProgress size={80} /> {/* Adjust the size as needed */}
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" align="center">{text}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default LoadingDialogue;