import React from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import {
  RichTextEditor,
  MenuControlsContainer,
  MenuButtonAlignCenter,
  MenuButtonAlignJustify,
  MenuButtonAlignLeft,
  MenuButtonAlignRight,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonBlockquote,
  MenuButtonRedo,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonIndent,
  MenuButtonUnindent,
  MenuDivider,
  TableImproved,
  MenuButtonAddTable,
  TableMenuControls,
} from 'mui-tiptap';
import { Box } from '@mui/material';
import './ConfiguredRichTextEditor.css';
import { Indent, getIndent, getOutdent } from './CustomExtensions/indent.ts';
import AiGenerate from './CustomExtensions/aiGenerate.js';
import MenuButtonGenerate from './CustomButtons/MenuButtonGenerate.jsx';

const extensions = [
  StarterKit,
  Underline,
  TextAlign.configure({ types: ['paragraph'] }),
  Indent,
  AiGenerate,
  TextStyle,
  TableImproved.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
];

const EditorMenuControls = ({ editor, showGenerateButton, fieldName, documentId, user }) => (
  <MenuControlsContainer>
    {showGenerateButton && (
      <>
        <MenuButtonGenerate fieldName={fieldName} documentId={documentId} user={user} />
        <MenuDivider />
      </>
    )}
    <MenuButtonBold />
    <MenuButtonItalic />
    <MenuButtonUnderline />
    <MenuDivider />
    <MenuButtonBlockquote />
    <MenuButtonBulletedList />
    <MenuButtonOrderedList />
    <MenuButtonUnindent
      tooltipLabel="Decrease indent"
      disabled={!editor?.isEditable}
      onClick={() => getOutdent()({ editor })}
    />
    <MenuButtonIndent
      tooltipLabel="Increase indent"
      disabled={!editor?.isEditable}
      onClick={() => getIndent()({ editor })}
    />
    <MenuDivider />
    <MenuButtonAlignLeft />
    <MenuButtonAlignCenter />
    <MenuButtonAlignRight />
    <MenuButtonAlignJustify />
    <MenuDivider />
    <MenuButtonUndo />
    <MenuButtonRedo />
    <MenuDivider />
    <MenuButtonAddTable />
    {editor.isActive('table') && <TableMenuControls />}
  </MenuControlsContainer>
);

const ConfiguredRichTextEditor = ({ content, onChange, showGenerateButton = false, fieldName, documentId, user }) => (
  <Box
    sx={{
      '.ProseMirror': {
        minHeight: '100px',
        maxHeight: '600px',
        overflow: 'auto',
      },
    }}
  >
    <RichTextEditor
      extensions={extensions}
      content={content}
      onUpdate={onChange}
      parseOptions={{ preserveWhitespace: 'full' }}
      renderControls={(editor) => (
        <EditorMenuControls
          editor={editor}
          showGenerateButton={showGenerateButton}
          fieldName={fieldName}
          documentId={documentId}
          user={user}
        />
      )}
    />
  </Box>
);

export default ConfiguredRichTextEditor;
