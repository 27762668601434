import React, { useState, useEffect } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { AccountBox, LogoutRounded, AdminPanelSettings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import WbCloudyTwoToneIcon from '@mui/icons-material/WbCloudyTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import awsExports from './aws-exports';
import { Avatar, Button, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { isLawFirmUser, isPrecedentUser, PRECEDENT_ADMIN_ROLE } from './common-roles';
import { userHasRole, userHasPermission } from './common';
import { getUserApi } from './api';
import { theme } from './Theme'
import { useSelector } from "react-redux";
import { persistor } from './App';

const pages = ['Inventory', "Contact log", "User management"];
const settings = [{ name: 'Profile', icon: <AccountBox color="primaryActions" /> }, { name: 'Logout', icon: <LogoutRounded color="primaryActions" /> }];
const styles = { color: '#ffffff', display: 'block', textTransform: 'none', '&:hover': { backgroundColor: '#ffffff25' } };

const HeaderAppBar = (props) => {

  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user
  ]);

  const location = useLocation();
  const navbarState = useSelector((state) => state.Navbar);
  const { isDisplayed } = navbarState;

  const imageUrl = "/precedent-logo-white.png";
  const homeUrl = "/";
  const showStage = (awsExports.ENV_STAGE.toUpperCase() !== "PROD") && awsExports.ENV_STAGE

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [pages, setPages] = useState([]);
  const [userData, setUserData] = useState();
  const [authenticated, setAuthenticated] = useState();

  // API
  const getUser = async () => {
    const response = await getUserApi(user);
    setUserData(response);
  };

  // UI Handlers
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignout = async () => {
    handleCloseUserMenu();
    if (authenticated) {
      setAuthenticated(false);
      signOut();
    }
  };

  useEffect(() => {
    const localPages = [];
    if (isLawFirmUser(userData)) {
      localPages.push({ label: 'Inventory', route: '/inventory' });
    }
    else if (isPrecedentUser(userData)) {
      localPages.push({ label: 'Inventory', route: '/inventory' });
      localPages.push({ label: 'Contact log', route: '/contact-log-inventory' });

      if (userHasRole(PRECEDENT_ADMIN_ROLE, userData)) {
        localPages.push({ label: 'Admin', route: '/customer-management' });
      }
    }
    setPages(localPages);
  }, [userData]);

  useEffect(() => {
    if (route === 'authenticated') {
      getUser();
      setAuthenticated(true);
    }
    else {
      setPages([]);
    }
  }, [route]);

  useEffect(() => {
    if (location.pathname === "/login") {
      persistor.purge();
    }
  }, [location.pathname]);

  return (
    <>
      {isDisplayed ? (
        <ThemeProvider theme={theme}>
          <AppBar sx={{ position: 'fixed', zIndex: 12, height: "64px" }}>
            <Container maxWidth="">
              <Toolbar disableGutters sx={{ height: "64px" }}>
                {/* logo and stage for large view */}
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Stack direction="row" spacing={2} display="flex" alignItems="center">
                    <Link to={homeUrl}>
                      <Box display="flex" alignItems={"center"}>
                        <img src={imageUrl} height="35" alt='Precedent Exchange' />
                      </Box>
                    </Link>
                    {showStage ? (
                      <Stack direction="row" sx={{ border: "2px solid #ED6C02", borderRadius: "4px" }} spacing={1} padding={.5} display="flex" alignItems="center">
                        <WbCloudyTwoToneIcon color="warning" />
                        <Typography variant="h6" sx={{ color: "#ed6c02" }} className="env-stage">{awsExports.ENV_STAGE}</Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Box>
                {/* hamburger menu for small view */}
                <Box sx={{ flexGrow: .33, display: { xs: 'flex', md: 'none' } }}>
                  {authenticated ? (
                    <>
                      <IconButton
                        size="large"
                        aria-label="menu-for-site-nav"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        keepMounted
                        disableScrollLock
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                          display: { xs: 'block', md: 'none' },
                        }}
                      >
                        {pages.map((page) => (
                          <MenuItem key={page.route} onClick={handleCloseNavMenu}>
                            <Button key={page.route} to={page.route} component={Link} onClick={handleCloseNavMenu} sx={styles} variant="text">
                              <Typography textAlign="center" color="primary">{page.label}</Typography>
                            </Button>
                          </MenuItem>
                        ))}
                      </Menu>
                    </>) : null}
                </Box>
                {/* center aligned logo and stage for small view */}
                <Box justifyContent="center" sx={{ flexGrow: .33, display: { xs: 'flex', md: 'none' } }}>
                  <Stack direction="row" spacing={2} display="flex" alignItems="center" >
                    <Link to={homeUrl}>
                      <Box display="flex" alignItems={"center"}>
                        <img src={imageUrl} height="35" alt='Precedent Exchange' />
                      </Box>
                    </Link>
                    {showStage ? (
                      <Stack direction="row" sx={{ border: "2px solid #ED6C02", borderRadius: "4px" }} spacing={1} padding={.5} display="flex" alignItems="center">
                        <WbCloudyTwoToneIcon color="warning" />
                        <Typography variant="h6" style={{ color: "#ed6c02" }} > {awsExports.ENV_STAGE}</Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Box>
                {/* nav links for large views */}
                <Stack direction="row" spacing={1} sx={{ ml: 4, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pages.map((page) => (
                    <Box key={page.route} className={page.route === location.pathname ? 'App-header-tab App-header-tab-selected' : 'App-header-tab'} onClick={handleCloseNavMenu} display="flex" alignItems={"center"}>
                      <Button key={page.route} to={page.route} component={Link} onClick={handleCloseNavMenu} sx={styles} variant="text">
                        <Typography variant="body1">{page.label}</Typography>
                      </Button>
                    </Box>
                  ))}
                </Stack>
                {/* avatar for signout */}
                <Box sx={{ flexGrow: .33, display: "flex", justifyContent: "flex-end" }}>
                  {authenticated && userData ? (
                    <Stack direction="row" spacing={2} display="flex" alignItems={"center"}>
                      {showStage ? (
                        <Stack direction="row" spacing={1}>
                          <AdminPanelSettings />
                          <Typography variant="body1">
                            {userData.roles[0]}
                          </Typography>
                        </Stack>) : null}
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar >{userData.firstName[0]}{userData.lastName[0]}</Avatar>
                      </IconButton>
                    </Stack>) : null}
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    disableScrollLock
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >

                    <MenuItem key="signOut" onClick={handleSignout}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <Typography textAlign="center">Sign out</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </ThemeProvider>
      ) : null}
    </>
  );
}

export default HeaderAppBar;