import React, { useState } from 'react'
import { Tabs, Tab, Tooltip, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { set_component_A_width, setIsClosed } from '../redux/slices/sideBySideWithVerticalNavSlice';
import { grey } from '@mui/material/colors';

const GeneralOptionsPanel = ({ tabsAndComponentMap }) => {
    const dispatch = useDispatch();
    const { isClosed } = useSelector((state) => state.SideBySideWithVerticalNav);
    const [tabValue, setTabValue] = useState(0);

    const windowWidth = window.innerWidth

    const handleTabClicked = (e, index) => {
        tabValue !== index && setTabValue(index);
        isClosed && dispatch(setIsClosed(false));
        isClosed && dispatch(set_component_A_width(windowWidth / 2));
    }

    return (
        <>
            <Stack
                direction={"row"}
                sx={{
                    width: "100%",
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                <Stack
                    sx={{
                        borderColor: 'divider',
                        borderRight: `1px solid ${grey[300]}`,
                        borderLeft: !isClosed ? 0 : `1px solid ${grey[300]}`,
                        height: 'calc(100vh - 82px)',
                    }}
                >
                    <Tabs
                        orientation='vertical'
                        value={tabValue}
                        TabIndicatorProps={{
                            sx: {
                                display: isClosed ? 'none' : 'block'
                            }
                        }}
                        sx={{
                            '& .Mui-selected': {
                                background: !isClosed && grey[100]
                            },
                        }}
                    >
                        {tabsAndComponentMap.map((tab, index) => (
                            <Tooltip
                                key={index}
                                title={tab.title}
                                placement='left'
                                arrow
                            >
                                <Tab
                                    icon={tab.icon}
                                    aria-label={tab.title}
                                    tabIndex={index}
                                    sx={{
                                        '&:hover': {
                                            background: grey[50],
                                        },
                                        '&:hover.Mui-selected': {
                                            background: grey[100],
                                        },
                                        minWidth: '48px',
                                        width: '48px'
                                    }}
                                    onClick={e => handleTabClicked(e, index)}
                                />
                            </Tooltip>
                        ))}
                    </Tabs>
                </Stack>

                <Stack sx={{ height: '90vh', flex: 1 }}>
                    {tabsAndComponentMap[tabValue].component}
                </Stack>
            </Stack>
        </>
    );
}

export default GeneralOptionsPanel;


