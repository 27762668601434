import { createSlice } from '@reduxjs/toolkit';

const initialIcdCodesSlice = {
  aggregatedIcdCodeData: {},
  selectedRowsAndIcdCodeIds: {},
};

export const IcdCodesSlice = createSlice({
  name: 'IcdCodes',
  initialState: initialIcdCodesSlice,
  reducers: {
    setAggregatedIcdCodeData: (state, action) => {
      state.aggregatedIcdCodeData = action.payload;
    },
    setSelectedRowsAndIcdCodeIds: (state, action) => {
      state.selectedRowsAndIcdCodeIds = action.payload;
    },
  },
});

export const { setAggregatedIcdCodeData, setSelectedRowsAndIcdCodeIds } = IcdCodesSlice.actions;

export default IcdCodesSlice.reducer;
