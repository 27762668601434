import React from 'react';
import Chip from '@mui/material/Chip';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

const WarningChip = ({ label }) => {
  return (
    <Chip
      color="error"
      variant="outlined"
      icon={<ErrorOutline />}
      label={label}
      size='small'
      alignSelf={'flex-start'}
    />
  );
}

export default WarningChip;
