import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMedicals, postMedicals } from '../../api';
import { checkRegeneratePdfStatus } from './documentData';
import { setDocumentRegnerationLoading } from '../slices/documentSlice';

export const fetchMedicalsData = createAsyncThunk('Medicals/fetchMedicalsData', async ({ documentId, user }) => {
    return getMedicals(documentId, user)
        .then((response) => response.json())
        .catch(err => err);
});

export const postMedicalsData = createAsyncThunk('Medicals/postMedicalsData', async ({ documentId, medicals, user }, { dispatch }) => {
    dispatch(setDocumentRegnerationLoading(documentId));
    return postMedicals(documentId, medicals, user)
        .then((response) => {
            dispatch(checkRegeneratePdfStatus(documentId, user));
            dispatch(fetchMedicalsData({ documentId, user }));
            return response.json()
        })
        .catch(err => err);
});