import { createSlice } from '@reduxjs/toolkit';
import { fetchCustomerData } from '../thunks/customerData';

const originalCustomerState = {
  customerData: {},
};

const customerSlice = createSlice({
  name: 'Customer',
  initialState: originalCustomerState,
  reducers: {
    setCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerData.fulfilled, (state, action) => {
      state.customerData = action.payload;
    });
  },
});

export const { setCustomerData } = customerSlice.actions;

export default customerSlice.reducer;
