export const PRECEDENT_ADMIN_ROLE = "PrecedentAdmin";
export const PRECEDENT_EDITOR_ROLE = "PrecedentEditor";
export const PRECEDENT_USER_ROLE = "PrecedentUser";
export const PRECEDENT_CALLER_ROLE = "PrecedentCaller";

export const PRECEDENT_ROLES = [PRECEDENT_ADMIN_ROLE, PRECEDENT_EDITOR_ROLE, PRECEDENT_USER_ROLE, PRECEDENT_CALLER_ROLE];

export const LAW_FIRM_ADMIN_ROLE = "LawFirmAdmin";
export const LAW_FIRM_APPROVER_ROLE = "LawFirmApprover";
export const LAW_FIRM_EDITOR_ROLE = "LawFirmEditor";
export const LAW_FIRM_USER_ROLE = "LawFirmUser";
export const LAW_FIRM_COMPOSER_ROLE = "LawFirmComposer";

export const LAW_FIRM_ROLES = [LAW_FIRM_ADMIN_ROLE, LAW_FIRM_APPROVER_ROLE, LAW_FIRM_EDITOR_ROLE, LAW_FIRM_USER_ROLE, LAW_FIRM_COMPOSER_ROLE];

export const isLawFirmUser = (user) => {
    return user && (user["roles"].some(r=> LAW_FIRM_ROLES.indexOf(r) >= 0));
};

export const isLawFirmAdmin = (user) => user && (user["roles"].some(r=> r === LAW_FIRM_ADMIN_ROLE));

export const isPrecedentUser = (user) => {
    return user && (user["roles"].some(r=> PRECEDENT_ROLES.indexOf(r) >= 0));
};

export const isPrecedentAdmin = (user) => {
        return user && (user["roles"].some(r=> r === PRECEDENT_ADMIN_ROLE));
};