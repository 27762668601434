import React, { useEffect, useState } from 'react';
import PdfEditor from './PdfEditor';
import PdfRedactorDialog from './PdfRedactor/PdfRedactorDialog.jsx';
import { Dialog, AppBar, IconButton, Typography, Stack, Box, DialogContent, Button, DialogTitle, Container, Snackbar, Alert, Tooltip, Paper } from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Title from '../CommonComponents/Title';
import { Transition } from '../Transition';
import { theme } from '../Theme';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAllDocumentsOpen, setDocumentFiles, setEditOneDocumentFileName, setDocumentEdit3Url, setRedactDocumentOpen, setEditDocumentPdfOpen, setFileDisabled, setFileSaveStatusMap } from '../redux/slices/demandDomSlice';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { setFilesSequenceApi, deleteFile, getDocumentFilesApi, getFileUrl  } from '../api';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { fetchMedicalsData } from '../redux/thunks/medicalsData';
import { regenerateDocumentStatusApi } from '../api';
import { setDocumentRegnerationLoading, setShouldDeleteFile, setDocumentDataLoading } from '../redux/slices/documentSlice';
import { setConfirmDeleteFile, setDocumentEditingConfirmation } from '../redux/slices/globalDialogueSlice';
import LoadingStatusChip from '../CommonComponents/Chips/LoadingStatusChip.jsx';
import { DiscardWork } from '../dialogs/DialogText.js';

const demandOrCoverLetter = (detectedType) => detectedType === "cover letter" || detectedType === "demand letter";

const EditDocuments = ({ user, documentId }) => {
    const dispatch = useDispatch();

    const [isDirty, setIsDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [localDocumentFiles, setLocalDocumentFiles] = useState([]);
    const { editAllDocumentsOpen, documentFiles, editOneDocumentFileName, documentEditS3Url, editDocumentPdfOpen, redactDocumentOpen, fileDisabled, fileSaveStatusMap } = useSelector(state => state.DemandDom);
    const { documentRegnerationLoading, docummentDataLoading } = useSelector(state => state.Document);
    const { shouldDeleteFile } = useSelector(state => state.Document);
    const [file, setFile] = useState(null);
    const [toastMessage, setToastMessage] = useState('');
    const [toastSeverity, setToastSeverity] = useState('');
    const [toastOpen, setToastOpen] = useState(false);

    useEffect(() => {
        setLocalDocumentFiles([...documentFiles]);
    }, [editAllDocumentsOpen, documentFiles])

    useEffect(() => {
        setIsDirty(false);
        dispatch(setFileSaveStatusMap({}));
    }, [editAllDocumentsOpen])

    const getDocumentFiles = () =>
        getDocumentFilesApi(documentId, user)
            .then(response => response.json())
            .then(data => {
                const dataCopy = [...data];
                dispatch(setDocumentFiles(data));
                setLocalDocumentFiles(dataCopy);
            })
            .catch(error => {
                console.log(error);
                setToastMessage("Error fetching files");
                setToastSeverity('error');
                setToastOpen(true);
            });

    useEffect(() => {
        if (shouldDeleteFile) {
            dispatch(setFileDisabled(file.fileEntityId));
            setToastMessage(`Deleting ${file?.fileEntityData?.fileName}...`);
            setToastSeverity('info');
            setToastOpen(true);
            dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Deleting' }));
            deleteFile(file.fileEntityId, user)
                .then(() => {
                    const newLocalFileList = [...documentFiles].filter((localFile) => localFile.fileEntityId !== file.fileEntityId);
                    dispatch(setDocumentFiles(newLocalFileList));
                    getDocumentFiles();
                    dispatch(setDocumentRegnerationLoading(documentId));
                    checkStatus(documentId);
                    dispatch(setShouldDeleteFile(false));
                    setToastMessage(`${file?.fileEntityData?.fileName} deleted successfully`);
                    setToastSeverity('success');
                    setToastOpen(true);
                    dispatch(setFileDisabled(''));
                    const newFileStatusMap = { ...fileSaveStatusMap };
                    delete newFileStatusMap[file.fileEntityId];
                    dispatch(setFileSaveStatusMap(newFileStatusMap));
                })
                .catch((error) => {
                    console.log(error);
                    setToastMessage(`Error deleting ${file?.fileEntityData?.fileName}`);
                    setToastSeverity('error');
                    setToastOpen(true);
                    dispatch(setFileDisabled(''));
                });
        }
    }, [shouldDeleteFile])

    const handleEditFileClick = async (file, action) => {
        setFile(file);

        const response = await getFileUrl(file.fileEntityId, user)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
                setToastMessage("Error fetching file");
                setToastSeverity('error');
                setToastOpen(true);
            });

        const s3Url = response?.s3Url || null;
        s3Url && dispatch(setDocumentEdit3Url(s3Url));
        s3Url && dispatch(setEditOneDocumentFileName(file.fileEntityData.fileName));
        action === 'redact' && dispatch(setRedactDocumentOpen(true));
        action === 'edit' && dispatch(setEditDocumentPdfOpen(true));
    }

    const columns = [
        {
            field: "name",
            headerName: "File",
            width: 400,
            renderCell: (params) => {
                const showSaveStatus = fileSaveStatusMap[params.row.fileEntityId] !== undefined;
                return (
                    <Stack
                        mt={1}
                        mb={1}
                        sx={{ width: "100%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                    >
                        <Stack >
                            <Typography variant="tableP1" color={demandOrCoverLetter(params.row.detectedType) ? "text.secondary" : "primary"}>
                                {params.row.name}
                            </Typography>
                        </Stack>
                        {showSaveStatus && (
                            <LoadingStatusChip isLoading={(fileDisabled === params.row.fileEntityId) && !redactDocumentOpen && !editDocumentPdfOpen} label={fileSaveStatusMap[params.row.fileEntityId]} />
                        )}
                    </Stack>
                );
            },
        },
        {
            field: "pages",
            headerName: "Pages",
            width: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Typography variant="tableP1">
                        {params.row?.fileEntityData?.totalPages || ''}
                    </Typography>
                );
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            headerAlign: 'center',
            renderCell: (params) => {
                const { fileEntityId, name: fileName } = params.row;
                const fileLoading = fileDisabled === fileEntityId;
                const lessThan2Pages = params.row?.fileEntityData?.totalPages < 2;
                return (
                    <Box>
                        {demandOrCoverLetter(params.row.detectedType) ? "" :
                            <Stack direction="row" spacing={1}>
                                <Tooltip title={"Edit"} arrow>
                                    <div>
                                        <IconButton
                                            aria-label="Edit"
                                            disabled={fileLoading}
                                            onClick={() => handleEditFileClick(params.row, 'edit')}
                                        >
                                            <EditOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip title='Redact' arrow>
                                    <div>
                                        <IconButton
                                            aria-label="Redact"
                                            disabled={fileLoading}
                                            onClick={() => handleEditFileClick(params.row, 'redact')}
                                        >
                                            <ContentCutOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip title={'Delete'} arrow>
                                    <div>
                                        <IconButton
                                            aria-label="Delete"
                                            disabled={fileLoading}
                                            onClick={() => {
                                                dispatch(setConfirmDeleteFile({ open: true, data: { fileName, fileEntityId, documentId, user } }));
                                                setFile(params.row)
                                            }}
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Stack>
                        }
                    </Box>
                );
            },
        },
    ];

    const handleClose = () => {
        !isDirty && dispatch(setEditAllDocumentsOpen(false));
        isDirty && dispatch(setDocumentEditingConfirmation({
            open: true,
            data: {
                text: DiscardWork.text,
                confirmText: DiscardWork.confirmText,
                cancelText: DiscardWork.cancelText,
                source: 'editDocuments',
                buttonColor: 'error'
            }
        }));
    }

    const checkStatus = async (documentId) => {
        const response = await regenerateDocumentStatusApi(documentId, user);
        const status = response["status"];

        if (status === "Complete") {
            dispatch(setDocumentDataLoading(documentId));
            dispatch(fetchMedicalsData({ documentId, user }))
            dispatch(fetchDocumentData({ documentId, user }))
                .then(() => {
                    dispatch(setDocumentRegnerationLoading(''));
                    setToastMessage("Demand updated successfully");
                    setToastSeverity('success');
                    setToastOpen(true);
                    setIsDirty(false);
                    setIsSaving(false);
                    dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file?.fileEntityId]: 'Saved' }));
                })
            return;
        }
        else if (status === "Failed") {
            setToastMessage("Error updating demand");
            setToastSeverity('error');
            setToastOpen(true);
            dispatch(setDocumentRegnerationLoading(''));
            return;
        }

        setTimeout(() => {
            checkStatus(documentId);
        }, 3000);
    }

    const handleSubmit = () => {
        setIsSaving(true);
        setToastMessage("Saving document arrangement...");
        setToastSeverity('info');
        setToastOpen(true);
        dispatch(setDocumentRegnerationLoading(true));

        const filesIdSequence = localDocumentFiles.map((file) => file.fileEntityId);

        setFilesSequenceApi(documentId, { orderedFiles: filesIdSequence }, user)
            .then(response => {
                if (response.status === 200) {
                    checkStatus(documentId);
                    getDocumentFilesApi(documentId, user)
                        .then(response => response.json())
                        .then(data => {
                            dispatch(setDocumentFiles(data));
                            setLocalDocumentFiles(data);
                        })
                        .catch(error => {
                            dispatch(setDocumentRegnerationLoading(''));
                            console.log(error);
                            setToastMessage("Error updating demand");
                            setToastSeverity('error');
                            setToastOpen(true);
                            setIsSaving(false);
                        })
                } else {
                    setIsSaving(false)
                }
            })
            .catch(error => {
                console.log(error);
                setToastMessage("Error updating demand");
                setToastSeverity('error');
                setToastOpen(true);
                dispatch(setDocumentRegnerationLoading(''));
            })
    }

    const rows = localDocumentFiles.map((file) => {
        return {
            ...file,
            id: file.fileEntityId,
            name: file?.fileEntityData?.fileName || '',
            detectedType: file?.fileEntityData?.detectedType || '',
            pinned: file?.fileEntityData?.detectedType === "cover letter" || file?.fileEntityData?.detectedType === "demand letter",
        }
    });

    const columnsWithOptions = columns.map((column) => {
        return {
            ...column,
            resizable: false,
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            disableColumnSelector: true,
            checkboxSelection: true,
            disableReorder: true,
        }
    });

    const handleRowOrderChange = (params, b) => {
        const filesCopy = [...localDocumentFiles]
        const nonPinnedFiles = filesCopy.filter(file => file.fileEntityData.detectedType !== "cover letter" && file.fileEntityData.detectedType !== "demand letter")
        const pinnedFiles = filesCopy.filter(file => file.fileEntityData.detectedType === "cover letter" || file.fileEntityData.detectedType === "demand letter").sort((a, b) => a.fileEntityData.detectedType.localeCompare(b.fileEntityData.detectedType));
        const { oldIndex, targetIndex } = params;
        const movedFile = nonPinnedFiles.splice(oldIndex, 1)[0];
        nonPinnedFiles.splice(targetIndex, 0, movedFile);
        setLocalDocumentFiles([...pinnedFiles, ...nonPinnedFiles]);
        setIsDirty(true);
    }

    return (
        <Dialog
            fullScreen
            open={editAllDocumentsOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                    <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>

                            <IconButton onClick={handleClose} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                <Close color={'white'} />
                            </IconButton>

                            <Title color={theme.palette.white.main}>Edit documents</Title>

                        </Stack>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Button
                                disabled={!isDirty || isSaving || fileDisabled}
                                variant="contained"
                                color="white"
                                sx={{ '&:disabled': { backgroundColor: ("rgba(255,255,255,0.2)") } }}
                                onClick={handleSubmit}
                            >
                                {isSaving ? "Saving" : "Save"}
                            </Button>
                        </Stack>
                    </Stack>
                </DialogTitle>
            </AppBar>

            <Container sx={{ mt: '60px' }}>
                <DialogContent sx={{ marginTop: '0px', paddingTop: '0px', overflow: 'visible' }}>
                    <Box>

                        <Typography variant="body1" sx={{ my: 1 }}>
                            Drag and drop to arrange
                        </Typography>

                        <DataGridPro
                            rows={rows}
                            columns={columnsWithOptions}
                            rowReordering
                            onRowOrderChange={handleRowOrderChange}
                            pinnedRows={{ top: rows.filter(row => row.pinned).sort((a, b) => a.detectedType.localeCompare(b.detectedType)), bottom: [] }}
                            hideFooter
                            loading={isSaving || fileDisabled || documentRegnerationLoading || docummentDataLoading}
                            sx={{
                                height: 'calc(100vh - 120px)',
                                '.MuiDataGrid-pinnedRows': {
                                    '&:hover': {
                                        cursor: 'not-allowed',
                                    }
                                }
                            }}
                        />

                    </Box>
                </DialogContent>
            </Container>

            {!!editOneDocumentFileName && !!documentEditS3Url && (
                <Dialog open={editDocumentPdfOpen} fullScreen={true} fullWidth={true} >
                    <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                        <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <IconButton onClick={(e) => {
                                    dispatch(setEditDocumentPdfOpen(false));
                                }} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                    <ArrowBack color={'white'} />
                                </IconButton>

                                <Title color={theme.palette.white.main}>Edit {editOneDocumentFileName}</Title>

                            </Stack>
                        </Stack>
                    </DialogTitle>
                    <PdfEditor
                        documentS3Url={documentEditS3Url}
                        user={user}
                        documentId={documentId}
                        file={file}
                        setToastMessage={setToastMessage}
                        setToastSeverity={setToastSeverity}
                        setToastOpen={setToastOpen}
                        setLocalDocumentFiles={setLocalDocumentFiles}
                        setIsSaving={setIsSaving}
                    />
                </Dialog>
            )}

            {!!editOneDocumentFileName && !!documentEditS3Url && (
                <Dialog open={redactDocumentOpen} fullScreen={true} fullWidth={true} >
                    <PdfRedactorDialog
                        documentS3Url={documentEditS3Url}
                        user={user}
                        documentId={documentId}
                        file={file}
                        setToastMessage={setToastMessage}
                        setToastSeverity={setToastSeverity}
                        setToastOpen={setToastOpen}
                        setLocalDocumentFiles={setLocalDocumentFiles}
                        setIsSaving={setIsSaving}
                    />
                </Dialog>
            )}

            <Snackbar
                open={toastOpen}
                autoHideDuration={7000}
                onClose={e => setToastOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Paper elevation={8}>
                    <Alert onClose={e => setToastOpen(false)} severity={toastSeverity} sx={{ width: '100%' }}>{toastMessage}</Alert>
                </Paper>
            </Snackbar>

        </Dialog>
    )
}

export default EditDocuments;