import { createAsyncThunk } from '@reduxjs/toolkit'
import awsExports from '../../aws-exports';
import { postDocumentFilesByIdsApi, getDocumentFilesApi } from '../../api';
import { setDocumentFiles, setDocumentFilesLoading } from '../slices/demandDomSlice';
import { setDocumentRegnerationLoading } from '../slices/documentSlice';
import { checkRegeneratePdfStatus } from './documentData';
import { setToast } from '../slices/globalToastSlice';
const rootApiUrl = awsExports.ROOT_API_URL;

export const fetchInventoryDemandData = createAsyncThunk(
    'Inventory/fetchInventoryDemandDataStatus',
    async ({ requestBody, token }, { }) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
            body: JSON.stringify(requestBody)
        };

        const response = await fetch(rootApiUrl + "/inventory", settings)
            .then((response) => response.json())

        return { response, isActive: requestBody.active_filter };
    },
)

export const fetchAuthorFormData = createAsyncThunk(
    'Author/fetchAuthorFormDataStatus',
    async ({ token, user, customerId = null, hasPermission = false }, { rejectWithValue }) => {
        if (hasPermission) {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({ customerId: customerId })
            };

            const response = await fetch(
                rootApiUrl + "/authorFormData", settings
            ).then((response) => response.json())

            return { response, user };
        } else {
            return rejectWithValue({ error: 'User does not have permission to view this data' });
        }
    }
)

export const saveDocumentFilesBydId = createAsyncThunk(
    'DemandDom/saveDocumentFilesBydIdStatus',
    async ({ documentId, files, user }, { dispatch, rejectWithValue }) => {
        dispatch(setDocumentRegnerationLoading(documentId));
        const response = await postDocumentFilesByIdsApi(documentId, files, user)
            .then((response) => {
                if (response.status === 200) {
                    getDocumentFilesApi(documentId, user)
                        .then(response => response.json())
                        .then(data => {
                            setTimeout(() => {
                                dispatch(checkRegeneratePdfStatus(documentId, user));
                            }, 3000);
                            dispatch(setDocumentFiles(data));
                            dispatch(setDocumentFilesLoading(false));
                        })
                        .catch(error => {
                            console.log('error', error);
                            dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating files' }));
                            dispatch(setDocumentFilesLoading(false));
                        });
                    dispatch(setToast({ isOpen: true, severity: 'success', message: 'Files updated successfully' }));
                    return response.json();
                } else {
                    dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating files' }));
                    return rejectWithValue(response.json());
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating files' }));
                return rejectWithValue(error);
            });

        return response;
    }
);
