import { createAsyncThunk } from '@reduxjs/toolkit'
import { generateNarrativeAdmin, getAiEnabledFields, loadAiData, getAiDataLoaders } from "../../api";
import { setToast } from "../slices/globalToastSlice";

export const generateNarrative = createAsyncThunk(
    'AiNarrativeGeneration/generateNarrative',
    async ({documentId, requestBody, user}, { dispatch, rejectWithValue }) => {
    return generateNarrativeAdmin(documentId, requestBody, user)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setToast({message: 'Narrative generated successfully', severity: 'success', isOpen: true, duration: 2000}));
                return response.body;
            } else {
                dispatch(setToast({message: response?.body?.error || 'Error generating AI narrative', severity: 'error', isOpen: true, duration: 9000}));
                return rejectWithValue(response?.body?.error || 'Error generating AI narrative');
            }
        })
        .catch((error) => rejectWithValue(error))
});

export const fetchAiEnabledFields = createAsyncThunk(
'AiNarrativeGeneration/fetchAiEnabledFields',
    async ({documentId, user, requestBody}, { dispatch, rejectWithValue }) => {
    return getAiEnabledFields(documentId, user)
        .then((response) => {
            if (response.status === 200) {
                return response.body;
            } else {
                dispatch(setToast({message: response?.body?.error || 'Error fetching AI enabled fields', severity: 'error', isOpen: true, duration: 9000}));
                return rejectWithValue(response?.body?.error || 'Error fetching AI enabled fields');
            }
        })
        .catch((error) => rejectWithValue(error))
});

export const fetchAiData= createAsyncThunk(
'AiNarrativeGeneration/fetchAiData',
    async ({documentId, requestBody, fieldConfig, tabValue, user}, { dispatch, rejectWithValue }) => {
    return loadAiData(documentId, requestBody, user, fieldConfig)
        .then((response) => {
            if (response.status === 200) {
                return response.body;
            } else {
                dispatch(setToast({message: response?.body?.error || 'Error loading AI data', severity: 'error', isOpen: true, duration: 9000}));
                return rejectWithValue(response?.body?.error || 'Error loading AI data');
            }
        })
        .catch((error) => rejectWithValue(error))
});

export const reloadAiData = createAsyncThunk(
'AiNarrativeGeneration/reloadAiData',
    async ({documentId, requestBody, user}, { dispatch, rejectWithValue }) => {
    return loadAiData(documentId, requestBody, user)
        .then(response => {
            if (response.status === 200) {
                return response.body;
            } else {
                dispatch(setToast({message: response?.body?.error || 'Error loading AI data', severity: 'error', isOpen: true, duration: 9000}));
                return rejectWithValue(response?.body?.error || 'Error loading AI data');
            }
        })
        .catch((error) => rejectWithValue(error))
});

export const getLoaders = createAsyncThunk(
'AiNarrativeGeneration/getAiDataLoaders',
    async ({user}, { dispatch, rejectWithValue }) => {
    return getAiDataLoaders(user)
        .then(response => {
            if (response.status === 200) {
                return response.body?.loaders;
            } else {
                dispatch(setToast({message: response?.body?.error || 'Error getting AI data loaders', severity: 'error', isOpen: true, duration: 9000}));
                return rejectWithValue(response?.body?.error || 'Error getting AI data loaders');
            }
        })
        .catch((error) => rejectWithValue(error))
});