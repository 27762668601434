
import React, { useState, useEffect } from "react";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, CircularProgress, Typography, Stack } from '@mui/material';
import { Transition } from '../Transition'
import { sendToCarrierApi } from '../api';
import StatusDialog from "./StatusDialog";
import { getMedicalTreatmentAlerts } from "../MedicalsComponents/medicals";
import ConfirmationSwitch from '../CommonComponents/ConfirmationSwitch';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { fetchMedicalsData } from '../redux/thunks/medicalsData';

const CarrierSendDialog = ({ handleClose, dialogOpen, documentId, user, carrierName, deliveryEmail, handler, viewType, docData, source }) => {
    const dispatch = useDispatch();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [medicalTreatmentAlerts, setMedicalTreatmentAlerts] = useState([]);
    const [switchChecked, setSwitchChecked] = useState(false);
    const { documentData, documentDataLoading } = useSelector((state) => state.Document);
    const { medicals, medicalsLoading } = useSelector((state) => state.Medicals);
    const hasAlerts = medicalTreatmentAlerts.length > 0;
    const buttonIsDisabled = (hasAlerts && !switchChecked) || documentDataLoading || medicalsLoading;
    const currentDocumentFetched = documentData?.documentId === documentId;

    useEffect(() => {
        if (source === 'inventory') {
            dispatch(fetchDocumentData({ documentId, user }));
            dispatch(fetchMedicalsData({ documentId, user }));
        }
    }, [documentId]);

    useEffect(() => {
        if (!documentDataLoading && !medicalsLoading && currentDocumentFetched) {
            setMedicalTreatmentAlerts(getMedicalTreatmentAlerts(documentData, viewType, true, true, medicals));
        }
    }, [documentDataLoading, medicalsLoading]);

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const sendToCarrier = async (userTriggered) => {

        if (userTriggered) {
            handleClose();
            setStatusCloseDisabled(true);
            showStatusDialog("Sending demand to " + carrierName + "...")
        }

        sendToCarrierApi(documentId, user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The demand has been sent to " + carrierName);
                    setStatusCloseDisabled(false);
                    handler(true);
                }
                else if (response.status == 201) {
                    setStatusDialogText("The demand is being regenerated. This should only take a few moments.");
                    setTimeout(function () { sendToCarrier(false) }, 3000);
                }
                else {
                    setStatusCloseDisabled(false);
                    showStatusDialog("There was an error sending the email :-(");
                    handler(false);
                }
            });
    }

    return (
        <>
            <Dialog
                maxWidth="xs"
                open={dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    Send demand to carrier
                    {(documentDataLoading || medicalsLoading) && (
                        <CircularProgress />
                    )}
                </DialogTitle>
                <DialogContent>

                    {hasAlerts && (
                        <Box>
                            <DialogContentText id="alert-dialog-slide-description">
                                The following warnings exist on this demand:
                            </DialogContentText>

                            <div>
                                {medicalTreatmentAlerts.map((alert, index) => {
                                    return (
                                        <Alert sx={{ mt: 1 }} key={index} severity={alert.alertSeverity}>{alert.alertMessage}</Alert>
                                    )
                                }
                                )}
                            </div>
                            <ConfirmationSwitch setSwitchChecked={setSwitchChecked} switchChecked={switchChecked} labelText='Send demand despite warnings'></ConfirmationSwitch>
                        </Box>
                    )}


                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack spacing={2}>
                            <Typography variant="body1" color={"primary"}>
                                This demand will be submitted to <b>{carrierName}</b> by Precedent.
                            </Typography>
                            <Typography variant="body1" color={"primary"}>
                                Are you sure you want to continue?
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClose() }}>Cancel</Button>
                    <Button disabled={buttonIsDisabled} variant="contained" color="secondary" onClick={() => { sendToCarrier(true) }}>Approve & send</Button>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default CarrierSendDialog;