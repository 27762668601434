import React from 'react';
import { Stack, Typography, Link, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DataGridPro } from '@mui/x-data-grid-pro';

const dataGridStyles = { backgroundColor: '#FFFFFF' };

const MedicalsMRIDetailPanelContent = ({ mriDescription, rows, handleViewPageInDocument }) => {
  const columns = [
    {
      field: 'document',
      headerName: 'Document',
      width: 400,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return params.row?.page ? (
          <Link
            component="button"
            onClick={() => handleViewPageInDocument(params.row?.page, params.row?.bboxes)}
            style={{ textDecoration: 'none', textAlign: 'left' }}
          >
            <Typography variant="tableP1" style={{ color: '#4077B0' }}>
              {params.row.document}
            </Typography>
          </Link>
        ) : (
          <Typography variant="tableP1">{params.row.document}</Typography>
        );
      },
    },
    {
      field: 'page',
      headerName: 'Page',
      width: 100,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <Typography variant="tableP1">{params.row.page}</Typography>;
      },
    },
  ];

  const useShortDetailPanel = rows.length < 7;

  return (
    <Stack
      direction="column"
      p={2}
      spacing={1}
      sx={{ backgroundColor: grey[100], borderBottom: '1px solid', borderColor: 'divider' }}
    >
      <Typography variant="body2">References to {mriDescription}</Typography>
      <Box
        sx={{
          height: useShortDetailPanel ? '' : '400px'
        }}
      >
        <DataGridPro
          rows={rows}
          columns={columns}
          density="compact"
          disableRowSelectionOnClick
          disableColumnReorder
          disableColumnSelector
          disableColumnPinning
          sx={dataGridStyles}
          getRowHeight={() => '43px'}
          autoHeight={useShortDetailPanel ? true : false}
        />
      </Box>
    </Stack>
  );
};

export default MedicalsMRIDetailPanelContent;
