import { createSlice } from '@reduxjs/toolkit'

const originalGlobalDialogueState = {
    documentEditingConfirmation: {
        open: false,
        data: {
            title: '',
            text: '',
            confirmText: '',
            cancelText: ''
        }
    },
    savingDemand: {
        open: false,
        data: {
            demandId: '',
            user: {},
            title: '',
            text: ''
        }
    },
    uploadMedicals: {
        open: false,
        data: {
            documentId: '',
            user: {}
        }
    },
    confirmDeleteFile: {
        open: false,
        data: {
            fileName: '',
            fileEntityId: '',
            documentId: '',
            user: {}
        }
    },
    reimportMedicals: {
        open: false,
        data: {
            documentId: '',
            user: {},
        },
    },
    addICDCode: {
        open: false,
        data: {
            documentId: '',
            user: {},
        }
    },
    editICDCode: {
        open: false,
        data: {
            icdSelectedRows: [],
            documentId: '',
            user: {}
        }
    },
    editMRIFinding: {
        open: false,
        data: {
            mriSelectedRows: [],
            documentId: '',
            user: {},
        }
    },
    editDemandNotes: {
        open: false,
        isOpenFromInventory: false,
        data: {
            documentId: '',
            user: {},
            initialNote: '',
        }
    },
    editDocumentNames: {
        open: false,
        data: {
            documentId: '',
            user: {}
        }
    },
}

export const globalDialogueSlice = createSlice({
    name: 'GlobalDialogues',
    initialState: originalGlobalDialogueState,
    reducers: {
        setDocumentEditingConfirmation: (state, action) => { state.documentEditingConfirmation = action.payload },
        setSavingDemand: (state, action) => { state.savingDemand = action.payload },
        setUploadMedicals: (state, action) => { state.uploadMedicals = action.payload },
        setConfirmDeleteFile: (state, action) => { state.confirmDeleteFile = action.payload },
        setReimportMedicals: (state, action) => { state.reimportMedicals = action.payload },
        setAddICDCode: (state, action) => { state.addICDCode = action.payload },
        setEditICDCode: (state, action) => { state.editICDCode = action.payload },
        setEditMRIFinding: (state, action) => { state.editMRIFinding = action.payload },
        setEditDemandNotes: (state, action) => { state.editDemandNotes = action.payload },
        setEditDocumentNames: (state, action) => { state.editDocumentNames = action.payload },
        resetGlobalDialogues: () => originalGlobalDialogueState, //use whenever you want to ensure all dialogues are closed
    },
})

export const { 
    setDocumentEditingConfirmation,
    setUploadMedicals, 
    setConfirmDeleteFile, 
    setReimportMedicals,
    resetGlobalDialogues,
    setSavingDemand, 
    setAddICDCode,
    setEditICDCode,
    setEditMRIFinding,
    setEditDemandNotes,
    setEditDocumentNames,
} = globalDialogueSlice.actions

export default globalDialogueSlice.reducer