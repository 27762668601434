/**
 * A code editor using the CodeMirror package. @see https://codemirror.net/
 *
 * CodeMirror is very modular: at its core, it's comprised of a state, a view and the default keymap.
 * Additional features and customizations can be added as needed.
 * This React implementation is based on https://www.adamcollier.co.uk/posts/adding-codemirror-6-to-a-react-project
 * and https://thetrevorharmon.com/blog/codemirror-and-react/.
 */

import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EditorState } from '@codemirror/state';
import { Skeleton } from '@mui/material';
import { EditorView, keymap, lineNumbers, highlightActiveLine, highlightActiveLineGutter } from '@codemirror/view';
import { defaultKeymap, indentWithTab, history, historyKeymap } from '@codemirror/commands';
import {
  LanguageSupport,
  syntaxHighlighting,
  defaultHighlightStyle,
  foldGutter,
  bracketMatching,
  indentUnit,
} from '@codemirror/language';
import { html } from '@codemirror/lang-html';
import { handlebarsLanguage } from '@xiechao/codemirror-lang-handlebars';
import { useDispatch } from 'react-redux';
import { setCurrentCodeEditorContent } from '../redux/slices/codeEditorSlice';

const CodeEditor = ({ parentComponentName, height }) => {
  const { currentCodeEditorContent, codeEditorContentFetched } = useSelector((state) => state.CodeEditor[parentComponentName]);
  const dispatch = useDispatch();
  const editor = useRef();


  useEffect(() => {
    if (codeEditorContentFetched) {
      // Define an update listener that calls the onUpdate function whenever the view updates.
      const updateListener = EditorView.updateListener.of((viewUpdate) => {
        if (viewUpdate.docChanged) {
          dispatch(setCurrentCodeEditorContent({ parentComponentName, content: viewUpdate.state.doc.toString() }));
        }
      });

      const startState = EditorState.create({
        doc: currentCodeEditorContent,
        extensions: [
          keymap.of([...defaultKeymap, ...historyKeymap, indentWithTab]),
          new LanguageSupport(handlebarsLanguage, html().support), // Handlebars/Mustache with HTML nested. html() also has support for Javascript and CSS.
          lineNumbers(),
          syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
          highlightActiveLine(),
          highlightActiveLineGutter(),
          foldGutter(),
          bracketMatching(),
          indentUnit.of('    '), // indent 4 spaces
          history(),
          updateListener,
          EditorView.lineWrapping
        ],
      });

      new EditorView({
        state: startState,
        parent: editor.current, // Bind the codemirror editor view to the ref div
      });
    }
  }, [codeEditorContentFetched]);

  return (
    <>

      {!codeEditorContentFetched &&
        <Skeleton
          variant="rectangular"
          width="100%"
          height={height}
        />
      }

      {codeEditorContentFetched &&
        <div
          ref={editor}
          style={{
            width: '100%',
            // height: 'calc(100vh - 80px)',
            overflow: 'auto',
            border: '1px solid rgba(0,0,0,0.12)',
            height: height
          }}>
        </div>
      }

    </>
  );
};

export default CodeEditor;
