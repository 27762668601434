import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide, Typography, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setBulkDeleteOpen } from '../redux/slices/demandDomSlice';
import { Transition } from '../Transition';

const BulkDeleteMedical = ({ handleSaveBulkDeleteClick }) => {

    const dispatch = useDispatch();
    const bulkDeleteOpen = useSelector((state) => state.DemandDom.bulkDeleteOpen);
    const selectionModel = useSelector((state) => state.MedicalsTreatments.selectionModel);

    return (

        <Dialog
            maxWidth="xs"
            open={bulkDeleteOpen}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
        >

            <DialogContent
                sx={{
                    marginTop: 0
                }}
            >
                <DialogContentText>
                    <Stack spacing={2}>
                        <Typography variant="body1" color={"primary"}>
                            {`You are about to permanently delete ${selectionModel.length} medical treatment${selectionModel.length > 1 ? 's' : ''}.`}
                        </Typography>
                        <Typography variant="body1" color={"primary"}>
                            Are you sure you want to continue?
                        </Typography>

                    </Stack>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    color='secondary'
                    onClick={() => dispatch(setBulkDeleteOpen(false))}
                > Cancel </Button>
                <Button variant="contained" color="error" onClick={handleSaveBulkDeleteClick} disabled={selectionModel.length === 0}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default BulkDeleteMedical;
