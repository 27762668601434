import { createSlice } from '@reduxjs/toolkit';
import { fetchTemplateData, fetchTemplateDocumentUsage, bumpTemplatePin } from '../thunks/templateData';

const originalTemplateSlice = {
  bumpTemplatePinLoading: false,
  templateFetchLoading: false,
  documentUsageLoading: false,
  documentUsage: {},
  templateBody: {},
};

const TemplateSlice = createSlice({
  name: 'Template',
  initialState: originalTemplateSlice,
  reducers: {
    setTemplate: (state, action) => {
      state.templateBody = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchTemplateData.fulfilled, (state, action) => {
        if (action.payload) {
          state.templateBody = action.payload;
        }
        state.templateFetchLoading = false;
      })
      .addCase(fetchTemplateData.pending, (state) => {
        state.templateFetchLoading = true;
      })
      .addCase(fetchTemplateData.rejected, (state, action) => {
        state.templateFetchLoading = false;
      });

    builder.addCase(fetchTemplateDocumentUsage.fulfilled, (state, action) => {
        state.documentUsage = action.payload;
        state.documentUsageLoading = false;
    })

    builder.addCase(fetchTemplateDocumentUsage.pending, (state) => {
        state.documentUsageLoading = true;
    })

    builder.addCase(fetchTemplateDocumentUsage.rejected, (state, action) => {
        state.documentUsageLoading = false;
    })

    builder.addCase(bumpTemplatePin.pending, (state) => {
        state.bumpTemplatePinLoading = true;
    })

    builder.addCase(bumpTemplatePin.fulfilled, (state, action) => {
        state.bumpTemplatePinLoading = false;
    })

    builder.addCase(bumpTemplatePin.rejected, (state, action) => {
        state.bumpTemplatePinLoading = false;
    })


  }
});

export const { setTemplate } = TemplateSlice.actions;

export default TemplateSlice.reducer;
