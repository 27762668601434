import React from 'react'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff';
import { Stack, DialogActions, Button, InputAdornment } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { cleanNumber, getCurrentDate, formatDecimal } from '../common';
import { useSelector } from 'react-redux';
import { isCompositeDemand } from '../DemandComponents/helpers';
import { postMedicalsData } from '../redux/thunks/medicalsData';
import { useDispatch } from 'react-redux';

const AddMedicalTreatment = ({ handleAddMedicalsClose, setAddMedicalOpen, medicals, user }) => {
    const dispatch = useDispatch();

    const documentDataState = useSelector(state => state.Document);
    const { documentData, documentMetaData } = documentDataState;

    const isComposite = isCompositeDemand(documentMetaData?.documentType);

    const validate = values => {
        const errors = {};
        if (!values.treatmentFacility) {
            errors.treatmentFacility = 'Required';
        }
        if (!values.treatmentDate) {
            errors.treatmentDate = 'Required';
        }
        if (!values.billedAmount) {
            errors.billedAmount = 'Required';
        }

        if (isComposite && !values.generatedPageNumber) {
            errors.generatedPageNumber = 'Required';
        }
        return errors;
    }

    const onSubmit = async values => {
        const billedAmount = cleanNumber(values.billedAmount);
        const treatmentFacility = values.treatmentFacility.trim();
        const generatedPageNumber = values.generatedPageNumber ? parseInt(values.generatedPageNumber) : '';
        const updatedMedicals = {
            ...medicals,
            medicalTreatments: [...medicals.medicalTreatments, { ...values, billedAmount, treatmentFacility, generatedPageNumber }]
        };

        dispatch(postMedicalsData({
            documentId: documentData.documentId,
            medicals: updatedMedicals,
            user
        }))
        .catch(err => console.log(err));
        setAddMedicalOpen(false);
    }

    return (
        <div>
            <Form
                onSubmit={onSubmit}
                initialValues={{ medicalTreatmentId: uuidv4() }}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values, valid, errors }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Stack spacing={2} sx={{ mt: 1 }}>
                                    <TextField label="Facility" name="treatmentFacility" />
                                    <TextField label="Type" name="treatmentType" />
                                    <TextField label="Date of service" name="treatmentDate" type="date" InputLabelProps={{ shrink: true }} inputProps={{ max: getCurrentDate() }} />
                                    <TextField label="Charged amount" name="billedAmount" onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                    />
                                    <TextField label="Diagnoses" name="diagnoses" />
                                    <TextField label="Treatments" name="treatments" />
                                    <TextField
                                        onChange={(e) => form.change('generatedPageNumber', (e.target.value.replace(/[^0-9]/g, '')))}
                                        label="Generated Page number"
                                        name="generatedPageNumber"
                                    />
                                </Stack>
                            </div>

                            <DialogActions>
                                <Button color='secondary' variant="text" onClick={() => { handleAddMedicalsClose() }}>Cancel</Button>
                                <Button type='submit' variant="contained" color="secondary" disabled={!valid}>Save</Button>
                            </DialogActions>

                        </form>
                    )
                }}
            />
        </div>
    )
}

export default AddMedicalTreatment;
