import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import CircularProgress from '@mui/material/CircularProgress';

const RequireAuth = ({ roles, permissions, children }) => {
  const location = useLocation();
  const { route, user } = useAuthenticator((context) => [context.route, context.user]);

  // Valid route states are: authenticated, setup, signIn, signOut, idle
  if(route === 'idle') {
    return <CircularProgress />;
  } else if (route !== 'authenticated'  && route !== 'idle' ) {
    // If we have setup, it means they were redirected there from a protected route
    // This means that they were once authenticated, otherwise there's no way they could have been there.
    // Effectively, this means their session expired.
    // Brand new signins come from the signIn route, or if the user explicitly signed out they get the signOut route
    let target = route !== 'setup' ? '/login' : '/login?status=expired';
      return <Navigate to={target} state={{ from: location, roles: roles, permissions: permissions }} replace />;
  } else {

    return (
      <>
        {children}
      </>
    );
  }
}

export default RequireAuth;