import { createSlice } from '@reduxjs/toolkit'
import { generateNarrative, fetchAiEnabledFields, fetchAiData, reloadAiData, getLoaders } from "../thunks/aiNarrativeGenerationData";

const originalGenerationState = {
    aiEnabledFields: {},
    aiDataLoaders: [],
    selectedField: "",
    aiGenerationPrompt: "",
    aiGenerationData: "",
    aiDataSourceConfig: "",
    generatedNarrativeContent: "",
    isGenerating: false,
    isLoadingFields: true,
    tabValue: 0
}

const aiNarrativeGenerationSlice = createSlice({
    name: 'aiNarrativeGeneration',
    initialState: originalGenerationState,
    reducers: {
        setAiEnabledFields: (state, action) => {state.aiEnabledFields = action.payload },
        setAiDataLoaders: (state, action) => {state.aiDataLoaders = action.payload },
        setSelectedField: (state, action) => {state.selectedField = action.payload },
        setAiGenerationPrompt: (state, action) => {state.aiGenerationPrompt = action.payload },
        setAiGenerationData: (state, action) => {state.aiGenerationData = action.payload },
        setAiDataSourceConfig: (state, action) => {state.aiDataSourceConfig = action.payload },
        setGeneratedNarrativeContent: (state, action) => { state.generatedNarrativeContent = action.payload },
        setIsGenerating: (state, action) => { state.isGenerating = action.payload },
        setIsLoadingFields: (state, action) => { state.isLoadingFields = action.payload },
        setTabValue: (state, action) => { state.tabValue = action.payload },
    },
    extraReducers: (builder) => {
        builder.addCase(generateNarrative.pending, (state, action) => {
            state.isGenerating = true;
        });
        builder.addCase(generateNarrative.fulfilled, (state, action) => {
            if (action.payload) {
                state.isGenerating = false;
                state.generatedNarrativeContent = action.payload;
                state.tabValue = 2; // switch to results tab
            }
        });
        builder.addCase(generateNarrative.rejected, (state, action) => {
            state.isGenerating = false;
        });
        builder.addCase(fetchAiEnabledFields.pending, (state, action) => {
            state.isLoadingFields = true;
            state.aiEnabledFields = {};
        });
        builder.addCase(fetchAiEnabledFields.fulfilled, (state, action) => {
            if (action.payload) {
                state.isLoadingFields = false;
                state.aiEnabledFields = action.payload;
            }
        });
        builder.addCase(fetchAiEnabledFields.rejected, (state, action) => {
            state.isLoadingFields = false;
            state.aiEnabledFields = {};
        });
        builder.addCase(fetchAiData.fulfilled, (state, action) => {
            if (action.payload) {
                state.aiGenerationData = action.payload;
                state.aiGenerationPrompt = action.meta.arg.fieldConfig.aiGenerationPrompt;
                state.aiDataSourceConfig = action.meta.arg.fieldConfig.aiDataSourceConfig;
                if (state.tabValue === 2) {
                    state.tabValue = 0; // switch to data tab
                }
            }
        });
        builder.addCase(fetchAiData.rejected, (state, action) => {
            state.aiGenerationData = "";
        });
        builder.addCase(reloadAiData.pending, (state, action) => {
            state.aiGenerationData = "";
        });
        builder.addCase(reloadAiData.fulfilled, (state, action) => {
            if (action.payload) {
                state.aiGenerationData = action.payload;
            }
        });
        builder.addCase(reloadAiData.rejected, (state, action) => {
            state.aiGenerationData = "";
        });
        builder.addCase(getLoaders.fulfilled, (state, action) => {
            if (action.payload) {
                state.aiDataLoaders = action.payload;
            }
        });
        builder.addCase(getLoaders.rejected, (state, action) => {
            state.aiDataLoaders = [];
        });
    }
})

export const { setAiEnabledFields, setSelectedField,
    setAiGenerationPrompt, setAiGenerationData,
    setAiDataSourceConfig, setGeneratedNarrativeContent,
    setIsGenerating, setTabValue,
    setIsLoadingFields, setAiDataLoaders} = aiNarrativeGenerationSlice.actions

export default aiNarrativeGenerationSlice.reducer;