import React from 'react';
import { FormControlLabel, Switch, DialogContentText } from '@mui/material';

const ConfirmationSwitch = ({setSwitchChecked, switchChecked, labelText }) => {
    return (
        <FormControlLabel
        control={
            <Switch
                onChange={() => setSwitchChecked(!switchChecked)}
                checked={switchChecked}
                color="primary"
                sx={{
                    mb: 3,
                    mt: 2,
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#2196F3',
                        '&:hover': {
                            backgroundColor: '#90CBF9',
                        },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#90CBF9',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
                        backgroundColor: 'white',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked:hover .MuiSwitch-thumb': {
                        backgroundColor: '#2196F3',
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: '#9E9E9E',
                    },
                }}
            />
        }
        label={<DialogContentText id="alert-dialog-slide-description">{labelText}</DialogContentText>}
    />
    );
};

export default ConfirmationSwitch;
