import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellCheckboxRenderer,
} from '@mui/x-data-grid-pro';
import { Alert, AlertTitle, Typography, Box, Stack } from '@mui/material';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, WarningAmber } from '@mui/icons-material';
import {
  mapPageInsightStatusToDisplayStatus,
  selectPageInsightStatusOptions,
  formatPageNumbers,
  postIcdCodeUpdates,
} from './pageInsights';
import { getDocumentFilesApi } from '../api';
import MedicalsDiagnosesDetailPanelContent from './MedicalsDiagnosesDetailPanelContent';
import TableNoRowsOverlay from './TableNoRowsOverlay';
import { setSelectedRowsAndIcdCodeIds } from '../redux/slices/icdCodesSlice';
import { setDocumentFiles } from '../redux/slices/demandDomSlice';
import { checkRegeneratePdfStatus } from '../redux/thunks/documentData';
import { setDocumentRegnerationLoading, setDocumentFilesLoading } from '../redux/slices/documentSlice';
import { setToast } from '../redux/slices/globalToastSlice';
import {userHasPermission} from "../common";

const slots = {
  detailPanelExpandIcon: KeyboardArrowDownOutlined,
  detailPanelCollapseIcon: KeyboardArrowUpOutlined,
  noRowsOverlay: TableNoRowsOverlay,
};

const slotProps = {
  noRowsOverlay: {
    text: 'No diagnoses',
  },
};

const MedicalsDiagnosesTable = ({
  documentFiles,
  documentId,
  handleViewPageInDocument,
  user,
  documentFilesLoading,
  aggregatedIcdCodeData,
  icdCodesAlerts,
  userData
}) => {
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);

  const { selectedRowsAndIcdCodeIds } = useSelector((state) => state.IcdCodes);
  // The array of keys corresponds to the selected parent rows.
  const rowSelectionModel = Object.keys(selectedRowsAndIcdCodeIds) || [];

  const dispatch = useDispatch();

  const rows = Object.keys(aggregatedIcdCodeData).map((icdCode) => ({
    id: icdCode,
    icdCode,
    ...aggregatedIcdCodeData[icdCode],
  }));

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        // Partial sub row selections would show the Indeterminate checkbox state.
        const isPartialSelection = selectedRowsAndIcdCodeIds[params.row.id]
          ? selectedRowsAndIcdCodeIds[params.row.id].length < params.row.references.length
          : false;

        return <GridCellCheckboxRenderer {...params} indeterminate={isPartialSelection} />;
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    },
    {
      field: 'icdCode',
      headerName: 'ICD code',
      width: 400,
      editable: false,
      renderCell: ({ row }) => {
        const { icdCode, description, needsReview, totalCount, includedCount, } = row;
        const displayCount = includedCount === totalCount ? totalCount : `${includedCount}/${totalCount}`;
        return (
          <>
            {needsReview && <WarningAmber color="warning" sx={{ mr: 1 }} />}
            <Stack mt={.5} mb={.5}>
              <Typography variant="tableP1">{`${icdCode} (${displayCount})`}</Typography>
              <Typography variant="tableP2">{description}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      field: 'pages',
      headerName: 'Page(s)',
      width: 200,
      editable: false,
      renderCell: (params) => {
        return <Typography variant="tableP1">{formatPageNumbers(params.row.pages)}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: true,
      filterable: true,
      type: 'singleSelect',
      valueOptions: selectPageInsightStatusOptions,
      renderCell: (params) => {
        return <Typography variant="tableP1">{mapPageInsightStatusToDisplayStatus[params.row.status]}</Typography>;
      },
    },
  ];

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <MedicalsDiagnosesDetailPanelContent
        icdCode={row.icdCode}
        rows={row.references}
        handleViewPageInDocument={handleViewPageInDocument}
        userData={userData}
      />
    ),
    [handleViewPageInDocument, userData]
  );
  const getRowHeight = useCallback(() => 'auto', []);

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);

  const getRowClassName = useCallback((params) => {
    const evenOrOddClassName = params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd';
    if (params.row.needsReview) {
      return `${evenOrOddClassName} grid-row-warning`;
    }
    return evenOrOddClassName;
  }, []);

  const handleRowSelectionChange = useCallback(
    (newRowSelectionModel) => {
      // The row selections here are the parent rows (ICD codes). Each row is set as the key, with the value being all the sub-row selections.
      const newSelectedIcdCodeIds = {};

      newRowSelectionModel.forEach((parentRowId) => {
        if (selectedRowsAndIcdCodeIds[parentRowId]) {
          newSelectedIcdCodeIds[parentRowId] = [...selectedRowsAndIcdCodeIds[parentRowId]];
        } else {
          const row = rows.find((row) => row.id === parentRowId) || {};
          newSelectedIcdCodeIds[parentRowId] = row?.references.map((subRow) => subRow.id);
        }
      });

      dispatch(setSelectedRowsAndIcdCodeIds(newSelectedIcdCodeIds));
    },
    [rows, selectedRowsAndIcdCodeIds, dispatch]
  );

  const processRowUpdate = (updatedRow, originalRow) => {
    if (updatedRow.status !== originalRow.status) {
      // NOTE: Backend SHOULD be returning quickly but currently actually waits for full regen to happen, so we throw skeleton up early
      dispatch(setDocumentFilesLoading(true)); // Table skeleton
      dispatch(setDocumentRegnerationLoading(documentId)); // PDF Skeleton

      const fileIds = updatedRow.references.map(({ fileId }) => fileId);
      const filesToUpdate = documentFiles.filter((file) => fileIds.includes(file.fileEntityId));
      const icdCodeIds = updatedRow.references.map(({ icdCodeId }) => icdCodeId);

      postIcdCodeUpdates(documentId, user, filesToUpdate, icdCodeIds, updatedRow.status)
        .then(() => {
          getDocumentFilesApi(documentId, user)
            .then((response) => response.json())
            .then((data) => {
              dispatch(setDocumentFiles(data));
              dispatch(setDocumentFilesLoading(false));
              dispatch(checkRegeneratePdfStatus(documentId, user));
            });
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(setDocumentFilesLoading(false));
          dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating ICD code(s)' }));
        });
    }

    return updatedRow;
  };

  const handleProcessRowError = useCallback((error) => console.log(error), []);

  return (
    <>
      {icdCodesAlerts.map(({ alertSeverity, alertTitle, alertMessage }, index) => {
        return (
          <Alert key={index} severity={alertSeverity} sx={{ mb: 1 }}>
            {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
            {alertMessage}
          </Alert>
        );
      })}
      {/* These two boxes are a bit of a hack to force the browser to calculate the DataGrid's dimensions, allowing it to be height responsive. */}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            loading={documentFilesLoading}
            rows={rows}
            columns={columns}
            getRowHeight={getRowHeight}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            disableRowSelectionOnClick
            disableColumnReorder
            disableColumnSelector
            disableColumnPinning
            slots={slots}
            slotProps={slotProps}
            getRowClassName={getRowClassName}
            checkboxSelection={userHasPermission('DemandCompose', userData)}
            onRowSelectionModelChange={handleRowSelectionChange}
            rowSelectionModel={rowSelectionModel}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowError}
          />
        </Box>
      </Box>
    </>
  );
};

export default MedicalsDiagnosesTable;
