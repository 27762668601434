import { createSlice } from '@reduxjs/toolkit'

export const accordionSlice = createSlice({
  name: 'accordion',
  initialState: {
    panels: []
  },
  reducers: {
    toggle: (state,action) => {

        console.log(action);
        const matchingPanel = state.panels.find(panel => panel.id === action.payload.id)

        if (matchingPanel) {
            matchingPanel.expanded =  action.payload.expanded;
        }
        else {
            state.panels.push({
                id: action.payload.id,
                expanded:  action.payload.expanded
            })
        }
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggle  } = accordionSlice.actions

export default accordionSlice.reducer