import { createSlice } from '@reduxjs/toolkit'

const initialMedicalsTreatmentsState = {
  selectionModel: [],
  treatmentProviderChartLegend: {},
  treatmentProviderChartDateRange: {
    start: 0,
    end: 0
  },
  currentDeleteMedical: {}
}

export const MedicalsTreatmentsSlice = createSlice({
  name: 'MedicalsTreatments',
  initialState: initialMedicalsTreatmentsState,
  reducers: {
    setSelectionModel: (state, action) => {
      state.selectionModel = action.payload
    },
    setTreatmentProviderChartLegend: (state, action) => {
      state.treatmentProviderChartLegend = action.payload
    },
    setTreatmentProviderChartDateRange: (state, action) => {
      state.treatmentProviderChartDateRange = action.payload
    },
    setCurrentDeleteMedical: (state, action) => {
      state.currentDeleteMedical = action.payload
    },
  },
})

export const { setSelectionModel, setTreatmentProviderChartLegend, setTreatmentProviderChartDateRange, setCurrentDeleteMedical } = MedicalsTreatmentsSlice.actions

export default MedicalsTreatmentsSlice.reducer