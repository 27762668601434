import { createSlice } from '@reduxjs/toolkit';
import { fetchDocumentInsightsMriData } from '../thunks/documentInsightsData';

const originalDocumentInsightsSlice = {
  mriInsightsBody: {},
};

const documentInsightsSlice = createSlice({
  name: 'DocumentInsights',
  initialState: originalDocumentInsightsSlice,
  reducers: {
    setMriInsights: (state, action) => {
      state.mriInsightsBody = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentInsightsMriData.fulfilled, (state, action) => {
        if (action.payload) {
          state.mriInsightsBody = action.payload;
        }
      })
      .addCase(fetchDocumentInsightsMriData.rejected, (state, action) => {
        console.log(action.error);
      });
  },
});

export const { setMriInsights } = documentInsightsSlice.actions;

export default documentInsightsSlice.reducer;
