import { createSlice } from '@reduxjs/toolkit';

const initialMriFindingsSlice = {
  mriSelectedRows: [],
};

export const MriFindingsSlice = createSlice({
  name: 'MriFindings',
  initialState: initialMriFindingsSlice,
  reducers: {
    setMriSelectedRows: (state, action) => {
      state.mriSelectedRows = action.payload;
    },
  },
});

export const { setMriSelectedRows } = MriFindingsSlice.actions;

export default MriFindingsSlice.reducer;
