import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DocumentUploader from '../DocumentUploader';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import { createDocumentApi, regenerateDocumentStatusApi, getDocumentFilesApi, getAvailableTemplatesForCustomer, getConfigValue } from '../../api';
import { isLawFirmUser, isPrecedentUser } from '../../common-roles';
import { Form } from 'react-final-form'
import { TextField, Autocomplete, Switches, Radios } from 'mui-rff'
import { Stack, Button, InputAdornment, Dialog, IconButton, DialogContent, DialogTitle, Typography, MenuItem, FormControl, TextField as MuiTextField, Box, Tooltip, Divider, Snackbar, Collapse, Alert, AlertTitle, Paper, FormHelperText } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Title from '../../CommonComponents/Title';
import { Close, CloudUploadOutlined, InfoOutlined } from '@mui/icons-material';
import { Transition } from '../../Transition';
import { theme } from '../../Theme';
import { setCreateNewDemandOpen, setDocumentUploaderList, setDocumentFiles } from '../../redux/slices/demandDomSlice';
import { setDocumentDataLoading } from '../../redux/slices/documentSlice';
import { fetchAuthorFormData } from '../../redux/thunks/demandData';
import { useDispatch, useSelector } from 'react-redux';
import { formatDecimal, getCurrentDate, cleanNumber, normalizeEmailAddress, formatPhoneNumber, CONTACT_EMAIL, userHasPermission } from '../../common';
import { coverageList, stateLabelValues } from '../../common-data';
import { DOCUMENT_UPLOAD_FILE_TYPES } from '../../common-document';
import moment from 'moment';
import { uploadFilesAndPostToDemand } from '../utilities';
import { setDocumentRegnerationLoading, setDocumentFilesLoading } from '../../redux/slices/documentSlice';
import { fetchDocumentData } from '../../redux/thunks/documentData';
import { parseNestedObject } from '../../helpers/formHelpers';
import { buildCarrierAutoCompleteData, loadCarriers, validate, NO_TEMPLATE, buildUserAutoCompleteData, autoFillCreateNewDemandForm, getSingleSelectAutoCompleteFilteredOptions, getMultiSelectAutoCompleteFilteredOptions } from './FormHelpers';
import { DiscardWork } from '../../dialogs/DialogText';

const CreateNewDemand = ({ user, userData, useDemandNotesEnabled }) => {
    const [carriersData, setCarriersData] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [uploadErrorState, setUploadErrorState] = useState({});
    const [demandErrorState, setDemandErrorState] = useState({ show: false, severity: 'error', title: '', message: '' });
    const [dialogState, setDialogState] = useState({ discardChanges: false });
    const [toastState, setToastState] = useState({ show: false, severity: 'info', message: '' })
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [defaultCustomerTemplate, setDefaultCustomerTemplate] = useState(NO_TEMPLATE);

    const { createNewDemandOpen, documentUploaderList } = useSelector(state => state.DemandDom);
    const authorState = useSelector((state) => state.Author);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { approvers, users } = authorState;
    const approverIds = approvers?.map(approver => approver.userId) || [];
    const attorneys = users ? users.filter(user => approverIds.includes(user.userId)) : [];

    if (userData && !attorneys.find(attorney => attorney?.userId === userData?.userId)) {
        attorneys.unshift({ userId: userData.userId, firstName: userData.firstName, lastName: userData.lastName, contactInfo: { phoneNumber: userData.phone, emailAddress: userData.emailAddress } })
    }

    const attorney_auto_complete_data = buildUserAutoCompleteData(attorneys);
    const user_data_for_auto_completes = buildUserAutoCompleteData(users);
    const primaryContact_auto_complete_data = user_data_for_auto_completes;
    const carrier_auto_complete_data = buildCarrierAutoCompleteData(carriersData);
    const collaborators_auto_complete_data = user_data_for_auto_completes;

    useEffect(() => {
        if (user) {
            dispatch(fetchAuthorFormData({ token: user.signInUserSession.accessToken.jwtToken, user: user, hasPermission: userHasPermission('DemandCompose', userData) }));
            loadCarriers(user, setCarriersData);
            getAvailableTemplatesForCustomer(user)
                .then(response => {
                    const templates = response?.templates;
                    setAvailableTemplates([{ templateId: NO_TEMPLATE, templateName: NO_TEMPLATE }, ...templates]);
                });
            getConfigValue('defaultTemplateId', null, user)
                .then(response => {
                    if (response) {
                        setDefaultCustomerTemplate(response);
                    }
                });
        }
    }, [user]);

    const checkStatus = async (documentId) => {
        dispatch(setDocumentFilesLoading(false))
        const response = await regenerateDocumentStatusApi(documentId, user);
        const status = response["status"];
        console.log("Regeneration status is: " + status + " for document " + documentId);

        if (status === "Complete") {
            setFormSubmitting(false);
            dispatch(setDocumentDataLoading(documentId));
            dispatch(fetchDocumentData({ documentId, user }))
                .then(() => {
                    dispatch(setDocumentRegnerationLoading(''));
                    getDocumentFilesApi(documentId, user)
                        .then(response => response.json())
                        .then(data => dispatch(setDocumentFiles(data)))
                        .catch(error => console.log('error', error));
                })
            return;
        }
        else if (status === "Failed") {
            setFormSubmitting(false);
            setDemandErrorState({
                ...demandErrorState, show: true, severity: "warning",
                title: "Demand Submitted",
                message: "The document PDF generation failed however the demand has been created."
            });
            setDemandErrorState({
                ...demandErrorState, show: true, severity: "warning",
                title: "Demand Submitted",
                message: "The document PDF generation failed however the demand has been created."
            });
            dispatch(setDocumentRegnerationLoading(''));
            return;
        }

        setTimeout(() => {
            checkStatus(documentId);
        }, 3000);
    }

    const submitDemand = async (demand) => {
        setToastState({ ...toastState, show: true, severity: "info", message: "Submitting demand..." })
        setFormSubmitting(true);

        createDocumentApi(demand, user)
            .then(async (response) => {
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error);
                } else {
                    setToastState({ ...toastState, show: true, severity: "success", message: "The demand has been submitted to Precedent. The document is being generated. Please wait..." })
                    const documentId = (await response.json())["documentId"];
                    dispatch(setDocumentRegnerationLoading(documentId));
                    dispatch(setDocumentFilesLoading(true));

                    if (isLawFirmUser(userData)) {
                        dispatch(setCreateNewDemandOpen(false));
                        navigate('/l/' + documentId);
                    } else if (isPrecedentUser(userData)) {
                        dispatch(setCreateNewDemandOpen(false));
                        navigate('/a/' + documentId);
                    }
                    return uploadFilesAndPostToDemand(documentUploaderList, documentId, user, setUploadErrorState, uploadErrorState, checkStatus);
                }
            })
            .then((response) => {
                // TODO: propagate list of failed files to EditDemand
                if (response.successful.length === 0) {
                    console.log("All files failed upload: " + response.failed.map((file) => file.name).join());
                    console.log("All files failed upload: " + response.failed.map((file) => file.name).join());
                } else if (response.failed.length > 0) {
                    console.log("Some files failed upload: " + response.failed.map((file) => file.name).join())
                    console.log("Some files failed upload: " + response.failed.map((file) => file.name).join())
                }
                return response;
            })
            .catch((error) => {
                console.log(error);
                setToastState({ ...toastState, show: false });
                setDemandErrorState({
                    ...demandErrorState, show: true, severity: "error",
                    title: "Failed to compose demand",
                    message: `Please try again. If the problem persists, contact ${CONTACT_EMAIL}.`
                })
            }).finally(() => {
                setFormSubmitting(false);
            })
    }

    const handleClose = () => {
        dispatch(setCreateNewDemandOpen(false))
    };

    const initialValues = useMemo(() => {
        const currentDate = getCurrentDate();
        return {
            metadata: {
                demandTemplateId: defaultCustomerTemplate,
            },
            sendingFirm: {
                caseManagers: []
            },
            claimInfo: {
                dateOfLoss: currentDate,
                lossState: 'none',
                clientIsInjuredParty: true,
                insuredIsTortfeasor: true
            },
            demandDetails: {
                displayDemandAmount: false,
                policyLimitDemandIndicator: false
            },
            mutators: {
                attorneyIsPrimaryContact: true,
            }
        }
    }, [userData, defaultCustomerTemplate]);


    const handleSaveClick = async (values) => {
        const body = parseNestedObject({
            ...values,
            documentType: 'COMPOSITEDEMAND',
            damagesSummary: {
                ...values.damagesSummary,
                demandDamagesAmount: values?.damagesSummary?.demandDamagesAmount ? cleanNumber(values.damagesSummary.demandDamagesAmount) : ''
            },
            demandDetails: {
                ...values.demandDetails,
                demandOfferToSettleAmount: values?.demandDetails?.demandOfferToSettleAmount ? cleanNumber(values.demandDetails.demandOfferToSettleAmount) : ''
            },
            sendingFirm: {
                ...values.sendingFirm,
                //use autoComplete values to populate respective fields on sendingFirm object onSave
                attorney: values?.autoCompletes?.attorney?.value,
                primaryContact: values?.mutators?.attorneyIsPrimaryContact ? values?.autoCompletes?.attorney?.value : values?.autoCompletes?.primaryContact?.value,
                caseManagers: (values?.autoCompletes?.collaborators || []).map(collaborator => collaborator.value)
            },
            claimInfo: {
                ...values.claimInfo,
                insuredPolicyLimitsAsUnderstoodByFirm: values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm ? cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm) : ''
            },
            defaultDeliveryInformationEnabled: values?.autoCompletes?.carrier?.value?.defaultDeliveryInformationEnabled,
            recipientCarrier: {
                //use autoComplete values to populate respective fields on recipientCarrier object onSave
                ...values?.recipientCarrier,
                carrierCustomerAliasId: values?.autoCompletes?.carrier?.value?.carrierCustomerAliasId,
                carrierCommonName: values?.autoCompletes?.carrier?.value?.carrierCommonName || values?.recipientCarrier?.carrierCommonName,
                //use recipientCarrier.contactInfo values to populate respective fields b/c these fields are not fully controlled by autoComplete selection
                contactInfo: { ...values?.recipientCarrier?.contactInfo }
            },
            recipientAdjuster: {
                ...values.recipientAdjuster,
                contactInfo: {
                    ...values?.recipientAdjuster?.contactInfo,
                    emailAddress: values?.recipientAdjuster?.contactInfo?.emailAddress ? normalizeEmailAddress(values.recipientAdjuster.contactInfo.emailAddress) : '',
                    phoneNumber: values?.recipientAdjuster?.contactInfo?.phoneNumber ? formatPhoneNumber(values.recipientAdjuster.contactInfo.phoneNumber) : ''
                }
            },
            customerSpecialNotes: values?.customerSpecialNotes ? values.customerSpecialNotes : '',
        });
        delete body.autoCompletes;  //remove autoCompletes from document body before submitting
        delete body.mutators;  //remove mutators from document body before submitting
        submitDemand(body);
    }

    const hasFilesUploaded = documentUploaderList.length > 0;

    const handleCloseButtonClick = (dirty) => {
        if (dirty) {
            setDialogState({ ...dialogState, discardChanges: true });
        } else {
            handleClose();
        }
    }

    const confirmationDialogProps = {
        dialogOpen: dialogState.discardChanges,
        dialogText: DiscardWork.text,
        cancelButtonText: DiscardWork.cancelText,
        okButtonText: DiscardWork.confirmText,
        handleCancel: () => {
            setDialogState({ ...dialogState, discardChanges: false });
        }
    }


    return (
        <Dialog
            open={createNewDemandOpen}
            fullWidth={true}
            fullScreen={true}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
        >
            <Form
                validate={validate}
                onSubmit={() => null}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, values, valid, errors, dirty, fields, dirtyFields, dirtySinceLastSubmit, touched }) => {
                    const carrierId = values?.autoCompletes?.carrier?.value?.carrierId;
                    const hasUploadErrors = documentUploaderList.some((file) => file.error);

                    return (
                        <form id="createDemand" onSubmit={handleSubmit}>

                            <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                                <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                        <IconButton onClick={e => handleCloseButtonClick(dirty || hasFilesUploaded)} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                            <Close color={'white'} />
                                        </IconButton>

                                        <Title color={theme.palette.white.main}>Compose demand</Title>

                                    </Stack>

                                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-end"}>
                                        {hasFilesUploaded &&
                                            <>
                                                <input
                                                    accept={DOCUMENT_UPLOAD_FILE_TYPES}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    multiple
                                                    type="file"

                                                    onChange={(e) => {
                                                        const files = e.target.files;
                                                        const newFileList = [...documentUploaderList, ...files];
                                                        //iterate through new file list and if files have same name- remove the duplicate
                                                        const uniqueFiles = newFileList.filter((file, index, self) =>
                                                            index === self.findIndex((t) => (
                                                                t.name === file.name
                                                            ))
                                                        )
                                                        uniqueFiles.forEach((file) => {
                                                            file.error = file.size > 157286400 ? 'File size cannot exceed 150MB' : null;
                                                        })
                                                        dispatch(setDocumentUploaderList(uniqueFiles));
                                                    }}

                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, }}>
                                                        <Button
                                                            component="span"
                                                            variant="outlined"
                                                            color="white"
                                                            startIcon={<CloudUploadOutlined />}
                                                        >
                                                            Upload more documents
                                                        </Button>
                                                    </Box>
                                                    <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, }}>
                                                        <IconButton size='small'
                                                            component="span"
                                                            color={'white'}
                                                            sx={{ '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}
                                                        >
                                                            <CloudUploadOutlined />
                                                        </IconButton>
                                                    </Box>
                                                </label>
                                            </>
                                        }

                                        <Box sx={{ '& > button': { m: 1 } }}>
                                            <LoadingButton
                                                disabled={!valid || formSubmitting || !dirty || !hasFilesUploaded || hasUploadErrors}
                                                loading={formSubmitting}
                                                sx={{ '&:disabled': { backgroundColor: ("rgba(255,255,255,0.2)") } }}
                                                onClick={() => {
                                                    setDemandErrorState({ ...demandErrorState, show: false });
                                                    handleSaveClick(values)
                                                }}
                                                loadingPosition="start"
                                                variant="contained"
                                                color="white"
                                            >
                                                <span>{formSubmitting ? 'Saving...' : 'Save'}</span>
                                            </LoadingButton>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </DialogTitle>

                            <DialogContent sx={{ marginTop: '0px', paddingTop: '0px', overflow: 'visible' }}>
                                <Grid container spacing={2} sx={{ mt: 8, mb: 5 }}>
                                    {demandErrorState.show &&
                                        <Grid xs={12}>
                                            <Collapse in={demandErrorState.show}>
                                                <Alert severity={demandErrorState.severity}>
                                                    <AlertTitle>{demandErrorState.title}</AlertTitle>
                                                    {demandErrorState.message}
                                                </Alert>
                                            </Collapse>
                                        </Grid>
                                    }
                                    {/* Left Column */}
                                    <Grid xs={12} sm={12} md={8} lg={7}
                                        sx={{
                                            paddingX: 0,
                                            marginX: 0,
                                        }}
                                    >
                                        <fieldset
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                marginLeft: 0,
                                                marginRight: 0
                                            }}
                                            disabled={formSubmitting}>
                                            <Stack
                                                spacing={2}
                                                divider={<Divider flexItem />}
                                            >
                                                {/* case details */}
                                                <Stack>
                                                    <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case details</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField fullWidth required label="Matter number or Case ID" size='small' name='sendingFirm.firmCaseNumber' />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField fullWidth label="Claim number" size='small' name='claimInfo.claimNumber' />
                                                        </Grid>
                                                        <Grid item xs={12} md={12}>
                                                            <Stack
                                                                spacing={1}
                                                            >
                                                                <TextField fullWidth required label="Client" name='claimInfo.claimant.firstName' size='small'
                                                                    onChange={(e) => {
                                                                        if (values.claimInfo?.clientIsInjuredParty) {
                                                                            form.change('claimInfo.injuredPartyFullName', e.target.value);
                                                                        }
                                                                        form.change('claimInfo.claimant.firstName', e.target.value)
                                                                        if (values?.sendingFirm?.firmCaseNumber === "42" && values?.claimInfo?.claimNumber === "42" && e.target.value === "Precedent") {
                                                                            autoFillCreateNewDemandForm(form, values, attorney_auto_complete_data, carrier_auto_complete_data, user_data_for_auto_completes)
                                                                        }
                                                                    }}
                                                                />
                                                                <Switches
                                                                    name="claimInfo.clientIsInjuredParty"
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        form.change('claimInfo.clientIsInjuredParty', checked)
                                                                        if (checked) {
                                                                            form.change('claimInfo.injuredPartyFullName', values?.claimInfo?.claimant?.firstName)
                                                                        } else {
                                                                            form.change('claimInfo.injuredPartyFullName', '')
                                                                        }
                                                                    }}
                                                                    data={{ label: 'Client is injured party', value: true }}
                                                                    color="secondary"
                                                                />
                                                                {!values.claimInfo?.clientIsInjuredParty &&

                                                                    <Box
                                                                        sx={{
                                                                            paddingLeft: 6,
                                                                            paddingBottom: 2
                                                                        }}
                                                                    >
                                                                        <TextField fullWidth required label="Injured party" name='claimInfo.injuredPartyFullName' size='small'
                                                                        />
                                                                    </Box>

                                                                }
                                                            </Stack>
                                                        </Grid>

                                                        <Grid item xs={12} md={12}>
                                                            <Stack spacing={1}>
                                                                <TextField fullWidth required label="Insured" name="claimInfo.insuredFirstName" size='small'
                                                                    onChange={(e) => {
                                                                        form.change('claimInfo.insuredFirstName', e.target.value)

                                                                        if (values.claimInfo?.insuredIsTortfeasor) {
                                                                            form.change('claimInfo.tortfeasorFullName', e.target.value)
                                                                        }
                                                                    }} />
                                                                <Switches
                                                                    name="claimInfo.insuredIsTortfeasor"
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        form.change('claimInfo.insuredIsTortfeasor', checked)
                                                                        if (checked) {
                                                                            form.change('claimInfo.tortfeasorFullName', values?.claimInfo?.insuredFirstName)
                                                                        } else {
                                                                            form.change('claimInfo.tortfeasorFullName', '')
                                                                        }
                                                                    }}
                                                                    data={{ label: 'Insured is tortfeasor', value: true }}
                                                                    color="secondary"
                                                                />
                                                                {!values.claimInfo?.insuredIsTortfeasor &&
                                                                    <Box
                                                                        sx={{
                                                                            paddingLeft: 6
                                                                        }}
                                                                    >
                                                                        <TextField fullWidth required label="Tortfeasor" name='claimInfo.tortfeasorFullName' size='small' />
                                                                    </Box>
                                                                }
                                                            </Stack>
                                                        </Grid>

                                                    </Grid>

                                                </Stack>

                                                {/* loss details */}
                                                <Stack>
                                                    <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Loss details</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid xs={12} md={6}>
                                                            <TextField
                                                                name='claimInfo.dateOfLoss'
                                                                fullWidth={true}
                                                                inputProps={{ max: getCurrentDate() }}
                                                                label="Date of loss" type="date"
                                                                InputLabelProps={{ shrink: true }}
                                                                size='small'
                                                                required
                                                                //we are using MUI textfield here b/c datepicker is better; therefore, we need to add below props to explicitly create controlled component
                                                                onChange={e => form.change('claimInfo.dateOfLoss', e.target.value)}
                                                                value={values?.claimInfo?.dateOfLoss}
                                                                error={errors.claimInfo?.dateOfLoss}
                                                                helperText={errors.claimInfo?.dateOfLoss}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6}>
                                                            <TextField fullWidth label="Coverage" name='claimInfo.claimCoverage' select size='small'>
                                                                {coverageList.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid xs={12} md={6}>
                                                            <TextField fullWidth label="Loss state" name="claimInfo.lossState" size='small' select required>
                                                                {[{ label: 'Select a state', value: 'none' }, ...stateLabelValues].map((option, idx) => (
                                                                    <MenuItem key={idx} value={option.value} disabled={idx === 0} >
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid xs={12} md={6}>
                                                            <TextField fullWidth label="Loss county" name="claimInfo.lossCounty" size='small' />
                                                        </Grid>
                                                    </Grid>

                                                </Stack>

                                                <Stack>
                                                    <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Carrier details</Typography>
                                                    <Grid container spacing={2}>

                                                        <Grid xs={12}>
                                                            <Autocomplete
                                                                name='autoCompletes.carrier'
                                                                freeSolo
                                                                fullWidth
                                                                blurOnSelect={true}
                                                                filterSelectedOptions={true}
                                                                filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                options={carrier_auto_complete_data}
                                                                onChange={(e, selection) => {
                                                                    form.change('recipientCarrier', selection?.value || {
                                                                        carrierCommonName: '',
                                                                        carrierCustomerAliasId: '',
                                                                        contactInfo: {
                                                                            address: {
                                                                                city: '',
                                                                                state: '',
                                                                                street1: '',
                                                                                street2: '',
                                                                                zipCode: ''
                                                                            },
                                                                            emailAddress: '',
                                                                            faxNumber: '',
                                                                            phoneNumber: ''
                                                                        }
                                                                    })
                                                                }}
                                                                onInputChange={(e, value) => {
                                                                    if (e?.type === 'click') return;
                                                                    const match = carrier_auto_complete_data.find(option => option.label === value);
                                                                    const previousMatch = typeof values?.autoCompletes?.carrier !== 'string' ? carrier_auto_complete_data.find(option => option.label === values?.autoCompletes?.carrier?.label) : carrier_auto_complete_data.find(option => option.label === values?.autoCompletes?.carrier);
                                                                    if (match) {
                                                                        form.change('recipientCarrier', match.value)
                                                                        form.change('autoCompletes.carrier', match)
                                                                    } else {
                                                                        if (previousMatch) {
                                                                            form.change('recipientCarrier', {
                                                                                carrierCommonName: value,
                                                                                carrierCustomerAliasId: '',
                                                                                contactInfo: {
                                                                                    address: {
                                                                                        city: '',
                                                                                        state: '',
                                                                                        street1: '',
                                                                                        street2: '',
                                                                                        zipCode: ''
                                                                                    },
                                                                                    emailAddress: '',
                                                                                    faxNumber: '',
                                                                                    phoneNumber: ''
                                                                                }
                                                                            })
                                                                        } else {
                                                                            form.change('recipientCarrier.carrierCommonName', value)
                                                                        }
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        required
                                                                        name='autoCompletes.carrier'
                                                                        size='small'
                                                                        label="Carrier"
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>

                                                        {!carrierId &&
                                                            <>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Carrier fax number" name='recipientCarrier.contactInfo.faxNumber' type='tel' size='small'
                                                                        onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                    />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Carrier email" name='recipientCarrier.contactInfo.emailAddress' type='email' size='small' />
                                                                </Grid>
                                                            </>
                                                        }
                                                        <Grid xs={12}>
                                                            <Switches
                                                                name="mutators.showCarrierContactInfo"
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    form.change('mutators.showCarrierContactInfo', checked)
                                                                    if (!checked) {
                                                                        form.change('recipientCarrier.contactInfo.phoneNumber', '');
                                                                        form.change('recipientCarrier.contactInfo.address.street1', '');
                                                                        form.change('recipientCarrier.contactInfo.address.street2', '');
                                                                        form.change('recipientCarrier.contactInfo.address.city', '');
                                                                        form.change('recipientCarrier.contactInfo.address.state', '');
                                                                        form.change('recipientCarrier.contactInfo.address.zipCode', '');
                                                                    }
                                                                }}
                                                                data={{ label: 'Carrier contact info (optional)', value: true }}
                                                                color="secondary"
                                                            />
                                                        </Grid>

                                                        {values?.mutators?.showCarrierContactInfo &&
                                                            <>
                                                                <Grid xs={12}>
                                                                    <TextField fullWidth label="Carrier phone number" name='recipientCarrier.contactInfo.phoneNumber' type='tel' size='small' onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)} />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Address line 1" name="recipientCarrier.contactInfo.address.street1" size='small' />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Address line 2" name="recipientCarrier.contactInfo.address.street2" size='small' />
                                                                </Grid>
                                                                <Grid xs={12} md={4}>
                                                                    <TextField fullWidth label="City" name="recipientCarrier.contactInfo.address.city" size='small' />
                                                                </Grid>
                                                                <Grid xs={6} md={4}>
                                                                    <TextField fullWidth label="State" name="recipientCarrier.contactInfo.address.state" size='small' select>
                                                                        {stateLabelValues.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid xs={6} md={4}>
                                                                    <TextField fullWidth label="Zip" name="recipientCarrier.contactInfo.address.zipCode" size='small' />
                                                                </Grid>
                                                            </>
                                                        }

                                                        <Grid xs={12}>
                                                            <Switches
                                                                name="mutators.showAdjusterContactInfo"
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    form.change('mutators.showAdjusterContactInfo', checked)
                                                                    if (!checked) {
                                                                        form.change('recipientAdjuster.contactInfo.phoneNumber', '');
                                                                        form.change('recipientAdjuster.contactInfo.emailAddress', '');
                                                                        form.change('recipientAdjuster.firstName', '');
                                                                        form.change('recipientAdjuster.lastName', '');
                                                                    }
                                                                }}
                                                                data={{ label: 'Adjuster contact info (optional)', value: true }}
                                                                color="secondary"
                                                            />
                                                        </Grid>

                                                        {values?.mutators?.showAdjusterContactInfo &&
                                                            <>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Adjuster first name" name='recipientAdjuster.firstName' size='small' />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Adjuster last name" name='recipientAdjuster.lastName' size='small' />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Adjuster phone" name='recipientAdjuster.contactInfo.phoneNumber' type='tel' size='small'
                                                                        onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Adjuster email" name='recipientAdjuster.contactInfo.emailAddress' type='email' size='small' />
                                                                </Grid>
                                                            </>
                                                        }


                                                    </Grid>
                                                </Stack>

                                                {/* demand details */}
                                                <Stack>
                                                    <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Demand details</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid xs={12} md={6}>

                                                            <FormControl fullWidth>
                                                                <Radios
                                                                    color="secondary"
                                                                    sx={{ width: '100%' }}
                                                                    label="Display demand amount in letter?"
                                                                    name="demandDetails.displayDemandAmount"
                                                                    onChange={(e) => {
                                                                        form.change(
                                                                            'demandDetails.displayDemandAmount',
                                                                            e.target.value === 'true'
                                                                        );
                                                                        if (e.target.value !== 'true') {
                                                                            form.change('claimInfo.insuredPolicyLimitsAsUnderstoodByFirm', '');
                                                                            form.change('demandDetails.demandOfferToSettleAmount', '');
                                                                            form.change('demandDetails.policyLimitDemandIndicator', false);
                                                                        } else {
                                                                            form.change('demandDetails.policyLimitDemandIndicator', true);
                                                                            form.change(
                                                                                'demandDetails.demandOfferToSettleAmount',
                                                                                values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm || ''
                                                                            );
                                                                        }
                                                                    }}
                                                                    data={[
                                                                        { label: 'Yes', value: true },
                                                                        { label: 'No', value: false },
                                                                    ]}
                                                                    radioGroupProps={{ row: true }}
                                                                    required
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid xs={12} md={6}>
                                                            {values?.demandDetails?.displayDemandAmount !== false && (
                                                                <FormControl fullWidth>
                                                                    <Radios
                                                                        color="secondary"
                                                                        sx={{ width: '100%' }}
                                                                        label="Is this a policy limit demand?"
                                                                        name="demandDetails.policyLimitDemandIndicator"
                                                                        onChange={(e) => {
                                                                            form.change(
                                                                                'demandDetails.policyLimitDemandIndicator',
                                                                                e.target.value === 'true'
                                                                            );
                                                                            if (e.target.value === 'true') {
                                                                                form.change(
                                                                                    'demandDetails.demandOfferToSettleAmount',
                                                                                    values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
                                                                                );
                                                                            } else {
                                                                                form.change('demandDetails.demandOfferToSettleAmount', '');
                                                                            }
                                                                        }}
                                                                        data={[
                                                                            { label: 'Yes', value: true },
                                                                            { label: 'No', value: false },
                                                                        ]}
                                                                        radioGroupProps={{ row: true }}
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        </Grid>

                                                        <Grid xs={12} md={6}>
                                                            <TextField
                                                                label="Insured policy limit"
                                                                name='claimInfo.insuredPolicyLimitsAsUnderstoodByFirm'
                                                                onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                onChange={(e) => {
                                                                    if (values?.demandDetails?.policyLimitDemandIndicator) {
                                                                        form.change("demandDetails.demandOfferToSettleAmount", e.target.value)
                                                                    }
                                                                    form.change("claimInfo.insuredPolicyLimitsAsUnderstoodByFirm", e.target.value)
                                                                }}
                                                                size='small'
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                label="Demand amount"
                                                                name='demandDetails.demandOfferToSettleAmount'
                                                                onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                disabled={values?.demandDetails?.policyLimitDemandIndicator}
                                                                size='small'
                                                            />
                                                        </Grid>
                                                        <Grid xs={12}>
                                                            <Stack direction={"row"} spacing={2}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Expected medical specials amount"
                                                                    name='damagesSummary.demandDamagesAmount'
                                                                    onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                    size='small'
                                                                />

                                                                <Tooltip title="This optional field captures the expected amount of medical charges that have been uploaded for the demand." arrow placement="left">
                                                                    <div style={{ marginTop: '8px' }}>
                                                                        <InfoOutlined color="primary" />
                                                                    </div>
                                                                </Tooltip>

                                                            </Stack>
                                                        </Grid>
                                                        <Grid xs={12} md={6}>
                                                            <TextField
                                                                name='demandDetails.demandResponseDueDate'
                                                                fullWidth={true}
                                                                inputProps={{ min: getCurrentDate() }}
                                                                label="Demand response due date"
                                                                type="date"
                                                                InputLabelProps={{ shrink: true }}
                                                                size='small'
                                                                //we are using MUI textfield here b/c datepicker is better; therefore, we need to add below props to explicitly create controlled component
                                                                onChange={e => form.change('demandDetails.demandResponseDueDate', e.target.value)}
                                                                value={values?.demandDetails?.demandResponseDueDate}
                                                                error={errors.demandDetails?.demandResponseDueDate}
                                                                helperText={!errors.demandDetails?.demandResponseDueDate && values?.demandDetails?.demandResponseDueDate && moment(values.demandDetails.demandResponseDueDate).isAfter(moment().add(1, 'years')) ? 'Date is more than 1 year from today' : errors.demandDetails?.demandResponseDueDate}
                                                            />
                                                        </Grid>

                                                        <Grid xs={12} md={6}>
                                                            <TextField fullWidth label="Tax ID" name="sendingFirm.firmTINNumber" size='small' />
                                                        </Grid>

                                                        <Grid xs={12}>
                                                            <TextField fullWidth label="Demand letter template" name='metadata.demandTemplateId' select size='small' required>
                                                                {availableTemplates.map((option) => {
                                                                    return (
                                                                        <MenuItem key={option.templateId} value={option.templateId}>
                                                                            {option.templateName}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </TextField>
                                                        </Grid>

                                                    </Grid>
                                                </Stack>

                                                {/* case team */}
                                                <Stack>
                                                    <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case team</Typography>
                                                    <Grid container spacing={2}>

                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                name="autoCompletes.attorney"
                                                                //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText

                                                                fullWidth
                                                                blurOnSelect={true}
                                                                filterSelectedOptions={true}
                                                                filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                options={attorney_auto_complete_data}
                                                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                renderInput={(params) => (
                                                                    <>
                                                                        <TextField
                                                                            {...params}
                                                                            name="autoCompletes.attorney"
                                                                            size='small'
                                                                            label="Attorney"
                                                                            required={true}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </Grid>

                                                        <Grid xs={12}>
                                                            <Autocomplete
                                                                name="autoCompletes.collaborators"
                                                                //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                                                fullWidth
                                                                multiple
                                                                limitTags={4}
                                                                filterOptions={(options, params) => getMultiSelectAutoCompleteFilteredOptions(options, params, values?.autoCompletes?.collaborators)}
                                                                options={collaborators_auto_complete_data}
                                                                renderInput={(params) => (
                                                                    <>
                                                                        <MuiTextField
                                                                            //MUI-RFF textfield does filtering does not work well with multi select autocomplete, so we use MUI textfield here
                                                                            {...params}
                                                                            size='small'
                                                                            label="Collaborators"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </Grid>


                                                        <Grid xs={12}>
                                                            <Switches
                                                                name="mutators.attorneyIsPrimaryContact"
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    form.change('mutators.attorneyIsPrimaryContact', checked)
                                                                    form.change('autoCompletes.primaryContact', checked ? values?.autoCompletes?.attorney : { label: '', value: {} })
                                                                }}
                                                                data={{ label: 'Attorney is primary contact', value: true }}
                                                                color="secondary"
                                                            />
                                                        </Grid>

                                                        {!values?.mutators?.attorneyIsPrimaryContact &&

                                                            <Grid item xs={12}>
                                                                <Autocomplete
                                                                    name="autoCompletes.primaryContact"
                                                                    //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                                                    fullWidth
                                                                    blurOnSelect={true}
                                                                    filterSelectedOptions={true}
                                                                    filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                    options={primaryContact_auto_complete_data}
                                                                    renderInput={(params) => (
                                                                        <>
                                                                            <TextField
                                                                                {...params}
                                                                                name="autoCompletes.primaryContact"
                                                                                size='small'
                                                                                label="Primary contact"
                                                                                required={true}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Stack>

                                                {/* response address */}
                                                <Stack>
                                                    <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Response address</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid xs={12} md={6}>
                                                            <TextField fullWidth label="Address line 1" name="demandDetails.demandResponseAddress.street1" size='small' />
                                                        </Grid>
                                                        <Grid xs={12} md={6}>
                                                            <TextField fullWidth label="Address line 2" name="demandDetails.demandResponseAddress.street2" size='small' />
                                                        </Grid>
                                                        <Grid xs={12} md={4}>
                                                            <TextField fullWidth label="City" name="demandDetails.demandResponseAddress.city" size='small' />
                                                        </Grid>
                                                        <Grid xs={6} md={4}>
                                                            <TextField fullWidth label="State" name="demandDetails.demandResponseAddress.state" select size='small'>
                                                                {stateLabelValues.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid xs={6} md={4}>
                                                            <TextField fullWidth label="Zip" name="demandDetails.demandResponseAddress.zipCode" size='small' />
                                                        </Grid>
                                                    </Grid>
                                                </Stack>

                                                {useDemandNotesEnabled &&
                                                    <Stack>
                                                        <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Notes</Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid xs={12}>
                                                                <TextField fullWidth label="Demand Notes" name="customerSpecialNotes" size="small" inputProps={{ maxLength: 50 }} />
                                                                <FormHelperText>{values?.customerSpecialNotes ? values.customerSpecialNotes.length : 0}/50</FormHelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                }
                                            </Stack>
                                        </fieldset>

                                    </Grid>
                                    {/* Right Column */}
                                    <Grid xs={12} sm={12} md={4} lg={5}>
                                        <Stack spacing={2} width={"100%"}>
                                            <fieldset disabled={formSubmitting}>
                                                <DocumentUploader />
                                            </fieldset>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <ConfirmationDialog
                                handleOk={() => {
                                    setDialogState({ ...dialogState, discardChanges: false });
                                    handleClose();
                                }}
                                {...confirmationDialogProps}
                            />
                        </form>
                    )
                }
                }>
            </Form>
            <Snackbar
                open={toastState.show}
                autoHideDuration={7000}
                onClose={e => setToastState({ ...toastState, show: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Paper elevation={8}>
                    <Alert onClose={e => setToastState({ ...toastState, show: false })} severity={toastState.severity} sx={{ width: '100%' }}>{toastState.message}</Alert>
                </Paper>
            </Snackbar>
        </Dialog >
    )
}

export default CreateNewDemand;
