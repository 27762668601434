import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToast } from '../redux/slices/globalToastSlice';
import { Snackbar, Alert, Paper } from '@mui/material';

const Toast = () => {
  const { toast } = useSelector((state) => state.GlobalToasts);
  const { isOpen, severity, message } = toast;
  const duration = toast?.duration || 7000;

  const dispatch = useDispatch();

  const handleCloseToast = () => dispatch(setToast({ isOpen: false, message: '' }));

  return (
    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={duration}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Paper elevation={8}>
          <Alert onClose={handleCloseToast} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Paper>
      </Snackbar>
    </>
  );
};

export default Toast;
