import { createSlice } from '@reduxjs/toolkit'
import { fetchInventoryDemandData } from '../thunks/demandData'
import { fetchDocumentContacts } from '../thunks/demandContactData'
import { DEFAULT_PAGE_SIZE } from '../../common'

const originalQuickFiltersState = [
  {
    id: 'ALL',
    name: 'All demands',
    label: 'All', // Optional label to override name being used.
    toggleState: true,
    data: null
  },
  {
    id: 'READY_FOR_REVIEW',
    name: 'Ready for review',
    toggleState: false,
    data: null
  },
  {
    id: 'READY_FOR_APPROVAL',
    name: 'Ready for approval',
    toggleState: false,
    data: null
  },
  {
    id: 'AWAITING_PRECEDENT_REVIEW',
    name: 'Awaiting Precedent review',
    toggleState: false,
    data: null
  },
  {
    id: 'UPCOMING',
    name: 'Upcoming',
    toggleState: false,
    data: null
  },
  {
    id: 'ALL',
    name: 'All',
    toggleState: true,
    data: null
  },
  {
    id: 'NEW',
    name: 'New',
    toggleState: false,
    data: null
  },
  {
    id: 'PENDING_SIGNOFF',
    name: 'Pending signoff',
    toggleState: false,
    data: null
  },
  {
    id: 'PENDING_PROCESSING',
    name: 'Pending processing',
    toggleState: false,
    data: null
  },
  {
    id: 'PENDING_APPROVAL',
    name: 'Pending approval',
    toggleState: false,
    data: null
  }
]

export const InventorySlice = createSlice({
  name: 'Inventory',
  initialState: {
    contactData: {
      attorneys: [],
      selectedAttorneys: []
    },
    inventoryResponseData: {
      loading: false,
      isActiveDataFetched: false,
      isInactiveDataFetched: false,
      activeDocuments: [],
      inActiveDocuments: [],
      inActivePaginationKeys: {},
      activePaginationKeys: {},
    },
    pagination: {
      page: 0,
      hasNextPage: false,
      sort: 'date_created',
      sortDirection: 'desc',
      pageSize: DEFAULT_PAGE_SIZE
    },
    filterState: {
      filteredActiveDocuments: null,
      filterModel: { items: [] },
      quickFilters: originalQuickFiltersState,
      activeQuickFilter: 'ALL',
      filterToggleValue: 'active',
    }
  },
  reducers: {
    pageChange: (state, action) => {
      state.pagination.page = action.payload.page;
      state.pagination.hasNextPage = action.payload.hasNextPage;
    },
    pageSizeChange: (state, action) => {
      state.pagination.pageSize = action.payload.pageSize;
    },
    sortChange: (state, action) => {
      state.pagination.sort = action.payload.sort;
      state.pagination.sortDirection = action.payload.sortDirection;
    },
    updateFilterState: (state, action) => {
      state.filterState = action.payload;
    },
    resetFilterState: (state, action) => {
      state.filterState = {
        filteredActiveDocuments: null,
        filterModel: { items: [] },
        quickFilters: originalQuickFiltersState
      };
    },
    setFilterToggleValue: (state, action) => {
      state.filterState.filterToggleValue = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDemandData: (state, action) => {
      const { data, isActive } = action.payload;
      if (isActive) {
        state.inventoryResponseData.activeDocuments = data;
      } else {
        state.inventoryResponseData.inActiveDocuments = data;
      }
    },
    resetInventoryData: (state) => {
      state.inventoryResponseData.activeDocuments = [];
      state.inventoryResponseData.inActiveDocuments = [];
    },
    updateFilteredActiveDocuments: (state, action) => {
      state.filterState.filteredActiveDocuments = action.payload;
    },
    updateFilteredContacts: (state, action) => {
      state.contactData.selectedAttorneys = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryDemandData.fulfilled, (state, action) => {
      const { response, isActive } = action.payload;
      if (isActive) {
        state.inventoryResponseData.activeDocuments = response.documents;
        state.inventoryResponseData.activeTotalRows = response.totalRows;
        state.inventoryResponseData.activeTotalDocuments = response.totalDocuments;
        state.inventoryResponseData.activeStatusCounts = response.totalStatusCounts;
        state.isActiveDataFetched = true;
        state.loading = false;
      } else {
        state.inventoryResponseData.inActiveDocuments = response.documents;
        state.inventoryResponseData.inActiveTotalRows = response.totalRows;
        state.inventoryResponseData.inActiveTotalDocuments = response.totalDocuments;
        state.inventoryResponseData.inActiveStatusCounts = response.totalStatusCounts;
        state.isInactiveDataFetched = true;
        state.loading = false;
      }
    })

    builder.addCase(fetchDocumentContacts.fulfilled, (state, action) => {
      const { response } = action.payload;
      state.contactData.attorneys = response;
    })
  },
})

export const { pageChange, pageSizeChange, sortChange, setLoading, setDemandData, updateFilterState, updateFilteredActiveDocuments, resetFilterState, updateFilteredContacts, setFilterToggleValue } = InventorySlice.actions
export default InventorySlice.reducer
