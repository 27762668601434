import { useEffect } from "react";
import { Authenticator, useAuthenticator, View, Heading, Image, useTheme, ThemeProvider } from '@aws-amplify/ui-react';
import { Box, Button, Card, Container, Grid, Stack, Toolbar, Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import AuthInstructions from './AuthInstructions'
import { useNavigate, useLocation } from 'react-router';
import awsExports from '../aws-exports';
import { getUserApi } from '../api';
import { userHasRole, userHasPermission } from '../common';
import Countdown from "./Countdown";

const Login = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user
  ]);

  const userNameField = document.querySelector('input[name="username"]');
  const passwordField = document.querySelector('input[type="password"]');
  const trimFields = () => {
    if (userNameField?.value) {
      userNameField.value = userNameField?.value.replace(/ /g, '').trim()
    }
    if (passwordField?.value) {
      passwordField.value = passwordField?.value.replace(/ /g, '').trim()
    }
  }


  document.addEventListener('keyup', (e) => {
    trimFields()
  })

  //useEffect to remove event listener on unmount
  useEffect(() => {
    return () => {
      document.removeEventListener('keyup', (e) => {
        trimFields()
      })
    }
  }, [])

  const isSetupTOTPRoute = route === 'setupTOTP';
  const isForceNewPasswordRoute = route === 'forceNewPassword';

  const querystring = location.search;
  const params = new URLSearchParams(querystring);
  const status = params.get('status');
  const sessionExpired = status === 'expired';

  const getPasswordRulesList = () => {
    return (
      `<ul style={{marginBottom: 0}}>
        <li>At least 8 characters</li>
        <li>1 number</li>
        <li>1 special character</li>
      </ul>`
    )
  }

  // forceNewPassword customizations 
  const forceNewPasswordField = isForceNewPasswordRoute ? document.querySelector('div[class*="amplify-passwordfield"]') : null;
  const passwordRules = isForceNewPasswordRoute ? document.querySelector('div[class*="password-rules"]') : null;
  const forceConfirmNewPasswordField = isForceNewPasswordRoute ? document.querySelectorAll('div[class*="amplify-passwordfield"]')[1] : null;


  if (forceNewPasswordField && forceConfirmNewPasswordField && !passwordRules) {
    const passwordRules = document.createElement('div');
    passwordRules.className = 'password-rules';
    passwordRules.innerHTML = getPasswordRulesList();
    passwordRules.style.marginTop = '-20px'
    forceNewPasswordField.after(passwordRules);
    forceConfirmNewPasswordField.style.marginTop = '-20px';
  }
  //

  const getUser = async (user) => {
    const response = await getUserApi(user);

    // Check roles
    let hasRole = false;
    if (location.state?.roles) {
      location.state?.roles.forEach((role) => {
        if (userHasRole(role, response)) {
          hasRole = true;
        }
      });
    }
    else {
      hasRole = true;
    }

    // Check permissions
    let hasPermission = false;
    if (location.state?.permissioms) {
      location.state?.permissions.forEach((permission) => {
        if (userHasPermission(permission, response)) {
          hasPermission = true;
        }
      });
    }
    else {
      hasPermission = true;
    }

    if (hasRole && hasPermission) {
      navigate(from, { replace: true });
    }
    else {
      navigate('/', { replace: true });
    }
  };

  const formFields = {
    setupTOTP: {
      QR: {
        totpIssuer: awsExports.MFA_ISSUER
      },
      confirmation_code: {
        label: ``,
        placeholder: '6-digit passcode from Precedent-Exchange-prod',
        isRequired: true
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Enter the code from your Authenticator application.',
        placeholder: 'Enter your Authenticator code:',
        isRequired: true,

      },
    }
  };

  const components = {
    Header() {
      return (
        <View>
        </View>
      );
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          null
        );
      }
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading level={3}>
            Authenticator information
          </Heading>
        );
      }
    },
  };

  const login_theme = {
    name: 'precedent-theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            '90': '#2C537B',
            '100': '#4077b0'
          }
        }
      },
      components: {
        button: {
          // this will affect the font weight of all button variants
          fontWeight: { value: '400' },

          // style the primary variation
          primary: {
            backgroundColor: { value: '{colors.brand.primary[100]}' },
            _hover: {
              backgroundColor: { value: '{colors.brand.primary[90]}' },
            },
            _focus: {
              backgroundColor: { value: '{colors.brand.primary[100]}' },
            },
            _active: {
              backgroundColor: { value: '{colors.brand.primary[100]}' },
            },
          },
        }
      },
    },
  };

  useEffect(() => {
    if (route === 'authenticated') {
      getUser(user);
    }
  }, [route, navigate, from]);

  return (
    <Box>
      <Toolbar />
      <Container maxWidth='md'>
        <Stack justifyContent={"space-around"} sx={{ mt: 5 }}>
          <ThemeProvider theme={login_theme}>
            {!isSetupTOTPRoute &&
              <Authenticator hideSignUp={true} components={components} formFields={formFields} />
            }

            {isSetupTOTPRoute &&
              <Box>
                <Typography sx={{ textAlign: "center", mb: 1 }} variant="h4">
                  Set up multi-factor authentication
                </Typography>
                <Countdown seconds={900} />
                <AuthInstructions qrCode={<Authenticator hideSignUp={true} components={components} formFields={formFields} />} />
              </Box>

            }

          </ThemeProvider>
        </Stack>

      </Container>
    </Box>

  );
}

export default Login;