import React from 'react';
import { useSelector } from 'react-redux';
import Toast from './Toast';

const GlobalToasts = () => {
  const { toast } = useSelector((state) => state.GlobalToasts);

  return <>{toast.isOpen && <Toast />}</>;
};

export default GlobalToasts;
