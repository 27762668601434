import { batchUpdatePageInsights, batchUpdateMri } from '../api';

export const INCLUDED_STATUS = 'INCLUDED';
export const EXCLUDED_STATUS = 'EXCLUDED';
export const NEEDS_REVIEW_STATUS = 'NEEDS_REVIEW';

export const mapPageInsightStatusToDisplayStatus = {
  [INCLUDED_STATUS]: 'Included',
  [EXCLUDED_STATUS]: 'Excluded',
  [NEEDS_REVIEW_STATUS]: 'Needs review',
};

export const selectPageInsightStatusOptions = [
  { value: INCLUDED_STATUS, label: mapPageInsightStatusToDisplayStatus[INCLUDED_STATUS] },
  { value: EXCLUDED_STATUS, label: mapPageInsightStatusToDisplayStatus[EXCLUDED_STATUS] },
];

export const formatPageNumbers = (pageNumbers) => {
  // remove duplicates
  const uniquePageNumbers = pageNumbers.reduce((acc, pageNumber) => {
    if (!acc.includes(pageNumber)) {
      acc.push(pageNumber);
    }
    return acc;
  }, []);

  const formattedList = [];
  let consecutiveRangeStart;
  let previousNumber;
  uniquePageNumbers.forEach((pageNumber) => {
    if (consecutiveRangeStart && pageNumber === previousNumber + 1) {
      formattedList[formattedList.length - 1] = `${consecutiveRangeStart}-${pageNumber}`;
    } else {
      formattedList.push(pageNumber);
      consecutiveRangeStart = pageNumber;
    }
    previousNumber = pageNumber;
  });
  return formattedList.join(', ');
};

export const formatBodyPartName = (bodyPart) => {
  return bodyPart.charAt(0).toUpperCase() + bodyPart.slice(1).toLowerCase();
};

export const aggregateIcdCodes = (files) => {
  const data = {};

  files.forEach((file) => {
    selectPageInsights(file).forEach((pageInsight) => {
      selectIcdCodes(pageInsight).forEach(
        ({ icd_code_id, description, status, bboxes, value: icdCode, needs_review }) => {
          const reference = {
            id: icd_code_id,
            icdCodeId: icd_code_id,
            document: file.fileEntityData.fileName,
            page: pageInsight.generated_page_number,
            status: needs_review ? NEEDS_REVIEW_STATUS : status,
            bboxes,
            fileId: file.fileEntityId,
          };

          if (data[icdCode] === undefined) {
            data[icdCode] = {
              description,
              totalCount: 1,
              includedCount: reference.status === INCLUDED_STATUS ? 1 : 0,
              needsReview: !!needs_review,
              pages: [pageInsight.generated_page_number],
              references: [{ ...reference }],
            };
          } else {
            data[icdCode].totalCount++;
            reference.status === INCLUDED_STATUS && data[icdCode].includedCount++;
            data[icdCode].pages.push(pageInsight.generated_page_number);
            data[icdCode].references.push({ ...reference });
            if (!data[icdCode].needsReview) {
              data[icdCode].needsReview = needs_review;
            }
          }
        }
      );
    });
  });

  // Apply a top level status for the ICD code based on the statuses of its references
  Object.keys(data).forEach((icdCode) => {
    const { references } = data[icdCode];

    if (references.some((ref) => ref.status === INCLUDED_STATUS)) {
      data[icdCode].status = INCLUDED_STATUS;
    } else if (references.every((ref) => ref.status === EXCLUDED_STATUS)) {
      data[icdCode].status = EXCLUDED_STATUS;
    } else if (references.some((ref) => ref.status === NEEDS_REVIEW_STATUS)) {
      data[icdCode].status = NEEDS_REVIEW_STATUS;
    }
  });

  return data;
};

// Uses the MRI summarized findings and extracts the corresponding page entities MRI findings by matching on entity id
export const combineMriSummaryWithPageEntities = (files, mriNarratives) => {
  // Restructure the data so that it can be accessible by entity_id at the top level and only include MRI findings
  const mriEntityIdMapToFilePageInsights = {};

  files.forEach((file) => {
    selectPageInsights(file).forEach((pageInsight) => {
      selectMriFindings(pageInsight).forEach((mriFinding) => {
        mriEntityIdMapToFilePageInsights[mriFinding.entity_id] = {
          ...mriFinding,
          generatedPageNumber: pageInsight.generated_page_number,
          fileEntityId: file.fileEntityId,
          fileName: file.fileEntityData.fileName,
        };
      });
    });
  });

  const mriSummarizedFindings = [];
  mriNarratives.forEach(({ summary_name, narrative_components, body_part }) => {
    narrative_components.forEach(({ value, entity_ids }) => {
      const mriPageEntities = entity_ids.map((entityId) => {
        const mriPageEntity = mriEntityIdMapToFilePageInsights[entityId];
        return {
          id: entityId,
          mriFindingId: entityId,
          fileId: mriPageEntity?.fileEntityId,
          document: mriPageEntity?.fileName,
          page: mriPageEntity?.generatedPageNumber,
          status: mriPageEntity?.status,
          bboxes: mriPageEntity?.bboxes,
        };
      });

      mriSummarizedFindings.push({
        mriSummaryName: summary_name,
        bodyPart: body_part,
        value,
        references: mriPageEntities,
        status: mriPageEntities[0].status,
        pages: mriPageEntities.map(({ page }) => page),
      });
    });
  });

  return mriSummarizedFindings;
};

/**
 * Takes a pageInsight array and updates the ICD codes by id.
 *
 * @param {Array} pageInsights
 * @param {Array} icdCodeIdsToUpdate
 * @param {string} newStatus
 * @param {string} newIcdCode
 * @param {string} newDescription
 *
 * @returns A copy of the pageInsights array with updated ICD codes
 */
export const updateIcdCodes = (pageInsights, icdCodeIdsToUpdate, newStatus, newIcdCode, newDescription) => {
  return pageInsights.map((pageInsight) => {
    const updatedIcdCodes = selectIcdCodes(pageInsight).map((icdCode) => {
      if (icdCodeIdsToUpdate.includes(icdCode.icd_code_id)) {
        return {
          ...icdCode,
          ...(newStatus && { status: newStatus }),
          needs_review: false,
          ...(newIcdCode && { value: newIcdCode }),
          ...(newDescription && { description: newDescription }),
        };
      }
      return {
        ...icdCode,
      };
    });

    return {
      ...pageInsight,
      entities: {
        ...pageInsight.entities,
        icd10_codes: updatedIcdCodes,
      },
    };
  });
};

/**
 * Takes a pageInsight array and updates the MRI findings by id.
 *
 * @param {Array} pageInsights
 * @param {Array} mriFindingIdsToUpdate
 * @param {string} newStatus
 * @param {string} newDescription
 *
 * @returns A copy of the pageInsights array with updated MRI findings
 */
export const updatePageInsightsMriFindings = (
  pageInsights,
  mriFindingIdsToUpdate,
  newStatus = '',
  newDescription = ''
) => {
  return pageInsights.map((pageInsight) => {
    const updatedMriFindings = selectMriFindings(pageInsight).map((mriFinding) => {
      if (mriFindingIdsToUpdate.includes(mriFinding.entity_id)) {
        return {
          ...mriFinding,
          status: newStatus !== '' ? newStatus : mriFinding.status,
          value: newDescription !== '' ? newDescription : mriFinding.value,
        };
      }
      return {
        ...mriFinding,
      };
    });

    return {
      ...pageInsight,
      entities: {
        ...pageInsight.entities,
        mri_findings: updatedMriFindings,
      },
    };
  });
};

/**
 * Updates a MRI insights object with the new value for the given entity ids.
 */
export const updateMriInsightsBody = (mriInsightsBody, mriEntityIdsToUpdate, newValue) => {
  return {
    ...mriInsightsBody,
    insights: {
      ...mriInsightsBody.insights,
      // Iterate through each narrative and narrative component
      narratives: mriInsightsBody.insights.narratives.map(({ narrative_components, ...narrative }) => {
        const updatedNarrativeComponents = narrative_components.map(({ value, entity_ids }) => {
          // If entity_ids contain the mri entity ids to update, then update value
          if (entity_ids.some((entityId) => mriEntityIdsToUpdate.includes(entityId))) {
            return {
              value: newValue || value,
              entity_ids,
            };
          }

          return {
            value,
            entity_ids,
          };
        });

        return {
          ...narrative,
          narrative_components: updatedNarrativeComponents,
        };
      }),
    },
  };
};

export const postIcdCodeUpdates = (
  documentId,
  user,
  filesToUpdate,
  icdCodeIds,
  newStatus = '',
  newIcdCode = '',
  newDescription = ''
) => {
  const payload = filesToUpdate.map((file) => {
    const { fileEntityId } = file;
    const pageInsights = selectPageInsights(file);
    const updatedPageInsights = updateIcdCodes(pageInsights, icdCodeIds, newStatus, newIcdCode, newDescription);

    return {
      fileEntityId,
      pageInsights: updatedPageInsights,
    };
  });

  return batchUpdatePageInsights(documentId, payload, user);
};

export const postMriFindingsUpdates = (
  filesToUpdate,
  mriInsightsBody,
  mriFindingIds,
  newStatus,
  newDescription,
  documentId,
  user
) => {
  const mriInsightsPayload = updateMriInsightsBody(mriInsightsBody, mriFindingIds, newDescription);

  const batchPageInsightsPayload = filesToUpdate.map((file) => {
    const { fileEntityId } = file;
    const pageInsights = selectPageInsights(file);

    const updatedPageInsights = updatePageInsightsMriFindings(pageInsights, mriFindingIds, newStatus);

    return {
      fileEntityId,
      pageInsights: updatedPageInsights,
    };
  });

  const payload = {
    mri: mriInsightsPayload,
    batchPageInsights: batchPageInsightsPayload,
  };

  return batchUpdateMri(documentId, payload, user);
};

export const selectPageInsights = (file) => file?.fileEntityData?.pageInsights || [];
export const selectIcdCodes = (pageInsight) => pageInsight?.entities?.icd10_codes || [];
export const selectMriFindings = (pageInsight) => pageInsight?.entities?.mri_findings || [];
export const selectMriNarratives = (mriInsightsBody) => mriInsightsBody?.insights?.narratives || [];
