import { Extension } from '@tiptap/core';
import { generateNarrativeForField } from '../../../api';
import { setToast } from '../../../redux/slices/globalToastSlice';
import store from '../../../redux/store';

const formatResponse = (response, addNewLine) => {
  response = response.replaceAll('\\n', '</br>'); // replace newlines with line breaks
  if (response.startsWith('"') && response.endsWith('"'))  // remove end quotes
    response = response.substring(1, response.length-1);
  response = addNewLine ? '</br>' + response : response;  // add newline if editor wasn't empty
  return response;
}

const AiGenerate = Extension.create({
  name: 'aiGenerate',

  addStorage() {
    return {
      isLoading: false,
    };
  },

  addCommands() {
    return {
      generateNarrative:
        (fieldName, documentId, user) =>
        ({ editor }) => {
          this.storage.isLoading = true;
          generateNarrativeForField(fieldName, documentId, user)
            .then((response) => {
              if (response.status === 200) {
                editor
                  .chain()
                  .focus('end')
                  .insertContent(formatResponse(response.body, editor.getText() !== ''), {
                    parseOptions: {
                      preserveWhitespace: 'full',
                    },
                  })
                  .run();
              } else {
                store.dispatch(
                  setToast({
                    isOpen: true,
                    severity: 'error',
                    message: 'Error generating AI narrative',
                    duration: 7000,
                  })
                );
              }
            })
            .finally(() => {
              this.storage.isLoading = false;
              editor.chain().focus('end').run();
            });
        },
    };
  },
});

export default AiGenerate;
