import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDeleteMedicalOpen } from '../redux/slices/demandDomSlice';
import { Transition } from '../Transition';

const DeleteMedical = ({deleteMedical}) => {
    
        const dispatch = useDispatch();
        const deleteMedicalOpen = useSelector((state) => state.DemandDom.deleteMedicalOpen);
        const currentDeleteMedical = useSelector((state) => state.MedicalsTreatments.currentDeleteMedical);
        const handleDeleteMedicalClose = () => dispatch(setDeleteMedicalOpen(false));
    
        return (
            <Dialog
                open={deleteMedicalOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Delete medical treatment?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to delete this medical: {currentDeleteMedical["treatmentFacility"]} - {currentDeleteMedical["treatmentType"]}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleDeleteMedicalClose() }}>Cancel</Button><Button variant="contained" color="secondary" onClick={() => { deleteMedical() }}>Delete</Button>
                </DialogActions>
            </Dialog>
        )
    }

export default DeleteMedical;